/* eslint-disable quotes */
/* eslint-disable no-tabs */
// https://vuex.vuejs.org/en/actions.html
import axios from 'axios';
import { myHttp, myHttpSite, myHttpEmail } from './variables.js';
import moment from 'moment';
export default {
  updateCounter({ commit }, payload) {
    commit('updateCounter', payload);
  },
  async login({ commit }, formData) {
    var response = null;

    await axios
      .post(myHttp, {
        query:
          `mutation gtoken{getToken(username:"` +
          formData.user +
          `", password:"` +
          formData.password +
          `"){token}}
           `,
      })
      .then((res) => {
        response = res;
      })
      .catch((error) => {
        response = error;
      });
    var result = {
      type: null,
      response: null,
      query: 'login',
    };

    result.type = 'v';

    if (response.data) {
      if (response.data.errors && response.data.errors.length > 0) {
        result.response = response.data.errors[0].message;
        result.type = 'e';
        commit('setTokenProgres', null);
        localStorage.setItem('tokenProgres', null);
      } else {
        if (response.data.data.getToken) {
          result.type = 's';
          commit('setLogin', response.data.data.getToken.token);
          localStorage.setItem('tokenIM', response.data.data.getToken.token);
          var expirationDate = moment().add(10, 'hours').format('YYYY-MM-DD, HH:mm:ss');
          localStorage.setItem('loginDate', expirationDate);
          localStorage.setItem('tokenProgres', '');
          result.response = response.data.data.getToken.token;
        }
      }
    } else {
      result.type = 'e';
      result.response = response;
    }

    return result;
  },
  async getVegga({ commit }, formData) {
    var response = null;

    await axios
      .post(myHttp, {
        query:
          `mutation getVegga{verifyVeggaToken(  vgToken:"` +
          formData.token +
          `"){username, imToken}}
           `,
      })
      .then((res) => {
        response = res;
      })
      .catch((error) => {
        response = error;
      });
    var result = {
      type: null,
      response: null,
      query: 'getVegga',
    };

    result.type = 'v';
    if (response.data) {
      if (response.data.errors && response.data.errors.length > 0) {
        result.response = response.data.errors[0].message;
        result.type = 'e';
        commit('setTokenProgres', null);
        localStorage.setItem('tokenProgres', null);
      } else {
        if (response.data.data.verifyVeggaToken) {
          result.type = 's';
          commit('setLogin', response.data.data.verifyVeggaToken.imToken);
          localStorage.setItem('tokenIM', response.data.data.verifyVeggaToken.imToken);
          var expirationDate = moment().add(10, 'hours').format('YYYY-MM-DD, HH:mm:ss');
          localStorage.setItem('loginDate', expirationDate);
          localStorage.setItem('tokenProgres', '');
          result.response = response.data.data.verifyVeggaToken;
        }
      }
    } else {
      result.type = 'e';
      result.response = response;
    }

    return result;
  },

  async getTokenProgres({ commit }, inputVar) {
    var response = null;
    await axios
      .post(
        myHttp,
        {
          query: `query getProgresToken {getProgresToken}
          `,
        },
        {
          headers: {
            Authorization: 'JWT ' + inputVar.tokens.tokenIM,
          },
        }
      )
      .then((res) => {
        response = res;
      })
      .catch((error) => {
        response = error;
      });

    var result = {
      type: null,
      response: null,
      query: 'getTokenProgres',
    };

    result.type = 'v';
    if (response.data) {
      if (response.data.errors && response.data.errors.length > 0) {
        result.response = response.data.errors[0].message;
        result.type = 'e';
        commit('setTokenProgres', null);
        localStorage.setItem('tokenProgres', null);
        result.response = null;
      } else {
        if (response.data.data.getProgresToken) {
          result.type = 's';
          commit('setTokenProgres', response.data.data.getProgresToken);
          localStorage.setItem('tokenProgres', response.data.data.getProgresToken);

          result.response = response.data.data.getProgresToken;
        }
      }
    } else {
      result.type = 'e';
      result.response = response;
    }
    return result;
  },

  tryAutoLogin({ commit }) {
    var now = moment().format('YYYY-MM-DD, HH:mm:ss');

    if (now > localStorage.getItem('loginDate')) {
      commit('logout');

      localStorage.removeItem('tokenIM');
      localStorage.removeItem('tokenProgres');
      localStorage.removeItem('loginDate');
      window.location = myHttpSite;
    }
    const tokenIM = localStorage.getItem('tokenIM');
    if (!tokenIM) {
      return;
    }
    commit('setLogin', tokenIM);
    return false;
  },
  logout({ commit }) {
    commit('logout');
    localStorage.removeItem('tokenIM');
    localStorage.removeItem('tokenProgres');
    localStorage.removeItem('loginDate');
    window.location = myHttpSite;
  },
  async activateAccount({ commit }, inputVar) {
    var response = '';
    var a =
      `mutation vr{
      verifyAccount(
        token:"` +
      inputVar.token +
      `",
      ) {
        success, errors
      }
    }

`;
    await axios
      .post(myHttp, {
        query: a,
      })
      .then((res) => {
        response = res;
      })
      .catch((error) => {
        response = error;
      });

    return response;
  },
  async sendRecoverPassword({ commit }, inputVar) {
    var response = '';
    var a =
      `mutation sendPasswordResetEmail {sendPasswordResetEmail
      (email:"` +
      inputVar.email +
      `")
    {errors
    success}}

`;
    await axios
      .post(myHttpEmail, {
        query: a,
      })
      .then((res) => {
        response = res;
      })
      .catch((error) => {
        response = error;
      });

    return response;
  },
  async recoverPassword({ commit }, inputVar) {
    var response = '';
    var a =
      `mutation passwordReset {
  passwordReset(token: "` +
      inputVar.token +
      `", newPassword1: "` +
      inputVar.password +
      `", newPassword2: "` +
      inputVar.rePassword +
      `") {
    success
    errors
  }
}

`;
    await axios
      .post(myHttp, {
        query: a,
      })
      .then((res) => {
        response = res;
      })
      .catch((error) => {
        response = error;
      });

    return response;
  },
  async changePassword({ commit }, inputVar) {
    var response = '';

    var a =
      `mutation  passwordChange{
  passwordChange(oldPassword: "` +
      inputVar.oldPassword +
      `", newPassword1: "` +
      inputVar.password +
      `", newPassword2: "` +
      inputVar.rePassword +
      `") {
    success
    errors
    token
  }
}

`;

    await axios
      .post(
        myHttp,
        {
          query: a,
        },
        {
          headers: {
            Authorization: 'JWT ' + inputVar.token,
          },
        }
      )
      .then((res) => {
        response = res;
      })
      .catch((error) => {
        response = error;
      });

    return response;
  },
  async sendActivationEmail({ commit }, inputVar) {
    var response = '';

    inputVar.imusers.forEach(async function (item) {
      var a =
        `mutation {
        resendActivationEmail(
          email:"` +
        item.user.username +
        `",
        ) {
          success,
          errors

        }
      }
  `;
      await axios
        .post(myHttpEmail, {
          query: a,
        })
        .then((res) => {
          response = res;
        })
        .catch((error) => {
          response = error;
        });
    });

    return response;
  },

  async getAccount({ commit }, inputVar) {
    var response = '';
    await axios
      .post(
        myHttp,
        {
          query: `
        query get_account {
          account {
            id
            accountName
            logo
            runningYear
            dashFarmRestr
            accountStatus {
              id
              accountStatus
            }

            unitProfId {
              id
              density{
                name
              }
              area {
                name
              }
              wVolume {
                name
              }
              wFlow {
                name
              }
              fFlow {
                name
              }
              massArea {
                name
              }
              mass {
                name
              }
              wVolumeArea {
                name
              }
              fVolume {
                name
              }
              fVolumeArea {
                name
              }
              fFlow {
                name
              }
            }
            irrigationCycle

            soilTypes {
              id
              name
            }
            cultivarTypes {
              id
              name
            }
            plantSpacings {
              id
              plantSpacing
              plantLineSpacing
            }
            irrigationSpacings {
              id
              dripFlow
              dripSpacing
              linesPerPlantLine
            }
            doserRefs{
              id
              doserModel
            }
            cycleStartMonth
            activeDt
          }
        }

      `,
        },
        {
          headers: {
            Authorization: 'JWT ' + inputVar.tokens.tokenIM,
          },
        }
      )
      .then((res) => {
        response = res;
      })
      .catch((error) => {
        response = error;
      });

    var result = {
      type: null,
      response: null,
      query: 'getAccount',
    };

    result.type = 'v';
    if (response.data) {
      if (response.data.errors && response.data.errors.length > 0) {
        result.response = response.data.errors[0].message;
        result.type = 'e';
      } else {
        if (response.data.data.account) {
          result.type = 's';
          commit('setAccount', response.data.data.account);
          result.response = response.data.data.account;
        }
      }
    } else {
      result.type = 'e';
      result.response = response;
    }
    return result;
  },
  async createAccount({ commit }, inputVar) {
    var queryUp = '';
    var response = '';
    var queryVar = [];

    inputVar.account.forEach(async function (item) {
      queryVar.push(
        `accountName: "` +
          item.accountName +
          `" accountStatus:` +
          item.accountStatusId +
          `,unitProfId:` +
          item.unitProfId +
          `,cloudUserId:` +
          item.cloudUserId
      );
    });

    if (queryVar) {
      queryUp =
        `mutation create_accounts {
  createAccounts(input: [{` +
        queryVar +
        `}]) {
    accounts {
      id, accountName
    }
  }
}
      `;

      await axios
        .post(
          myHttp,
          {
            query: queryUp,
          },
          {
            headers: {
              Authorization: 'JWT ' + inputVar.tokens.tokenIM,
            },
          }
        )
        .then((res) => {
          response = res;
        })
        .catch((error) => {
          response = error;
        });
    }

    var result = {
      type: null,
      response: null,
      query: 'createAccounts',
    };
    result.type = 'v';
    if (response.data) {
      if (response.data.errors && response.data.errors.length > 0) {
        result.response = response.data.errors[0].message;
        result.type = 'e';
      } else {
        if (response.data.data.createAccounts) {
          result.type = 's';
          result.response = response.data.data.createAccounts;
        }
      }
    } else {
      result.type = 'e';
      result.response = response;
    }
    return result;
  },
  async updateAccount({ commit }, inputVar) {
    var queryUp = '';
    var response = '';
    var queryVar = [];

    inputVar.account.forEach(async function (item) {
      queryVar.push(
        `accountName: "` +
          item.accountName +
          `"
        `
      );
    });

    if (queryVar) {
      queryUp =
        `mutation update_account {
        updateAccount(` +
        queryVar +
        `) {
          accountOut {
              id
              accountName
             
             
        }
      `;
      await axios
        .post(
          myHttp,
          {
            query: queryUp,
          },
          {
            headers: {
              Authorization: 'JWT ' + inputVar.tokens.tokenIM,
            },
          }
        )
        .then((res) => {
          response = res;
        })
        .catch((error) => {
          response = error;
        });
    }

    var result = {
      type: null,
      response: null,
      query: 'updateAccount',
    };
    result.type = 'v';
    if (response.data) {
      if (response.data.errors && response.data.errors.length > 0) {
        result.response = response.data.errors[0].message;
        result.type = 'e';
      } else {
        if (response.data.data.updateAccount) {
          result.type = 's';
          result.response = response.data.data.updateAccount;
        }
      }
    } else {
      result.type = 'e';
      result.response = response;
    }
    return result;
  },
  async updAccount({ commit }, inputVar) {
    var queryUp = '';
    var response = '';
    var queryVar = [];
    if (inputVar.account.irrigationCycle === null) {
      inputVar.account.irrigationCycle = '';
    }

    queryVar.push(
      `accountName: "` +
        inputVar.account.accountName +
        `" irrigationCycle: ` +
        inputVar.account.irrigationCycle +
        `, runningYear:` +
        inputVar.account.runningYear +
        `, dashFarmRestr:` +
        inputVar.account.dashFarmRestr +
        `, cycleStartMonth:` +
        inputVar.account.cycleStartMonth +
        `, soilTypes: [` +
        inputVar.account.soilTypes +
        `]` +
        `, cultivarTypes: [` +
        inputVar.account.cultivarTypes +
        `]` +
        `, plantSpacings: [` +
        inputVar.account.plantSpacings +
        `]` +
        `, irrigationSpacings: [` +
        inputVar.account.irrigationSpacings +
        `]` +
        `, doserRefs: [` +
        inputVar.account.doserRefs +
        `]` +
        `
         `
    );

    if (queryVar) {
      queryUp =
        `mutation update_account {
        updAccount(` +
        queryVar +
        `) {
          accountOut {
              id
              accountName
              runningYear
              dashFarmRestr
              accountStatus {
                id
                accountStatus
              }
              unitProfId {
                id
                area {
                  name
                }
                wVolume {
                  name
                }
                wFlow {
                  name
                }
                fFlow {
                  name
                }
                massArea {
                  name
                }
                mass {
                  name
                }
                wVolumeArea {
                  name
                }
                fVolume {
                  name
                }
                fVolumeArea {
                  name
                }
              }
              irrigationCycle

              soilTypes {
                id
                name
              }
              cultivarTypes {
                id
                name
              }
              plantSpacings {
                id
                plantSpacing
                plantLineSpacing
              }
              irrigationSpacings {
                id
                dripFlow
                dripSpacing
                linesPerPlantLine
              }
              doserRefs{
                id
                doserModel
              }
              cycleStartMonth
              activeDt
            }
          }
        }
      `;

      await axios
        .post(
          myHttp,
          {
            query: queryUp,
          },
          {
            headers: {
              Authorization: 'JWT ' + inputVar.tokens.tokenIM,
            },
          }
        )
        .then((res) => {
          response = res;
        })
        .catch((error) => {
          response = error;
        });
    }

    var result = {
      type: null,
      response: null,
      query: 'updAccount',
    };
    result.type = 'v';
    if (response.data) {
      if (response.data.errors && response.data.errors.length > 0) {
        result.response = response.data.errors[0].message;
        result.type = 'e';
      } else {
        if (response.data.data.updAccount) {
          result.type = 's';
          result.response = response.data.data.updAccount;
        }
      }
    } else {
      result.type = 'e';
      result.response = response;
    }
    return result;
  },
  /* getTariffRef */
  async getTariffRef({ commit }, inputVar) {
    var response = '';
    var queryUp =
      `query getTariffPlan {
  eTariffPlanByFarm(farmId:` +
      inputVar.farmId +
      `,cycleStartDt:"` +
      inputVar.cycleDate +
      `" ) {
    id

    supplier {
      id
      name
    }
    tariff {
      id
      name
    }
    rate {
      id
      name
      color
    }
    endTime
    startTime
  }
}
`;

    await axios
      .post(
        myHttp,
        {
          query: queryUp,
        },
        {
          headers: {
            Authorization: 'JWT ' + inputVar.tokens.tokenIM,
          },
        }
      )
      .then((res) => {
        response = res;
      })
      .catch((error) => {
        response = error;
      });

    var result = {
      type: null,
      response: null,
      query: 'getTariffRef',
    };
    result.type = 'v';
    if (response.data) {
      if (response.data.errors && response.data.errors.length > 0) {
        result.response = response.data.errors[0].message;
        result.type = 'e';
      } else {
        if (response.data.data.eTariffPlanByFarm) {
          result.type = 's';
          commit('setTarifRefs', response.data.data.eTariffPlanByFarm);
          result.response = response.data.data.eTariffPlanByFarm;
        }
      }
    } else {
      result.type = 'e';
      result.response = response;
    }
    return result;
  },
  async getHeaders({ commit }, inputVar) {
    let response = '';
    await axios
      .post(
        myHttp,
        {
          query: `
      query getHeaders {
        headersByControllerId(controllerId: ${inputVar.unitId}) {
          id
          sectors
        }
      }
      `,
        },
        {
          headers: {
            Authorization: 'JWT ' + inputVar.tokens.tokenIM,
          },
        }
      )
      .then((res) => {
        response = res;
      })
      .catch((error) => {
        response = error;
      });

    let result = {
      type: null,
      response: null,
      query: 'getHeaders',
    };

    result.type = 'v';
    if (response.data) {
      if (response.data.errors && response.data.errors.length > 0) {
        result.response = response.data.errors[0].message;
        result.type = 'e';
      } else {
        if (response.data.data.headersByControllerId) {
          result.type = 's';

          result.response = response.data.data.headersByControllerId;
        }
      }
    } else {
      result.type = 'e';
      result.response = response;
    }

    return result;
  },
  async getSectorsGroup({ commit }, inputVar) {
    var response = '';
    await axios
      .post(
        myHttp,
        {
          query:
            `query getSectorGroupsByFarm{
          getSectorgroupsByfarm(farmId:` +
            inputVar.farmId +
            `){
            groupName
            id
            name
            farmId{id name}
            master {
              id
              groupName
            }
            system
            header
            useAsGroup 
            sector {
              header 
              id
              name

            }
          }
        }
        `,
        },
        {
          headers: {
            Authorization: 'JWT ' + inputVar.tokens.tokenIM,
          },
        }
      )
      .then((res) => {
        response = res;
      })
      .catch((error) => {
        response = error;
      });

    var result = {
      type: null,
      response: null,
      query: 'getSectorsGroup',
    };
    result.type = 'v';
    if (response.data) {
      if (response.data.errors && response.data.errors.length > 0) {
        result.response = response.data.errors[0].message;
        result.type = 'e';
      } else {
        if (response.data.data.getSectorgroupsByfarm) {
          result.type = 's';
          commit('setSectorsGroup', response.data.data.getSectorgroupsByfarm);
          result.response = response.data.data.getSectorgroupsByfarm;
        }
      }
    } else {
      result.type = 'e';
      result.response = response;
    }
    return result;
  },
  async createSectorsGroup({ commit }, inputVar) {
    var response = '';

    const header = inputVar.header ? `, header:${inputVar.header}` : '';
    const master = inputVar.master ? `, master:${inputVar.master}` : '';

    const queryUp = `mutation createSectorGroup {
      createSectorGroup(input: [{
      groupName: "${inputVar.groupName}",
      farmId: ${inputVar.farmId},
      name: "${inputVar.name}",
      system: ${inputVar.system}
      ${header}
      ${master}
      }]) {
      sectorGroups {
        id
        groupName
        master {
        id
        groupName
        }
        system
        farmId {
        id
        name
        }
        sector {
        id
        name
        }
      }
      }
    }`;

    await axios
      .post(
        myHttp,
        {
          query: queryUp,
        },
        {
          headers: {
            Authorization: 'JWT ' + inputVar.tokens.tokenIM,
          },
        }
      )
      .then((res) => {
        response = res;
      })
      .catch((error) => {
        response = error;
      });
    var result = {
      type: null,
      response: null,
      query: 'createSectorGroup',
    };
    result.type = 'v';
    if (response.data) {
      if (response.data.errors && response.data.errors.length > 0) {
        result.response = response.data.errors[0].message;
        result.type = 'e';
      } else {
        if (response.data.data.createSectorGroup) {
          result.type = 's';

          result.response = response.data.data.createSectorGroup;
        }
      }
    } else {
      result.type = 'e';
      result.response = response;
    }
    return result;
  },
  async inactiveSectorGroup({ commit }, inputVar) {
    var queryUp = '';
    var response = '';
    var queryVar = [];
    inputVar.ids.forEach(async function (item) {
      queryVar.push(item + `,`);
    });

    if (queryVar.length > 0) {
      queryUp = `mutation inactiveSectorGroup {
                  inactiveSectorGroup(ids:[ 
                  ${queryVar}]) {
                    response {	
                      id
                    }
                  }
                }`;
      await axios
        .post(
          myHttp,
          {
            query: queryUp,
          },
          {
            headers: {
              Authorization: 'JWT ' + inputVar.tokens.tokenIM,
            },
          }
        )
        .then((res) => {
          response = res;
        })
        .catch((error) => {
          response = error;
        });
    }

    var result = {
      type: null,
      response: null,
      query: 'inactiveSectorGroup',
    };
    result.type = 'v';
    if (response.data) {
      if (response.data.errors && response.data.errors.length > 0) {
        result.response = response.data.errors[0].message;
        result.type = 'e';
      } else {
        if (response.data.data.inactiveSectorGroup) {
          result.type = 's';

          result.response = response.data.data.inactiveSectorGroup;
        }
      }
    } else {
      result.type = 'e';
      result.response = response;
    }
    return result;
  },
  async deactivateFarm({ commit }, inputVar) {
    var queryUp = '';
    var response = '';
    var queryVar = [];
    inputVar.ids.forEach(async function (item) {
      queryVar.push(item + `,`);
    });

    if (queryVar.length > 0) {
      queryUp = `mutation inactiveFarm {
                  inactiveFarm(ids:[ 
                  ${queryVar}]) {
                    response {	
                      id
                    }
                  }
                }`;
      await axios
        .post(
          myHttp,
          {
            query: queryUp,
          },
          {
            headers: {
              Authorization: 'JWT ' + inputVar.tokens.tokenIM,
            },
          }
        )
        .then((res) => {
          response = res;
        })
        .catch((error) => {
          response = error;
        });
    }

    var result = {
      type: null,
      response: null,
      query: 'inactiveFarm',
    };
    result.type = 'v';
    if (response.data) {
      if (response.data.errors && response.data.errors.length > 0) {
        result.response = response.data.errors[0].message;
        result.type = 'e';
      } else {
        if (response.data.data.inactiveFarm) {
          result.type = 's';

          result.response = response.data.data.inactiveFarm;
        }
      }
    } else {
      result.type = 'e';
      result.response = response;
    }
    return result;
  },
  async updSectorsGroup({ commit }, inputVar) {
    var response = '';

    var queryUp =
      `mutation updateSectorGroup {
      updSectorGroup(id:` +
      inputVar.sectorGroupId +
      `, input: {groupName: "` +
      inputVar.groupName +
      `", farmId:` +
      inputVar.farmId +
      `, name: "` +
      inputVar.name +
      `" ` +
      (inputVar.header ? `,header:` + inputVar.header : '') +
      (inputVar.master !== null ? `,master:` + inputVar.master : '') +
      `, system:` +
      inputVar.system +
      `}) {
        sectorGroup {
          id
          groupName
          name
          master {
            id
            groupName
          }
          system
          farmId{id name}
          sector {
            id
            name
          }
        }
      }
    }
    `;

    await axios
      .post(
        myHttp,
        {
          query: queryUp,
        },
        {
          headers: {
            Authorization: 'JWT ' + inputVar.tokens.tokenIM,
          },
        }
      )
      .then((res) => {
        response = res;
      })
      .catch((error) => {
        response = error;
      });
    var result = {
      type: null,
      response: null,
      query: 'updSectorGroup',
    };
    result.type = 'v';
    if (response.data) {
      if (response.data.errors && response.data.errors.length > 0) {
        result.response = response.data.errors[0].message;
        result.type = 'e';
      } else {
        if (response.data.data.updSectorGroup) {
          result.type = 's';

          result.response = response.data.data.updSectorGroup;
        }
      }
    } else {
      result.type = 'e';
      result.response = response;
    }
    return result;
  },
  async deleteSectorsGroup({ commit }, inputVar) {
    var queryDel = '';
    var response = '';
    queryDel =
      ` mutation delSectorGroup {delSectorGroup(id:` +
      inputVar.id +
      `)
      {deletedId found}}`;

    await axios
      .post(
        myHttp,
        {
          query: queryDel,
        },
        {
          headers: {
            Authorization: 'JWT ' + inputVar.tokens.tokenIM,
          },
        }
      )
      .then((res) => {
        response = res;
      })
      .catch((error) => {
        response = error;
      });

    var result = {
      type: null,
      response: null,
      query: 'deleteSectorsGroup',
    };
    result.type = 'v';
    if (response.data) {
      if (response.data.errors && response.data.errors.length > 0) {
        result.response = response.data.errors[0].message;
        result.type = 'e';
      } else {
        if (response.data.data.delSectorGroup) {
          result.type = 's';
          result.response = response.data.data.delSectorGroup;
        }
      }
    } else {
      result.type = 'e';
      result.response = response;
    }
    return result;
  },

  // ERate
  async createERate({ commit }, inputVar) {
    var response = '';
    inputVar.rate.color = inputVar.rate.color.substring(0, 7);

    await axios
      .post(
        myHttp,
        {
          query:
            `mutation createERate {
          createERate(input: [{name: "` +
            inputVar.rate.name +
            `", color: "` +
            inputVar.rate.color +
            `"}]) {
            eRates {
              id
              name
              color
            }
          }
        }
         `,
        },
        {
          headers: {
            Authorization: 'JWT ' + inputVar.tokens.tokenIM,
          },
        }
      )
      .then((res) => {
        response = res;
      })
      .catch((error) => {
        response = error;
      });

    var result = {
      type: null,
      response: null,
      query: 'createERate',
    };
    result.type = 'v';
    if (response.data) {
      if (response.data.errors && response.data.errors.length > 0) {
        result.response = response.data.errors[0].message;
        result.type = 'e';
      } else {
        if (response.data.data.createERate) {
          result.type = 's';

          result.response = response.data.data.createERate;
        }
      }
    } else {
      result.type = 'e';
      result.response = response;
    }
    return result;
  },
  async saveFertFromExcel({ commit }, inputVar) {
    var response = '';

    await axios
      .post(
        myHttp,
        {
          query: `mutation CreateFertProgrammingFromExcel {
              createFertProgrammingFromExcel(excelBase64: "${inputVar.fileHash}") {
                successList {
                  farm
                  effectiveDt
                  qty
                  transTypeId
                  farmid
                  qtyDimension
                  fertOutName
                }
                errorList {
                msg
                objFert {
                  farm
                  effectiveDt
                  qty
                  transTypeId
                  farmid
                  qtyDimension
                  fertOutName
                }
              }
            }
          }`,
        },
        {
          headers: {
            Authorization: 'JWT ' + inputVar.tokens.tokenIM,
          },
        }
      )
      .then((res) => {
        response = res;
      })
      .catch((error) => {
        response = error;
      });

    var result = {
      type: null,
      response: null,
      query: 'createFertProgrammingFromExcel',
    };
    result.type = 'v';
    if (response.data) {
      if (response.data.errors && response.data.errors.length > 0) {
        result.response = response.data.errors[0].message;
        result.type = 'e';
      } else {
        if (response.data.data.createFertProgrammingFromExcel) {
          result.type = 's';

          result.response = response.data.data.createFertProgrammingFromExcel;
        }
      }
    } else {
      result.type = 'e';
      result.response = response;
    }
    return result;
  },
  async saveParcelFromExcel({ commit }, inputVar) {
    var response = '';

    await axios
      .post(
        myHttp,
        {
          query: `mutation CreateParcelFromExcel {
            createParcelFromExcel(excelBase64: "${inputVar.fileHash}") {
              fparcelOut {
                parcel
                mapRef
                area
              }
            }
          }`,
        },
        {
          headers: {
            Authorization: 'JWT ' + inputVar.tokens.tokenIM,
          },
        }
      )
      .then((res) => {
        response = res;
      })
      .catch((error) => {
        response = error;
      });

    var result = {
      type: null,
      response: null,
      query: 'createParcelFromExcel',
    };
    result.type = 'v';
    if (response.data) {
      if (response.data.errors && response.data.errors.length > 0) {
        result.response = response.data.errors[0].message;
        result.type = 'e';
      } else {
        if (response.data.data.createParcelFromExcel) {
          result.type = 's';

          result.response = response.data.data.createParcelFromExcel;
        }
      }
    } else {
      result.type = 'e';
      result.response = response;
    }
    return result;
  },
  async saveIrrigFromExcel({ commit }, inputVar) {
    var response = '';

    await axios
      .post(
        myHttp,
        {
          query:
            `mutation CreateWaterPrescription {
              createWaterPrescription(
                  excelBase64: "` +
            inputVar.fileHash +
            `") {
                        success {
                          farmId
                          farmName
                      }
                      errors {
                          farmId
                          farmName
                          error
                      }
                  }
              }`,
        },
        {
          headers: {
            Authorization: 'JWT ' + inputVar.tokens.tokenIM,
          },
        }
      )
      .then((res) => {
        response = res;
      })
      .catch((error) => {
        response = error;
      });

    var result = {
      type: null,
      response: null,
      query: 'CreateWaterPrescription',
    };
    result.type = 'v';
    if (response.data) {
      if (response.data.errors && response.data.errors.length > 0) {
        result.response = response.data.errors[0].message;
        result.type = 'e';
      } else {
        if (response.data.data.CreateWaterPrescription) {
          result.type = 's';

          result.response = response.data.data.CreateWaterPrescription;
        }
      }
    } else {
      result.type = 'e';
      result.response = response;
    }
    return result;
  },
  async updateERate({ commit }, inputVar) {
    var response = '';
    inputVar.rate.color = inputVar.rate.color.substring(0, 7);

    await axios
      .post(
        myHttp,
        {
          query:
            `mutation updErate {
          updERate(id: ` +
            inputVar.rate.id +
            `, input: {name: "` +
            inputVar.rate.name +
            `" , color: "` +
            inputVar.rate.color +
            `"}) {
            eRate {
              id
              name
              color
            }
          }
        }

         `,
        },
        {
          headers: {
            Authorization: 'JWT ' + inputVar.tokens.tokenIM,
          },
        }
      )
      .then((res) => {
        response = res;
      })
      .catch((error) => {
        response = error;
      });
    var result = {
      type: null,
      response: null,
      query: 'updateERate',
    };
    result.type = 'v';
    if (response.data) {
      if (response.data.errors && response.data.errors.length > 0) {
        result.response = response.data.errors[0].message;
        result.type = 'e';
      } else {
        if (response.data.data.updERate) {
          result.type = 's';

          result.response = response.data.data.updERate;
        }
      }
    } else {
      result.type = 'e';
      result.response = response;
    }
    return result;
  },
  async deleteERate({ commit }, inputVar) {
    var queryDel = '';
    var response = '';
    queryDel =
      `mutation delERate {
        delERate(id:` +
      inputVar.rate.id +
      `)
       {deletedId found}}`;

    await axios
      .post(
        myHttp,
        {
          query: queryDel,
        },
        {
          headers: {
            Authorization: 'JWT ' + inputVar.tokens.tokenIM,
          },
        }
      )
      .then((res) => {
        response = res;
      })
      .catch((error) => {
        response = error;
      });

    var result = {
      type: null,
      response: null,
      query: 'deleteERate',
    };
    result.type = 'v';
    if (response.data) {
      if (response.data.errors && response.data.errors.length > 0) {
        result.response = response.data.errors[0].message;
        result.type = 'e';
      } else {
        if (response.data.data.delERate) {
          result.type = 's';

          result.response = response.data.data.delERate;
        }
      }
    } else {
      result.type = 'e';
      result.response = response;
    }
    return result;
  },
  // ESeason
  async createESeason({ commit }, inputVar) {
    var response = '';
    await axios
      .post(
        myHttp,
        {
          query:
            `mutation createESeason {
        createESeason(input: [{name: "` +
            inputVar.season.name +
            `", accountId: ` +
            inputVar.season.accountId.id +
            `, startDt: "` +
            inputVar.season.startDt +
            `", endDt:"` +
            inputVar.season.endDt +
            `"}]) {
          eSeasons {
            id
            name

          }
        }
      }

       `,
        },
        {
          headers: {
            Authorization: 'JWT ' + inputVar.tokens.tokenIM,
          },
        }
      )
      .then((res) => {
        response = res;
      })
      .catch((error) => {
        response = error;
      });
    var result = {
      type: null,
      response: null,
      query: 'createESeason',
    };
    result.type = 'v';
    if (response.data) {
      if (response.data.errors && response.data.errors.length > 0) {
        result.response = response.data.errors[0].message;
        result.type = 'e';
      } else {
        if (response.data.data.createESeason) {
          result.type = 's';

          result.response = response.data.data.createESeason;
        }
      }
    } else {
      result.type = 'e';
      result.response = response;
    }
    return result;
  },
  async updateESeason({ commit }, inputVar) {
    var response = '';
    await axios
      .post(
        myHttp,
        {
          query:
            `mutation updSeason {
        updESeason(id: ` +
            inputVar.season.id +
            `, input: {name: "` +
            inputVar.season.name +
            `", accountId: ` +
            inputVar.season.accountId.id +
            `, startDt: "` +
            inputVar.season.startDt +
            `", endDt:"` +
            inputVar.season.endDt +
            `"}) {
          eSeason {
            id
            name

        }
      }

       `,
        },
        {
          headers: {
            Authorization: 'JWT ' + inputVar.tokens.tokenIM,
          },
        }
      )
      .then((res) => {
        response = res;
      })
      .catch((error) => {
        response = error;
      });
    var result = {
      type: null,
      response: null,
      query: 'updateESeason',
    };
    result.type = 'v';
    if (response.data) {
      if (response.data.errors && response.data.errors.length > 0) {
        result.response = response.data.errors[0].message;
        result.type = 'e';
      } else {
        if (response.data.data.updESeason) {
          result.type = 's';

          result.response = response.data.data.updESeason;
        }
      }
    } else {
      result.type = 'e';
      result.response = response;
    }
    return result;
  },
  async deleteESeason({ commit }, inputVar) {
    var queryDel = '';
    var response = '';
    queryDel =
      `mutation delESeason {
      delESeason(id:` +
      inputVar.season.id +
      `)
     {deletedId found}}`;

    await axios
      .post(
        myHttp,
        {
          query: queryDel,
        },
        {
          headers: {
            Authorization: 'JWT ' + inputVar.tokens.tokenIM,
          },
        }
      )
      .then((res) => {
        response = res;
      })
      .catch((error) => {
        response = error;
      });

    var result = {
      type: null,
      response: null,
      query: 'delESeason',
    };
    result.type = 'v';
    if (response.data) {
      if (response.data.errors && response.data.errors.length > 0) {
        result.response = response.data.errors[0].message;
        result.type = 'e';
      } else {
        if (response.data.data.delESeason) {
          result.type = 's';

          result.response = response.data.data.delESeason;
        }
      }
    } else {
      result.type = 'e';
      result.response = response;
    }
    return result;
  },
  // ETariff
  async createETariff({ commit }, inputVar) {
    var response = '';

    if (inputVar.tariff.solarPeakTime === null) inputVar.tariff.solarPeakTime = '00:00:00';
    if (inputVar.tariff.preferredEndTime === null) inputVar.tariff.preferredEndTime = '00:00:00';

    await axios
      .post(
        myHttp,
        {
          query:
            `mutation createETariff {
            createETariff(input: [{name: "` +
            inputVar.tariff.name +
            `", isSolar: ` +
            inputVar.tariff.isSolar +
            `,
              isGenerator:` +
            inputVar.tariff.isGenerator +
            `, solarPeakTime:"` +
            inputVar.tariff.solarPeakTime +
            `", preferredEndTime:"` +
            inputVar.tariff.preferredEndTime +
            `"}]) {
                eTariffs {
                  id
                  name

                  isSolar
                  solarPeakTime
                  isGenerator
                  preferredEndTime
                }
            }
          }

           `,
        },
        {
          headers: {
            Authorization: 'JWT ' + inputVar.tokens.tokenIM,
          },
        }
      )
      .then((res) => {
        response = res;
      })
      .catch((error) => {
        response = error;
      });
    var result = {
      type: null,
      response: null,
      query: 'createETariff',
    };
    result.type = 'v';
    if (response.data) {
      if (response.data.errors && response.data.errors.length > 0) {
        result.response = response.data.errors[0].message;
        result.type = 'e';
      } else {
        if (response.data.data.createETariff) {
          result.type = 's';

          result.response = response.data.data.createETariff;
        }
      }
    } else {
      result.type = 'e';
      result.response = response;
    }
    return result;
  },
  async updateETariff({ commit }, inputVar) {
    var response = '';
    if (inputVar.tariff.solarPeakTime === null) inputVar.tariff.solarPeakTime = '00:00:00';
    if (inputVar.tariff.preferredEndTime === null) inputVar.tariff.preferredEndTime = '00:00:00';

    await axios
      .post(
        myHttp,
        {
          query:
            `mutation updTarrif {
            updETariff(id: ` +
            inputVar.tariff.id +
            `, input: {name: "` +
            inputVar.tariff.name +
            `"` +
            `, isSolar:` +
            inputVar.tariff.isSolar +
            `,
              isGenerator:` +
            inputVar.tariff.isGenerator +
            `, solarPeakTime:"` +
            inputVar.tariff.solarPeakTime +
            `" , preferredEndTime:"` +
            inputVar.tariff.preferredEndTime +
            `"}) {
                eTariff {
                  id
                  name

                  isSolar
                  solarPeakTime
                  isGenerator
                  preferredEndTime
                }
            }
          }
           `,
        },
        {
          headers: {
            Authorization: 'JWT ' + inputVar.tokens.tokenIM,
          },
        }
      )
      .then((res) => {
        response = res;
      })
      .catch((error) => {
        response = error;
      });

    var result = {
      type: null,
      response: null,
      query: 'updateETariff',
    };
    result.type = 'v';
    if (response.data) {
      if (response.data.errors && response.data.errors.length > 0) {
        result.response = response.data.errors[0].message;
        result.type = 'e';
      } else {
        if (response.data.data.updETariff) {
          result.type = 's';

          result.response = response.data.data.updETariff;
        }
      }
    } else {
      result.type = 'e';
      result.response = response;
    }
    return result;
  },
  async deleteETariff({ commit }, inputVar) {
    var queryDel = '';
    var response = '';

    queryDel =
      `mutation delETariff {
          delETariff(id:` +
      inputVar.tariff.id +
      `)
         {deletedId found}}`;

    await axios
      .post(
        myHttp,
        {
          query: queryDel,
        },
        {
          headers: {
            Authorization: 'JWT ' + inputVar.tokens.tokenIM,
          },
        }
      )
      .then((res) => {
        response = res;
      })
      .catch((error) => {
        response = error;
      });

    var result = {
      type: null,
      response: null,
      query: 'deleteETariff',
    };
    result.type = 'v';
    if (response.data) {
      if (response.data.errors && response.data.errors.length > 0) {
        result.response = response.data.errors[0].message;
        result.type = 'e';
      } else {
        if (response.data.data.delETariff) {
          result.type = 's';

          result.response = response.data.data.delETariff;
        }
      }
    } else {
      result.type = 'e';
      result.response = response;
    }
    return result;
  },
  // ESupplier
  async createESupplier({ commit }, inputVar) {
    var response = '';
    var q =
      `mutation createESupplier {
        createESupplier(input: [{name: "` +
      inputVar.supplier.name +
      `"}]) {
          eSuppliers {
            id
            name
          }
        }
      }
       `;

    await axios
      .post(
        myHttp,
        {
          query: q,
        },
        {
          headers: {
            Authorization: 'JWT ' + inputVar.tokens.tokenIM,
          },
        }
      )
      .then((res) => {
        response = res;
      })
      .catch((error) => {
        response = error;
      });

    var result = {
      type: null,
      response: null,
      query: 'createESupplier',
    };
    result.type = 'v';
    if (response.data) {
      if (response.data.errors && response.data.errors.length > 0) {
        result.response = response.data.errors[0].message;
        result.type = 'e';
      } else {
        if (response.data.data.createESupplier) {
          result.type = 's';

          result.response = response.data.data.createESupplier;
        }
      }
    } else {
      result.type = 'e';
      result.response = response;
    }
    return result;
  },
  async updateESupplier({ commit }, inputVar) {
    var response = '';
    await axios
      .post(
        myHttp,
        {
          query:
            `mutation updSupplier {
          updESupplier(id: ` +
            inputVar.supplier.id +
            `, input: {name: "` +
            inputVar.supplier.name +
            `"}) {
            eSupplier {
              id
              name
            }
          }
        }
         `,
        },
        {
          headers: {
            Authorization: 'JWT ' + inputVar.tokens.tokenIM,
          },
        }
      )
      .then((res) => {
        response = res;
      })
      .catch((error) => {
        response = error;
      });
    var result = {
      type: null,
      response: null,
      query: 'updateESupplier',
    };
    result.type = 'v';
    if (response.data) {
      if (response.data.errors && response.data.errors.length > 0) {
        result.response = response.data.errors[0].message;
        result.type = 'e';
      } else {
        if (response.data.data.updESupplier) {
          result.type = 's';

          result.response = response.data.data.updESupplier;
        }
      }
    } else {
      result.type = 'e';
      result.response = response;
    }
    return result;
  },
  async deleteESupplier({ commit }, inputVar) {
    var queryDel = '';
    var response = '';
    queryDel =
      `mutation delESupplier {
        delESupplier(id:` +
      inputVar.supplier.id +
      `)
       {deletedId found}}`;

    await axios
      .post(
        myHttp,
        {
          query: queryDel,
        },
        {
          headers: {
            Authorization: 'JWT ' + inputVar.tokens.tokenIM,
          },
        }
      )
      .then((res) => {
        response = res;
      })
      .catch((error) => {
        response = error;
      });

    var result = {
      type: null,
      response: null,
      query: 'deleteESupplier',
    };
    result.type = 'v';
    if (response.data) {
      if (response.data.errors && response.data.errors.length > 0) {
        result.response = response.data.errors[0].message;
        result.type = 'e';
      } else {
        if (response.data.data.delESupplier) {
          result.type = 's';

          result.response = response.data.data.delESupplier;
        }
      }
    } else {
      result.type = 'e';
      result.response = response;
    }
    return result;
  },
  // ESupplierRate

  async createESupplierRate({ commit }, inputVar) {
    var queryUp = '';
    var response = '';
    var queryVar = [];

    inputVar.supplierRate.forEach(async function (item) {
      if (item.id === null) {
        queryVar.push(
          `{rate: ` +
            item.rate +
            `, supplier:` +
            item.supplier +
            `, tariff:` +
            item.tariff +
            `, cost:` +
            item.cost +
            `}`
        );
      }
    });

    if (queryVar.length > 0) {
      queryUp =
        `mutation createSupplierRate {
              createESupplierRate(input:[` +
        queryVar +
        `]) {
                eSupplierRates {
                  id

                  rate {
                    id
                    name
                  }
                cost
                }
              }
            }

            `;

      await axios
        .post(
          myHttp,
          {
            query: queryUp,
          },
          {
            headers: {
              Authorization: 'JWT ' + inputVar.tokens.tokenIM,
            },
          }
        )
        .then((res) => {
          response = res;
        })
        .catch((error) => {
          response = error;
        });
    }

    var result = {
      type: null,
      response: null,
      query: 'createESupplierRate',
    };
    result.type = 'v';
    if (response.data) {
      if (response.data.errors && response.data.errors.length > 0) {
        result.response = response.data.errors[0].message;
        result.type = 'e';
      } else {
        if (response.data.data.createESupplierRate) {
          result.type = 's';

          result.response = response.data.data.createESupplierRate;
        }
      }
    } else {
      result.type = 'e';
      result.response = response;
    }
    return result;
  },
  async updateESupplierRate({ commit }, inputVar) {
    var queryUp = '';
    var response = '';
    var result = {
      type: null,
      response: null,
      query: 'updateESupplierRate',
    };
    inputVar.supplierRate.forEach(async function (item) {
      if (item.cost === null) {
        item.cost = 0;
      }
      if (item.id) {
        queryUp =
          'id:' +
          item.id +
          `, input: {rate: ` +
          item.rate +
          `, supplier:` +
          item.supplier +
          `, tariff:` +
          item.tariff +
          `,
            cost: ` +
          item.cost +
          `}`;

        var a =
          `mutation updSupplierRate {
          updESupplierRate(` +
          queryUp +
          `
          ) {
            eSupplierRate {
              id

              rate {
                id
                name
              }
            cost
            }
          }
        }

         `;

        await axios
          .post(
            myHttp,
            {
              query: a,
            },
            {
              headers: {
                Authorization: 'JWT ' + inputVar.tokens.tokenIM,
              },
            }
          )
          .then((res) => {
            response = res;
          })
          .catch((error) => {
            response = error;
          });
      }
    });

    result.type = 'v';
    if (response.data) {
      if (response.data.errors && response.data.errors.length > 0) {
        result.response = response.data.errors[0].message;
        result.type = 'e';
      } else {
        if (response.data.data.updESupplierRate) {
          result.type = 's';

          result.response = response.data.data.updESupplierRate;
        }
      }
    } else {
      result.type = 'e';
      result.response = response;
    }
    result.type = 's';
    return result;
  },
  async deleteESupplierRate({ commit }, inputVar) {
    var queryDel = '';
    var response = '';
    queryDel =
      `mutation delESupplierRate {
        delESupplierRate(id:` +
      inputVar.id +
      `)
       {deletedId found}}`;

    await axios
      .post(
        myHttp,
        {
          query: queryDel,
        },
        {
          headers: {
            Authorization: 'JWT ' + inputVar.tokens.tokenIM,
          },
        }
      )
      .then((res) => {
        response = res;
      })
      .catch((error) => {
        response = error;
      });
    var result = {
      type: null,
      response: null,
      query: 'deleteESupplierRate',
    };
    result.type = 'v';
    if (response.data) {
      if (response.data.errors && response.data.errors.length > 0) {
        result.response = response.data.errors[0].message;
        result.type = 'e';
      } else {
        if (response.data.data.delESupplierRate) {
          result.type = 's';

          result.response = response.data.data.delESupplierRate;
        }
      }
    } else {
      result.type = 'e';
      result.response = response;
    }
    return result;
  },

  // ETariffPlan
  async createETariffPlan({ commit }, inputVar) {
    var queryUp = '';
    var response = '';
    var queryVar = [];

    inputVar.tariffPlan.forEach(async function (item) {
      queryVar.push(
        `{season: "` +
          item.season +
          `",
          rate: ` +
          item.rate.id +
          `, tariff:` +
          item.tariff.id +
          `, supplier:` +
          item.supplier.id +
          `,
          startTime: "` +
          item.startTime +
          `", endTime:"` +
          item.endTime +
          `"}`
      );
    });

    if (queryVar.length > 0) {
      queryUp =
        `mutation createETariffPlan {
          createETariffPlan(input:[` +
        queryVar +
        `]) {
            eTariffPlans {
              id
              season
              rate {
                id
                name
              }
              tariff{id name}
              startTime
              endTime
            }
          }
        }
        `;

      await axios
        .post(
          myHttp,
          {
            query: queryUp,
          },
          {
            headers: {
              Authorization: 'JWT ' + inputVar.tokens.tokenIM,
            },
          }
        )
        .then((res) => {
          response = res;
        })
        .catch((error) => {
          response = error;
        });
    }

    var result = {
      type: null,
      response: null,
      query: 'createETariffPlan',
    };
    result.type = 'v';
    if (response.data) {
      if (response.data.errors && response.data.errors.length > 0) {
        result.response = response.data.errors[0].message;
        result.type = 'e';
      } else {
        if (response.data.data.createETariffPlan) {
          result.type = 's';

          result.response = response.data.data.createETariffPlan;
        }
      }
    } else {
      result.type = 'e';
      result.response = response;
    }
    return result;
  },
  async updateETariffPlan({ commit }, inputVar) {
    var queryUp = '';
    var response = '';
    var result = {
      type: null,
      response: null,
      query: 'updateETariffPlan',
    };
    inputVar.tariffPlan.forEach(async function (item) {
      queryUp =
        'id:' +
        item.id +
        `, input: {
         season: "` +
        item.season +
        `",
        rate: ` +
        item.rate.id +
        `, tariff:` +
        item.tariff.id +
        `, supplier:` +
        item.supplier.id +
        `,
        startTime: "` +
        item.startTime +
        `", endTime:"` +
        item.endTime +
        `"}`;

      var a =
        `mutation updEtarrifPlan {
      updETariffPlan(` +
        queryUp +
        `
      ) {
        eTariffPlan {
          id
          season
          rate {
            id
            name
          }
          tariff{id name}
          startTime
          endTime
        }
      }
    }
     `;

      await axios
        .post(
          myHttp,
          {
            query: a,
          },
          {
            headers: {
              Authorization: 'JWT ' + inputVar.tokens.tokenIM,
            },
          }
        )
        .then((res) => {
          response = res;
        })
        .catch((error) => {
          response = error;
        });

      result.type = 'v';
      if (response.data) {
        if (response.data.errors && response.data.errors.length > 0) {
          result.response = response.data.errors[0].message;
          result.type = 'e';
        } else {
          if (response.data.data.updETariffPlan) {
            result.type = 's';

            result.response = response.data.data.updETariffPlan;
          }
        }
      } else {
        result.type = 'e';
        result.response = response;
      }
    });
    result.type = 's';
    return result;
  },
  async deleteETariffPlan({ commit }, inputVar) {
    var queryUp = '';
    var response = '';
    var queryVar = [];
    inputVar.tariffPlan.forEach(async function (item) {
      queryVar.push(item.id + `,`);
    });

    if (queryVar.length > 0) {
      queryUp =
        `mutation delETariffPlan {
        delETariffPlan(ids:[` +
        queryVar +
        `]) {
        response {
          id

        }
       }
      }
      `;
      await axios
        .post(
          myHttp,
          {
            query: queryUp,
          },
          {
            headers: {
              Authorization: 'JWT ' + inputVar.tokens.tokenIM,
            },
          }
        )
        .then((res) => {
          response = res;
        })
        .catch((error) => {
          response = error;
        });
    }
    var result = {
      type: null,
      response: null,
      query: 'deleteETariffPlan',
    };
    result.type = 'v';
    if (response.data) {
      if (response.data.errors && response.data.errors.length > 0) {
        result.response = response.data.errors[0].message;
        result.type = 'e';
      } else {
        if (response.data.data.delETariffPlan) {
          result.type = 's';

          result.response = response.data.data.delETariffPlan;
        }
      }
    } else {
      result.type = 'e';
      result.response = response;
    }
    return result;
  },
  //
  async delSectors({ commit }, inputVar) {
    var queryUp = '';
    var response = '';
    var queryVar = [];
    inputVar.flistSectors.forEach(async function (item) {
      queryVar.push(item.id + `,`);
    });

    if (queryVar.length > 0) {
      queryUp =
        `mutation delSectors {
        delSectors(ids:[` +
        queryVar +
        `]) {
       deleted
       }
      }
      `;

      await axios
        .post(
          myHttp,
          {
            query: queryUp,
          },
          {
            headers: {
              Authorization: 'JWT ' + inputVar.tokens.tokenIM,
            },
          }
        )
        .then((res) => {
          response = res;
        })
        .catch((error) => {
          response = error;
        });
    }
    var result = {
      type: null,
      response: null,
      query: 'deleteSectors',
    };
    result.type = 'v';
    if (response.data) {
      if (response.data.errors && response.data.errors.length > 0) {
        result.response = response.data.errors[0].message;
        result.type = 'e';
      } else {
        if (response.data.data.delSectors) {
          result.type = 's';

          result.response = response.data.data.delSectors;
        }
      }
    } else {
      result.type = 'e';
      result.response = response;
    }
    return result;
  },
  async updSectors({ commit }, inputVar) {
    var queryUp = '';
    var response = '';
    var queryVar = [];
    inputVar.flistSectors.forEach(async function (item) {
      if (item.cloudFlow === null) item.cloudFlow = '';
      if (item.output === null) item.output = '';
      if (item.auxOutput === null) item.auxOutput = '';
      if (parseInt(item.auxOutput) === 0) item.auxOutput = '00000000';
      if (parseInt(item.output) === 0) item.output = '00000000';
      if (item.groupId.id === null) item.groupId.id = -1;
      if (item.waterMeter === null) item.waterMeter = -1;
      queryVar.push(
        '{sectorId:' +
          item.id +
          ` , name:"` +
          item.name +
          `" , cloudFlow:"` +
          item.cloudFlow +
          `" , output: "` +
          item.output +
          `" , auxOutput: "` +
          item.auxOutput +
          `" , groupId: ` +
          item.groupId.id +
          ` , waterMeter:` +
          item.waterMeter +
          `}`
      );
      if (item.cloudFlow === '') item.cloudFlow = null;
      if (item.output === '') item.output = null;
      if (item.auxOutput === '') item.auxOutput = null;
      if (item.groupId.id === -1) item.groupId.id = null;
      if (item.waterMeter === -1) item.waterMeter = null;
    });
    if (queryVar.length > 0) {
      queryUp =
        `mutation upd_sectors {
          updSectors(sectors:[` +
        queryVar +
        `]) {
            sectorOut {
              id
              name
              cloudFlow
              output
              auxOutput
              waterMeter
              groupId{
                id
              }
            }
          }
        }
        `;

      await axios
        .post(
          myHttp,
          {
            query: queryUp,
          },
          {
            headers: {
              Authorization: 'JWT ' + inputVar.tokens.tokenIM,
            },
          }
        )
        .then((res) => {
          response = res;
        })
        .catch((error) => {
          response = error;
        });
    }

    var result = {
      type: null,
      response: null,
      query: 'updSectors',
    };
    result.type = 'v';
    if (response.data) {
      if (response.data.errors && response.data.errors.length > 0) {
        result.response = response.data.errors[0].message;
        result.type = 'e';
      } else {
        if (response.data.data.updSectors) {
          result.type = 's';

          result.response = response.data.data.updSectors;
        }
      }
    } else {
      result.type = 'e';
      result.response = response;
    }
    return result;
  },
  async updAsGroups({ commit }, inputVar) {
    var queryUp = '';
    var response = '';
    var queryVar = [];
    inputVar.flistSectorsGroup.forEach(async function (item) {
      if (item.useAsGroup === null) item.useAsGroup = false;
      queryVar.push(`{sectorGroupId: ${item.id} , useAsGroup: ${item.useAsGroup}}`);
    });
    if (queryVar.length > 0) {
      queryUp = `mutation upd_as_groups {
          updAsGroups(sectorsGroup:[${queryVar.join(', ')}]) {
            sectorOut {
              id
              groupName
              name
              useAsGroup
              master {
                id
                groupName
              }
              system
              farmId{id name}
              sector {
                id
                name
              }
            }
          }
        }
        `;

      await axios
        .post(
          myHttp,
          {
            query: queryUp,
          },
          {
            headers: {
              Authorization: 'JWT ' + inputVar.tokens.tokenIM,
            },
          }
        )
        .then((res) => {
          response = res;
        })
        .catch((error) => {
          response = error;
        });
    }

    var result = {
      type: null,
      response: null,
      query: 'updAsGroups',
    };
    result.type = 'v';
    if (response.data) {
      if (response.data.errors && response.data.errors.length > 0) {
        result.response = response.data.errors[0].message;
        result.type = 'e';
      } else {
        if (response.data.data.updAsGroups) {
          result.type = 's';

          result.response = response.data.data.updAsGroups;
        }
      }
    } else {
      result.type = 'e';
      result.response = response;
    }
    return result;
  },
  async getControllers({ commit }, inputVar) {
    var response = '';
    var q =
      `mutation get_controllers {
      getControllers(token:"` +
      inputVar.tokenProgres +
      `",
      sync:` +
      inputVar.sync +
      `, getall:` +
      inputVar.getAll +
      `) {
        controllers {
          id
          name
          externalId
          serial
          monocable
          radio
          outputs
          plus
          typeId {
            model
          }
          farm {
            id
            name
          }
          accountId {
            id
            accountName
          }
        }
      }
    } `;

    await axios
      .post(
        myHttp,
        {
          query: q,
        },
        {
          headers: {
            Authorization: 'JWT ' + inputVar.tokenIM,
          },
        }
      )
      .then((res) => {
        response = res;
      })
      .catch((error) => {
        response = error;
      });
    var result = {
      type: null,
      response: null,
      query: 'getControllers',
    };

    result.type = 'v';
    if (response.data) {
      if (response.data.errors && response.data.errors.length > 0) {
        result.response = response.data.errors[0].message;
        result.type = 'e';
      } else {
        if (response.data.data.getControllers) {
          result.type = 's';
          commit('setControllers', response.data.data.getControllers);
          result.response = response.data.data.getControllers;
        }
      }
    } else {
      result.type = 'e';
      result.response = response;
    }
    return result;
  },

  async updateControllers({ commit }, controllersParam) {
    var queryUp = '';
    var response = '';
    var queryVar = [];
    controllersParam.flistControllers.forEach(async function (item) {
      if (item.outputs === null) item.outputs = -1;
      queryVar.push(
        '{id:' +
          item.id +
          ` , monocable:` +
          item.monocable +
          ` , radio:` +
          item.radio +
          ` , outputs: ` +
          item.outputs +
          ` , accountId: ` +
          item.accountId +
          ` , sync: ` +
          item.sync +
          ` , createFarm:` +
          item.createfarm +
          `}`
      );
      if (item.outputs === -1) item.outputs = null;
    });
    if (queryVar.length > 0) {
      queryUp =
        `mutation upd_controllers {
        updControllers(token:"` +
        controllersParam.tokens.tokenProgres +
        `"
          controllers:[` +
        queryVar +
        `]) {
          response {
            id
            name
            monocable
            radio
            outputs
            typeId {
              id
              externalId
            }
            externalId
          }
        }
      }
      `;

      await axios
        .post(
          myHttp,
          {
            query: queryUp,
          },
          {
            headers: {
              Authorization: 'JWT ' + controllersParam.tokens.tokenIM,
            },
          }
        )
        .then((res) => {
          response = res;
        })
        .catch((error) => {
          response = error;
        });
    }
    var result = {
      type: null,
      response: null,
      query: 'updControllers',
    };
    result.type = 'v';

    if (response.data) {
      if (response.data.errors && response.data.errors.length > 0) {
        result.response = response.data.errors[0].message;
        result.type = 'e';
      } else {
        if (response.data.data.updControllers) {
          result.type = 's';

          result.response = response.data.data.updControllers;
        }
      }
    } else {
      result.type = 'e';
      result.response = response;
    }
    return result;
  },
  async getImUsersContac({ commit }, inputVar) {
    var response = '';
    var queryUp =
      `query getImUser {
        imuser (farmId:` +
      inputVar.farmId +
      `, contactOnly:true) {
          id
          name
          farmId {
            id
            name
          }
          userContact {
            id
            name
            typeId {
              id
              contactType
            }
          }
        }
      }

      `;

    await axios
      .post(
        myHttp,
        {
          query: queryUp,
        },
        {
          headers: {
            Authorization: 'JWT ' + inputVar.tokens.tokenIM,
          },
        }
      )
      .then((res) => {
        response = res;
      })
      .catch((error) => {
        response = error;
      });

    var result = {
      type: null,
      response: null,
      query: 'getImUsersContac',
    };
    result.type = 'v';
    if (response.data) {
      if (response.data.errors && response.data.errors.length > 0) {
        result.response = response.data.errors[0].message;
        result.type = 'e';
      } else {
        if (response.data.data.imuser) {
          result.type = 's';
          result.response = response.data.data.imuser;
        }
      }
    } else {
      result.type = 'e';
      result.response = response;
    }
    return result;
  },
  async getImUsers({ commit }, inputVar) {
    var response = '';
    var a = `query getImUser {
  imuser {
    name
    id
    user {
      id
      username
      password
    }
    userContact {
      id
      name
      typeId {
        id
        contactType
      }
    }
    farmId {
      id
      name
    }
    userProfileId {
      id
      profileName
    }
  }
}

`;

    await axios
      .post(
        myHttp,
        {
          query: a,
        },
        {
          headers: {
            Authorization: 'JWT ' + inputVar.tokens.tokenIM,
          },
        }
      )
      .then((res) => {
        response = res;
      })
      .catch((error) => {
        response = error;
      });
    var result = {
      type: null,
      response: null,
      query: 'getImUsers',
    };

    result.type = 'v';
    if (response.data) {
      if (response.data.errors && response.data.errors.length > 0) {
        result.response = response.data.errors[0].message;
        result.type = 'e';
      } else {
        if (response.data.data.imuser) {
          result.type = 's';
          commit('setImusers', response.data.data.imuser);
          result.response = response.data.data.imuser;
        }
      }
    } else {
      result.type = 'e';
      result.response = response;
    }
    return result;
  },
  async createImuser({ commit }, inputVar) {
    var queryUp = '';
    var response = '';
    var queryVar = [];
    var pass = Math.random().toString(36).slice(2) + Math.random().toString(36).toUpperCase().slice(2);
    pass = 'rega2022';
    inputVar.imusers.forEach(async function (item) {
      queryVar.push(
        `{ name: "` +
          item.name +
          `" username: "` +
          item.user.username +
          `" password: "` +
          pass +
          `", userProfileId: ` +
          item.userProfileId.id +
          `, farmId: [` +
          item.farmId +
          `]}
            `
      );
    });
    if (queryVar.length > 0) {
      queryUp =
        `mutation create_imUser {
              createImuser(imusers: [` +
        queryVar +
        `]) {
                imuserOut {
                  id
                  name
                  user {
                    id
                    username
                  }


                  farmId {
                    id
                    name
                  }
                  userProfileId {
                    id
                    profileName
                  }

                }
              }
            }
        `;

      await axios
        .post(
          myHttp,
          {
            query: queryUp,
          },
          {
            headers: {
              Authorization: 'JWT ' + inputVar.tokens.tokenIM,
            },
          }
        )
        .then((res) => {
          response = res;
        })
        .catch((error) => {
          response = error;
        });
    }

    var result = {
      type: null,
      response: null,
      query: 'createImuser',
    };
    result.type = 'v';
    if (response.data) {
      if (response.data.errors && response.data.errors.length > 0) {
        result.response = response.data.errors[0].message;
        result.type = 'e';
      } else {
        if (response.data.data.createImuser) {
          result.type = 's';
          result.response = response.data.data.createImuser;
        }
      }
    } else {
      result.type = 'e';
      result.response = response;
    }
    return result;
  },
  async updateImuser({ commit }, inputVar) {
    var queryUp = '';
    var response = '';
    var queryVar = [];
    if (inputVar.settings) {
      queryVar.push(`{imuserId:  ` + inputVar.id + `, background: "` + inputVar.bg + `"}`);
    } else {
      inputVar.imusers.forEach(async function (item) {
        queryVar.push(
          `{imuserId:  ` +
            item.id +
            `, name: "` +
            item.name +
            `" username: "` +
            item.user.username +
            `", userProfileId: ` +
            item.userProfileId.id +
            `, farmId: [` +
            item.farmId +
            `]}
        `
        );
      });
    }
    if (queryVar.length > 0) {
      queryUp =
        `mutation update_imUser {
          updImuser(imusers: [` +
        queryVar +
        `]) {
            imuserOut {
              id
              name
              user {
                id
                username
              }


              farmId {
                id
                name
              }
              userProfileId {
                id
                profileName
              }

            }
          }
        }


     `;

      await axios
        .post(
          myHttp,
          {
            query: queryUp,
          },
          {
            headers: {
              Authorization: 'JWT ' + inputVar.tokens.tokenIM,
            },
          }
        )
        .then((res) => {
          response = res;
        })
        .catch((error) => {
          response = error;
        });
    }

    var result = {
      type: null,
      response: null,
      query: 'updateImuser',
    };
    result.type = 'v';
    if (response.data) {
      if (response.data.errors && response.data.errors.length > 0) {
        result.response = response.data.errors[0].message;
        result.type = 'e';
      } else {
        if (response.data.data.updImuser) {
          result.type = 's';
          result.response = response.data.data.updImuser;
        }
      }
    } else {
      result.type = 'e';
      result.response = response;
    }
    return result;
  },
  async delImuser({ commit }, inputVar) {
    var queryUp = '';
    var response = '';
    var queryVar = [];
    inputVar.imusers.forEach(async function (item) {
      queryVar.push(item.id + `,`);
    });

    if (queryVar.length > 0) {
      queryUp =
        `mutation delImuser {
    delImuser(ids:[` +
        queryVar +
        `]) {
      response {
        id
        name
      }
     }
   }
   `;

      await axios
        .post(
          myHttp,
          {
            query: queryUp,
          },
          {
            headers: {
              Authorization: 'JWT ' + inputVar.tokens.tokenIM,
            },
          }
        )
        .then((res) => {
          response = res;
        })
        .catch((error) => {
          response = error;
        });
    }

    var result = {
      type: null,
      response: null,
      query: 'delImuser',
    };
    result.type = 'v';
    if (response.data) {
      if (response.data.errors && response.data.errors.length > 0) {
        result.response = response.data.errors[0].message;
        result.type = 'e';
      } else {
        if (response.data.data.delImuser) {
          result.type = 's';
          result.response = response.data.data.delImuser;
        }
      }
    } else {
      result.type = 'e';
      result.response = response;
    }
    return result;
  },
  async updImuserAccount({ commit }, inputVar) {
    var queryUp = '';
    var response = '';
    var queryVar = [];
    queryVar.push(
      `imuserId:  ` +
        inputVar.userId +
        `, accountId: ` +
        inputVar.accountId +
        `
     `
    );

    if (queryVar.length > 0) {
      queryUp =
        `mutation group_to_user {
      groupToUser(` +
        queryVar +
        `) {
           user {
             id
           }
         }
       }
    `;

      await axios
        .post(
          myHttp,
          {
            query: queryUp,
          },
          {
            headers: {
              Authorization: 'JWT ' + inputVar.tokens.tokenIM,
            },
          }
        )
        .then((res) => {
          response = res;
        })
        .catch((error) => {
          response = error;
        });
    }

    var result = {
      type: null,
      response: null,
      query: 'updImuserAccount',
    };
    result.type = 'v';
    if (response.data) {
      if (response.data.errors && response.data.errors.length > 0) {
        result.response = response.data.errors[0].message;
        result.type = 'e';
      } else {
        if (response.data.data.groupToUser) {
          result.type = 's';
          result.response = response.data.data.groupToUser;
        }
      }
    } else {
      result.type = 'e';
      result.response = response;
    }
    return result;
  },
  async getImuserProfile({ commit }, inputVar) {
    var response = '';
    await axios
      .post(
        myHttp,
        {
          query: `query imuser_profile {
  imuserProfile {
    imuserId
    imuserName
    imuserBg
    accts {
      id
      accountName
      accountStatus {
        id
        accountStatus
      }
      unitProfId {
        id
        density {
          name
        }
        area {
          name
        }
        wVolume {
          name
        }
        wFlow {
          name
        }
        fFlow {
          name
        }
        massArea {
          name
        }
        mass {
          name
        }
        wVolumeArea {
          name
        }
        fVolume {
          name
        }
        fVolumeArea {
          name
        }
        fFlow {
          name
        }
      }
      cloudUserId {
        id
        cloudUser
      }
    }
    prof {
      id
      profileName
      profileName
      addFarm
      addAdmin
      addConfig
      addIrrReq
      addSector
      addParcel
      addFertPlan
      addFertControl
      addFertRequest
      viewFarm
      viewAdmin
      viewConfig
      viewSector
      viewParcel
      viewProgram
      viewIrrReq
      viewFertPlan
      viewFertControl
      viewFertRequest
      viewDashboard
      submitProgram
      submitCloudImport
      addSolidApp
      viewSolidApp
      viewStocks
      addStocks
      viewCloudImport
      accountId {
        id
        accountName
      }
    }
  }
}

      `,
        },
        {
          headers: {
            Authorization: 'JWT ' + inputVar.tokens.tokenIM,
          },
        }
      )
      .then((res) => {
        response = res;
      })
      .catch((error) => {
        response = error;
      });

    var result = {
      type: null,
      response: null,
      query: 'getImuserProfile',
    };
    result.type = 'v';
    if (response.data) {
      if (response.data.errors && response.data.errors.length > 0) {
        result.response = response.data.errors[0].message;
        result.type = 'e';
      } else {
        if (response.data.data.imuserProfile) {
          result.type = 's';
          var imuser = {
            imuserId: response.data.data.imuserProfile.imuserId,
            imuserName: response.data.data.imuserProfile.imuserName,
            imuserBg: response.data.data.imuserProfile.imuserBg,
          };
          commit('setImuser', imuser);
          commit('setAccounts', response.data.data.imuserProfile.accts);
          commit('setUserProfile', response.data.data.imuserProfile.prof);

          result.response = response.data.data.imuserProfile;
        }
      }
    } else {
      result.type = 'e';
      result.response = response;
    }
    return result;
  },
  async getContacts({ commit }, inputVar) {
    var response = '';
    await axios
      .post(
        myHttp,
        {
          query: `query contacts {
            contacts {
              id
              name
              address1
              address2
              city
              state
              postcode
              country
              mobilePhNo
              workPhNo
              emailAddr
              taxNumber
              typeId{id name isUser contactType}
              userId{id name}
              optimalLoad
            }
          }
          `,
        },
        {
          headers: {
            Authorization: 'JWT ' + inputVar.tokens.tokenIM,
          },
        }
      )
      .then((res) => {
        response = res;
      })
      .catch((error) => {
        response = error;
      });

    var result = {
      type: null,
      response: null,
      query: 'getContacts',
    };
    result.type = 'v';
    if (response.data) {
      if (response.data.errors && response.data.errors.length > 0) {
        result.response = response.data.errors[0].message;
        result.type = 'e';
      } else {
        if (response.data.data.contacts) {
          result.type = 's';
          commit('setContacts', response.data.data.contacts);
          result.response = response.data.data.contacts;
        }
      }
    } else {
      result.type = 'e';
      result.response = response;
    }
    return result;
  },
  async createContacts({ commit }, inputVar) {
    var queryUp = '';
    var response = '';
    var queryVar = [];
    inputVar.contacts.forEach(async function (item) {
      if (item.country === null) item.country = '';
      if (item.city === null) item.city = '';
      if (item.state === null) item.state = '';
      if (item.postcode === null) item.postcode = '';
      if (item.address1 === null) item.address1 = '';
      if (item.address2 === null) item.address2 = '';
      if (item.mobilePhNo === null) item.mobilePhNo = '';
      if (item.workPhNo === null) item.workPhNo = '';
      if (item.emailAddr === null) item.emailAddr = '';
      if (item.taxNumber === null) item.taxNumber = '';
      if (item.userId.id === null) item.userId.id = -1;
      if (item.optimalLoad === null) item.optimalLoad = -1;

      queryVar.push(
        `{ name: "` +
          item.name +
          `", typeId: ` +
          item.typeId.id +
          `, address1:" ` +
          item.address1 +
          ` "
            , address2:" ` +
          item.address2 +
          `", city:" ` +
          item.city +
          ` ", state:"` +
          item.state +
          `", postcode:"` +
          item.postcode +
          `", country:"` +
          item.country +
          `",workPhNo:"` +
          item.workPhNo +
          `",
              mobilePhNo:"` +
          item.mobilePhNo +
          `", emailAddr:"` +
          item.emailAddr +
          `", taxNumber:"` +
          item.taxNumber +
          `", userId:` +
          item.userId.id +
          `, optimalLoad:` +
          item.optimalLoad +
          `}
        `
      );
      if (item.userId.id === -1) item.userId.id = null;
      if (item.optimalLoad === -1) item.optimalLoad = null;
    });

    if (queryVar.length > 0) {
      queryUp =
        `mutation createContacts {
          createContacts(contacts:[` +
        queryVar +
        `]) {
            contactOut {
              id
               name
              city
              country
              postcode
              state
              workPhNo
              mobilePhNo
              emailAddr
              userId{id name}
              typeId{id
              name}
            }
          }
        }
        `;

      await axios
        .post(
          myHttp,
          {
            query: queryUp,
          },
          {
            headers: {
              Authorization: 'JWT ' + inputVar.tokens.tokenIM,
            },
          }
        )
        .then((res) => {
          response = res;
        })
        .catch((error) => {
          response = error;
        });
    }

    var result = {
      type: null,
      response: null,
      query: 'createContacts',
    };
    result.type = 'v';
    if (response.data) {
      if (response.data.errors && response.data.errors.length > 0) {
        result.response = response.data.errors[0].message;
        result.type = 'e';
      } else {
        if (response.data.data.createContacts) {
          result.type = 's';

          result.response = response.data.data.createContacts;
        }
      }
    } else {
      result.type = 'e';
      result.response = response;
    }
    return result;
  },
  async updateContacts({ commit }, inputVar) {
    var queryUp = '';
    var response = '';
    var queryVar = [];

    inputVar.contacts.forEach(async function (item) {
      if (item.country === null) item.country = '';
      if (item.city === null) item.city = '';
      if (item.state === null) item.state = '';
      if (item.postcode === null) item.postcode = '';
      if (item.address1 === null) item.address1 = '';
      if (item.address2 === null) item.address2 = '';
      if (item.mobilePhNo === null) item.mobilePhNo = '';
      if (item.workPhNo === null) item.workPhNo = '';
      if (item.emailAddr === null) item.emailAddr = '';
      if (item.taxNumber === null) item.taxNumber = '';
      if (item.userId.id === null) item.userId.id = -1;
      if (item.optimalLoad === null) item.optimalLoad = -1;
      queryVar.push(
        `{ contactId: ` +
          item.id +
          ` ,name: "` +
          item.name +
          `", typeId: ` +
          item.typeId.id +
          `, address1:" ` +
          item.address1 +
          ` "
          , address2:" ` +
          item.address2 +
          `", city:" ` +
          item.city +
          ` ", state:"` +
          item.state +
          `", postcode:"` +
          item.postcode +
          `", country:"` +
          item.country +
          `",workPhNo:"` +
          item.workPhNo +
          `",
            mobilePhNo:"` +
          item.mobilePhNo +
          `", emailAddr:"` +
          item.emailAddr +
          `", taxNumber:"` +
          item.taxNumber +
          `", userId:` +
          item.userId.id +
          `, optimalLoad:` +
          item.optimalLoad +
          `}
        `
      );
      if (item.userId.id === -1) item.userId.id = null;
      if (item.optimalLoad === -1) item.optimalLoad = null;
    });

    if (queryVar.length > 0) {
      queryUp =
        `mutation upd_contact {
      updContacts(contacts: [` +
        queryVar +
        `]) {
        contactOut {
          id
           name
          city
          country
          postcode
          state
          workPhNo
          mobilePhNo
          emailAddr
          taxNumber
          userId{id name}
          typeId{id
          name}
        }
      }}
     `;
      await axios
        .post(
          myHttp,
          {
            query: queryUp,
          },
          {
            headers: {
              Authorization: 'JWT ' + inputVar.tokens.tokenIM,
            },
          }
        )
        .then((res) => {
          response = res;
        })
        .catch((error) => {
          response = error;
        });
    }

    var result = {
      type: null,
      response: null,
      query: 'updateContacts',
    };
    result.type = 'v';
    if (response.data) {
      if (response.data.errors && response.data.errors.length > 0) {
        result.response = response.data.errors[0].message;
        result.type = 'e';
      } else {
        if (response.data.data.updContacts) {
          result.type = 's';

          result.response = response.data.data.updContacts;
        }
      }
    } else {
      result.type = 'e';
      result.response = response;
    }
    return result;
  },
  async delContacts({ commit }, inputVar) {
    var queryUp = '';
    var response = '';
    var queryVar = [];
    inputVar.contacts.forEach(async function (item) {
      queryVar.push(item.id + `,`);
    });

    if (queryVar.length > 0) {
      queryUp =
        `mutation delContacts {
    delContacts(ids:[` +
        queryVar +
        `]) {
      response {
        id

      }
     }
   }
   `;
      await axios
        .post(
          myHttp,
          {
            query: queryUp,
          },
          {
            headers: {
              Authorization: 'JWT ' + inputVar.tokens.tokenIM,
            },
          }
        )
        .then((res) => {
          response = res;
        })
        .catch((error) => {
          response = error;
        });
    }

    var result = {
      type: null,
      response: null,
      query: 'delContacts',
    };
    result.type = 'v';
    if (response.data) {
      if (response.data.errors && response.data.errors.length > 0) {
        result.response = response.data.errors[0].message;
        result.type = 'e';
      } else {
        if (response.data.data.delContacts) {
          result.type = 's';

          result.response = response.data.data.delContacts;
        }
      }
    } else {
      result.type = 'e';
      result.response = response;
    }
    return result;
  },
  async getContainerAssoc({ commit }, inputVar) {
    var response = '';
    var queryUp =
      `query containerAssoc {
    containerAssoc(farmId: ` +
      inputVar.farmId +
      `) {
        fcontOutId  
        fertContainerId
        fertContainerName
        fertOutId
        fertOutName
        externalId
        fertRefId
        fertRefName
        startDt
        endDt
        header{
          id
          headerNumber
          headerName
        }   
  }
}
`;

    await axios
      .post(
        myHttp,
        {
          query: queryUp,
        },
        {
          headers: {
            Authorization: 'JWT ' + inputVar.tokens.tokenIM,
          },
        }
      )
      .then((res) => {
        response = res;
      })
      .catch((error) => {
        response = error;
      });

    var result = {
      type: null,
      response: null,
      query: 'getContainerAssoc',
    };
    result.type = 'v';
    if (response.data) {
      if (response.data.errors && response.data.errors.length > 0) {
        result.response = response.data.errors[0].message;
        result.type = 'e';
      } else {
        if (response.data.data.containerAssoc) {
          result.type = 's';
          commit('setContainerAssoc', response.data.data.containerAssoc);
          result.response = response.data.data.containerAssoc;
        }
      }
    } else {
      result.type = 'e';
      result.response = response;
    }
    return result;
  },

  async updateFertContaineAssoc({ commit }, inputVar) {
    var queryUp = '';
    var response = '';
    if (!inputVar.fertContainerAssoc.endDt) {
      inputVar.fertContainerAssoc.endDt = '2999-01-01';
    }
    if (!inputVar.fertContainerAssoc.header) {
      inputVar.fertContainerAssoc.header = -1;
    }
    queryUp = `
    mutation upd_fert_cont_out {
      updFertContOuts(
        fcontOutId: ${inputVar.fertContainerAssoc.fcontOutId}, 
        fertContainerId: ${inputVar.fertContainerAssoc.fertContainerId}, 
        fertOutId: ${inputVar.fertContainerAssoc.fertOutId}, 
        fertRefId: ${inputVar.fertContainerAssoc.fertRefId}, 
        startDt: "${inputVar.fertContainerAssoc.startDt}", 
        endDt: "${inputVar.fertContainerAssoc.endDt}"
        headerId: ${inputVar.fertContainerAssoc.header}
      ) {
        fcoOut
      }
    }
  `;

    await axios
      .post(
        myHttp,
        {
          query: queryUp,
        },
        {
          headers: {
            Authorization: 'JWT ' + inputVar.tokens.tokenIM,
          },
        }
      )
      .then((res) => {
        response = res;
      })
      .catch((error) => {
        response = error;
      });

    var result = {
      type: null,
      response: null,
      query: 'updateFertContaineAssoc',
    };
    result.type = 'v';
    if (response.data) {
      if (response.data.errors && response.data.errors.length > 0) {
        result.response = response.data.errors[0].message;
        result.type = 'e';
      } else {
        if (response.data.data.updFertContOuts) {
          result.type = 's';

          result.response = response.data.data.updFertContOuts;
        }
      }
    } else {
      result.type = 'e';
      result.response = response;
    }
    return result;
  },

  async getFertOutput({ commit }, inputVar) {
    var response = '';
    var queryUp =
      `query fertOutput {
    fertOutputs(farmId: ` +
      inputVar.farmId +
      `) {
      fertOutId
    name
    externalId
    farmId
    container
    headerId{
      id
      headerName
      headerNumber
    }

  }
}
`;

    await axios
      .post(
        myHttp,
        {
          query: queryUp,
        },
        {
          headers: {
            Authorization: 'JWT ' + inputVar.tokens.tokenIM,
          },
        }
      )
      .then((res) => {
        response = res;
      })
      .catch((error) => {
        response = error;
      });

    var result = {
      type: null,
      response: null,
      query: 'getFertOutput',
    };
    result.type = 'v';
    if (response.data) {
      if (response.data.errors && response.data.errors.length > 0) {
        result.response = response.data.errors[0].message;
        result.type = 'e';
      } else {
        if (response.data.data.fertOutputs) {
          result.type = 's';
          commit('setFertOutputs', response.data.data.fertOutputs);
          result.response = response.data.data.fertOutputs;
        }
      }
    } else {
      result.type = 'e';
      result.response = response;
    }
    return result;
  },
  async createFertOutput({ commit }, inputVar) {
    var queryUp = '';
    var response = '';
    var queryVar = [];

    if (inputVar.fertOutput.externalId === null) inputVar.fertOutput.externalId = '';
    queryVar.push(`
      {
        name: "${inputVar.fertOutput.name}",
        externalId: "${inputVar.fertOutput.externalId}",
        farmId: ${inputVar.fertOutput.farmId},
        headerId: ${inputVar.fertOutput.headerId}
      }
    `);

    if (queryVar.length > 0) {
      queryUp = queryUp = `
      mutation createFertOutput {
        createFertOuts(fouts: [${queryVar.join(',')}]) {
          foutsOut {
            id
            name
            externalId
            farmId {
              id
            }
            headerId{
              id
              headerName
              headerNumber
            }
          }
        }
      }`;
      await axios
        .post(
          myHttp,
          {
            query: queryUp,
          },
          {
            headers: {
              Authorization: 'JWT ' + inputVar.tokens.tokenIM,
            },
          }
        )
        .then((res) => {
          response = res;
        })
        .catch((error) => {
          response = error;
        });
    }

    var result = {
      type: null,
      response: null,
      query: 'createFertOutput',
    };
    result.type = 'v';
    if (response.data) {
      if (response.data.errors && response.data.errors.length > 0) {
        result.response = response.data.errors[0].message;
        result.type = 'e';
      } else {
        if (response.data.data.createFertOuts) {
          result.type = 's';

          result.response = response.data.data.createFertOuts;
        }
      }
    } else {
      result.type = 'e';
      result.response = response;
    }
    return result;
  },
  async updateFertOutput({ commit }, inputVar) {
    var queryUp = '';
    var response = '';
    var queryVar = [];
    inputVar.fertOutputs.forEach(async function (item) {
      if (item.externalId === null) item.externalId = '';
      queryVar.push(
        `{ fertOutId:` +
          item.fertOutId +
          `, name: "` +
          item.name +
          `" ,externalId: "` +
          item.externalId +
          `", farmId: ` +
          item.farmId +
          `}
    `
      );
    });

    if (queryVar.length > 0) {
      queryUp =
        `mutation updateFertOutput {
      updFertOuts(fouts:[` +
        queryVar +
        `]) {
        foutsOut {
          id
          name
          externalId


          farmId {
            id
            name
          }
        }
      }
    }
    `;

      await axios
        .post(
          myHttp,
          {
            query: queryUp,
          },
          {
            headers: {
              Authorization: 'JWT ' + inputVar.tokens.tokenIM,
            },
          }
        )
        .then((res) => {
          response = res;
        })
        .catch((error) => {
          response = error;
        });
    }

    var result = {
      type: null,
      response: null,
      query: 'updateFertOutput',
    };
    result.type = 'v';
    if (response.data) {
      if (response.data.errors && response.data.errors.length > 0) {
        result.response = response.data.errors[0].message;
        result.type = 'e';
      } else {
        if (response.data.data.updFertOuts) {
          result.type = 's';

          result.response = response.data.data.updFertOuts;
        }
      }
    } else {
      result.type = 'e';
      result.response = response;
    }
    return result;
  },
  async updateFertOutputCont({ commit }, inputVar) {
    var queryUp = '';
    var response = '';
    var queryVar = [];
    inputVar.fertOutputs.forEach(async function (item) {
      if (item.fcontOutId) {
        if (item.endDt) {
          queryVar.push(
            `{ fcontOutId:` +
              item.fcontOutId +
              `,
          fertContainerId:` +
              item.fertContainerId +
              `, fertOutId: ` +
              item.fertOutId +
              `, fertRefId: ` +
              item.fertRefId +
              `, startDt: "` +
              item.startDt +
              `" ,endDt: "` +
              item.endDt +
              `"}`
          );
        } else {
          queryVar.push(
            `{ fcontOutId:` +
              item.fcontOutId +
              `,fertContainerId:` +
              item.fertContainerId +
              `, fertOutId: ` +
              item.fertOutId +
              `, fertRefId: ` +
              item.fertRefId +
              `, startDt: "` +
              item.startDt +
              `"}`
          );
        }
      } else {
        if (item.endDt) {
          queryVar.push(
            `{ fertContainerId:` +
              item.fertContainerId +
              `, fertOutId: ` +
              item.fertOutId +
              `, fertRefId: ` +
              item.fertRefId +
              `, startDt: "` +
              item.startDt +
              `" ,endDt: "` +
              item.endDt +
              `"}`
          );
        } else {
          queryVar.push(
            `{ fertContainerId:` +
              item.fertContainerId +
              `, fertOutId: ` +
              item.fertOutId +
              `, fertRefId: ` +
              item.fertRefId +
              `, startDt: "` +
              item.startDt +
              `"}`
          );
        }
      }
    });

    if (queryVar.length > 0) {
      queryUp =
        `mutation updFertContOuts {
      updFertContOuts(fcoIn:[` +
        queryVar +
        `]) {
        fcoOut
      }
    }
    `;

      await axios
        .post(
          myHttp,
          {
            query: queryUp,
          },
          {
            headers: {
              Authorization: 'JWT ' + inputVar.tokens.tokenIM,
            },
          }
        )
        .then((res) => {
          response = res;
        })
        .catch((error) => {
          response = error;
        });
    }

    var result = {
      type: null,
      response: null,
      query: 'updFertContOuts',
    };
    result.type = 'v';
    if (response.data) {
      if (response.data.errors && response.data.errors.length > 0) {
        result.response = response.data.errors[0].message;
        result.type = 'e';
      } else {
        if (response.data.data.updFertContOuts) {
          result.type = 's';

          result.response = response.data.data.updFertContOuts;
        }
      }
    } else {
      result.type = 'e';
      result.response = response;
    }
    return result;
  },
  async deleteFertOutput({ commit }, inputVar) {
    var queryUp = '';
    var response = '';
    var queryVar = [];
    inputVar.fertOutputs.forEach(async function (item) {
      queryVar.push(item.fertOutId + `,`);
    });

    if (queryVar.length > 0) {
      queryUp =
        `mutation delFertOutputs {
        delFertOuts(ids:[` +
        queryVar +
        `]) {
         response {
           id
           name
         }
        }
      }
      `;

      await axios
        .post(
          myHttp,
          {
            query: queryUp,
          },
          {
            headers: {
              Authorization: 'JWT ' + inputVar.tokens.tokenIM,
            },
          }
        )
        .then((res) => {
          response = res;
        })
        .catch((error) => {
          response = error;
        });
    }

    var result = {
      type: null,
      response: null,
      query: 'deleteFertOutput',
    };
    result.type = 'v';
    if (response.data) {
      if (response.data.errors && response.data.errors.length > 0) {
        result.response = response.data.errors[0].message;
        result.type = 'e';
      } else {
        if (response.data.data.delFertOuts) {
          result.type = 's';

          result.response = response.data.data.delFertOuts;
        }
      }
    } else {
      result.type = 'e';
      result.response = response;
    }
    return result;
  },
  async deleteFertMassivePlan({ commit }, inputVar) {
    var response = '';

    var queryUp = `mutation deleteFertPlanMassive {
                    delFertPlanMassive(id: ${inputVar.id}) {
                      deleted
                    }
                  }`;

    await axios
      .post(
        myHttp,
        {
          query: queryUp,
        },
        {
          headers: {
            Authorization: 'JWT ' + inputVar.tokens.tokenIM,
          },
        }
      )
      .then((res) => {
        response = res;
      })
      .catch((error) => {
        response = error;
      });

    var result = {
      type: null,
      response: null,
      query: 'delFertPlanMassive',
    };
    result.type = 'v';
    if (response.data) {
      if (response.data.errors && response.data.errors.length > 0) {
        result.response = response.data.errors[0].message;
        result.type = 'e';
      } else {
        if (response.data.data.delFertPlanMassive) {
          result.type = 's';

          result.response = response.data.data.delFertPlanMassive;
        }
      }
    } else {
      result.type = 'e';
      result.response = response;
    }
    return result;
  },
  async getFertControls({ commit }, inputVar) {
    var response = '';

    var queryUp =
      `query fertControls {
      fertControls(farmId: ` +
      inputVar.farmId +
      `) {
            fcontrolOuts {
              fcId
              tTypeId
              tTypeName
              tTypeInOut
              tTypeUserInput
              fOuts {
                fOutId
                fOutName
              }
              fRefId
              fRefName
              effectiveDt
              qty
              qtyDimension
              qtyUnit
              balance
              farmId
            }
            fconts {
              fContId
              name
              capacity

            }
            foutCombos {
              fContainerId
              fContainerName
              fOutId
              fOutName
              fFertRefId
              fFertRefName
              fStartDt
              fEndDt
              header{
                id
                headerName
                headerNumber
              }

            }
          }
        }
  `;

    await axios
      .post(
        myHttp,
        {
          query: queryUp,
        },
        {
          headers: {
            Authorization: 'JWT ' + inputVar.tokens.tokenIM,
          },
        }
      )
      .then((res) => {
        response = res;
      })
      .catch((error) => {
        response = error;
      });

    var result = {
      type: null,
      response: null,
      query: 'getFertControls',
    };
    result.type = 'v';
    if (response.data) {
      if (response.data.errors && response.data.errors.length > 0) {
        result.response = response.data.errors[0].message;
        result.type = 'e';
      } else {
        if (response.data.data.fertControls) {
          result.type = 's';
          commit('setFertControls', response.data.data.fertControls);
          result.response = response.data.data.fertControls;
        }
      }
    } else {
      result.type = 'e';
      result.response = response;
    }
    return result;
  },
  async getFertStockOnIrrReqDate({ commit }, inputVar) {
    var response = '';

    var queryUp = `query fert_stock_on_irr_req_date {
        getFertStockOnIrrReqDate(farmId: ${inputVar.farmId}, date: "${inputVar.date}" ) {
              fcId
              fRefId
              fRefName
              effectiveDt
              qty
              balance
              farmId
          }
        }
  `;

    await axios
      .post(
        myHttp,
        {
          query: queryUp,
        },
        {
          headers: {
            Authorization: 'JWT ' + inputVar.tokens.tokenIM,
          },
        }
      )
      .then((res) => {
        response = res;
      })
      .catch((error) => {
        response = error;
      });

    var result = {
      type: null,
      response: null,
      query: 'getFertStockOnIrrReqDate',
    };
    result.type = 'v';
    if (response.data) {
      if (response.data.errors && response.data.errors.length > 0) {
        result.response = response.data.errors[0].message;
        result.type = 'e';
      } else {
        if (response.data.data.getFertStockOnIrrReqDate) {
          result.type = 's';
          result.response = response.data.data.getFertStockOnIrrReqDate;
        }
      }
    } else {
      result.type = 'e';
      result.response = response;
    }
    return result;
  },
  async createFertControls({ commit }, inputVar) {
    var queryUp = '';
    var response = '';
    var queryVar = [];

    inputVar.fertControls.forEach(async function (item) {
      queryVar.push(
        `{ transTypeId:` +
          item.tTypeId +
          `, fertOutId:[` +
          item.fOutId +
          `],effectiveDt:"` +
          item.effectiveDt +
          `",
      qty:"` +
          item.qty +
          `",qtyDimension:"` +
          item.qtyDimension +
          `"}
      `
      );
    });

    if (queryVar.length > 0) {
      queryUp =
        `mutation createFertControls {
        createFertControls(fcontrols:[` +
        queryVar +
        `]) {
          fcontrolsOut {
            id
            transTypeId {
              id
            }
            fertOutId {
              id
            }
            fertRefId {
              id
            }
            effectiveDt
            qty,
            qtyDimension,
            createdDt
            createdBy {
              id
            }
            lastUpdDt
            lastUpdBy {
              id
            }
          }
        }
      }
      `;

      await axios
        .post(
          myHttp,
          {
            query: queryUp,
          },
          {
            headers: {
              Authorization: 'JWT ' + inputVar.tokens.tokenIM,
            },
          }
        )
        .then((res) => {
          response = res;
        })
        .catch((error) => {
          response = error;
        });
    }

    var result = {
      type: null,
      response: null,
      query: 'createFertControls',
    };
    result.type = 'v';
    if (response.data) {
      if (response.data.errors && response.data.errors.length > 0) {
        result.response = response.data.errors[0].message;
        result.type = 'e';
      } else {
        if (response.data.data.createFertControls) {
          result.type = 's';

          result.response = response.data.data.createFertControls;
        }
      }
    } else {
      result.type = 'e';
      result.response = response;
    }
    return result;
  },
  async updateFertControls({ commit }, inputVar) {
    var queryUp = '';
    var response = '';
    var queryVar = [];

    inputVar.fertControls.forEach(async function (item) {
      queryVar.push(
        `{ fertControlId:` +
          item.fcId +
          `transTypeId:` +
          item.tTypeId +
          `, fertOutId:[` +
          item.fOutId +
          `],effectiveDt:"` +
          item.effectiveDt +
          `",
            qty:"` +
          item.qty +
          `",qtyDimension:"` +
          item.qtyDimension +
          `"}
            `
      );
    });

    if (queryVar.length > 0) {
      queryUp =
        `mutation updFertControls {
        updFertControls(fcontrols:[` +
        queryVar +
        `]) {
          fcontrolsOut {
            id
            transTypeId {
              id
            }
            fertOutId {
              id
            }
            fertRefId {
              id
            }
            effectiveDt
            qty,
            qtyDimension
            createdDt
            createdBy {
              id
            }
            lastUpdDt
            lastUpdBy {
              id
            }
          }
        }
      }

      `;

      await axios
        .post(
          myHttp,
          {
            query: queryUp,
          },
          {
            headers: {
              Authorization: 'JWT ' + inputVar.tokens.tokenIM,
            },
          }
        )
        .then((res) => {
          response = res;
        })
        .catch((error) => {
          response = error;
        });
    }

    var result = {
      type: null,
      response: null,
      query: 'updateFertControls',
    };
    result.type = 'v';
    if (response.data) {
      if (response.data.errors && response.data.errors.length > 0) {
        result.response = response.data.errors[0].message;
        result.type = 'e';
      } else {
        if (response.data.data.updFertControls) {
          result.type = 's';

          result.response = response.data.data.updFertControls;
        }
      }
    } else {
      result.type = 'e';
      result.response = response;
    }
    return result;
  },

  async deleteFertControls({ commit }, inputVar) {
    var queryUp = '';
    var response = '';
    var queryVar = [];
    inputVar.fertControls.forEach(async function (item) {
      queryVar.push(item.fcId + `,`);
    });

    if (queryVar.length > 0) {
      queryUp =
        `mutation delFertControls {
          delFertControls(ids:[` +
        queryVar +
        `]) {
           response {
             id
           }
          }
        }
        `;
      await axios
        .post(
          myHttp,
          {
            query: queryUp,
          },
          {
            headers: {
              Authorization: 'JWT ' + inputVar.tokens.tokenIM,
            },
          }
        )
        .then((res) => {
          response = res;
        })
        .catch((error) => {
          response = error;
        });
    }

    var result = {
      type: null,
      response: null,
      query: 'deleteFertControls',
    };
    result.type = 'v';
    if (response.data) {
      if (response.data.errors && response.data.errors.length > 0) {
        result.response = response.data.errors[0].message;
        result.type = 'e';
      } else {
        if (response.data.data.delFertControls) {
          result.type = 's';

          result.response = response.data.data.delFertControls;
        }
      }
    } else {
      result.type = 'e';
      result.response = response;
    }
    return result;
  },
  /* ---------------------------------------------- */
  async calcIrrReq({ commit }, inputVar) {
    var response = '';
    var irrId = '';
    if (inputVar.calc.id) {
      irrId = `irrreqid:` + inputVar.calc.id;
    }

    if (inputVar.calc.shiftGap === null) {
      inputVar.calc.shiftGap = 0;
    }
    if (inputVar.calc.shiftGap.length < 5) {
      inputVar.calc.shiftGap = 0;
    }
    var shiftGap = 0;
    if (inputVar.calc.shiftGap !== 0) {
      var hh = parseInt(inputVar.calc.shiftGap.substring(0, 2));
      var mm = parseInt(inputVar.calc.shiftGap.substring(3, 5));
      shiftGap = parseInt(hh) * 60 + parseInt(mm);
    }

    var queryUp =
      `mutation calcIrrReq {
        irrRequest(` +
      irrId +
      `, toSave: ` +
      inputVar.save +
      `, shiftGap: ` +
      shiftGap +
      `, farmid: ` +
      inputVar.calc.farmId.id +
      ` startdt:"` +
      inputVar.calc.startDt +
      `", enddt:"` +
      inputVar.calc.endDt +
      `", volunit:"` +
      inputVar.calc.volunit +
      `" irrSgs:[` +
      inputVar.calc.irrSgs +
      `], irrDaily: [` +
      inputVar.calc.irrDaily +
      `]
           ) {

            water {
              day
              sectorGroupId
              startTime
              endTime
              volArea
            }
            fert {
              sgName
              fertName
              wvaReq
              fvaReal
              fvaPlan
              wvaPlan
            }
            daily {
              day
              manual
              ferts
            }
          }
        }
    `;

    await axios
      .post(
        myHttp,
        {
          query: queryUp,
        },
        {
          headers: {
            Authorization: 'JWT ' + inputVar.tokens.tokenIM,
          },
        }
      )
      .then((res) => {
        response = res;
      })
      .catch((error) => {
        response = error;
      });

    var result = {
      type: null,
      response: null,
      query: 'calcIrrReq',
    };
    result.type = 'v';
    if (response.data) {
      if (response.data.errors && response.data.errors.length > 0) {
        result.response = response.data.errors[0].message;
        result.type = 'e';
      } else {
        if (response.data.data.irrRequest) {
          result.type = 's';

          result.response = response.data.data.irrRequest;
        }
      }
    } else {
      result.type = 'e';
      result.response = response;
    }
    return result;
  },
  async deleteIrrReq({ commit }, inputVar) {
    var queryUp = '';
    var response = '';
    var queryVar = [];
    inputVar.irrReq.forEach(async function (item) {
      queryVar.push(item.id + `,`);
    });

    if (queryVar.length > 0) {
      queryUp =
        `mutation delirrreqs {
      delIrrReqs(ids:[` +
        queryVar +
        `]) {
       response
      }
    }
    `;
      await axios
        .post(
          myHttp,
          {
            query: queryUp,
          },
          {
            headers: {
              Authorization: 'JWT ' + inputVar.tokens.tokenIM,
            },
          }
        )
        .then((res) => {
          response = res;
        })
        .catch((error) => {
          response = error;
        });
    }

    var result = {
      type: null,
      response: null,
      query: 'delIrrReqs',
    };
    result.type = 'v';
    if (response.data) {
      if (response.data.errors && response.data.errors.length > 0) {
        result.response = response.data.errors[0].message;
        result.type = 'e';
      } else {
        if (response.data.data.delIrrReqs) {
          result.type = 's';

          result.response = response.data.data.delIrrReqs;
        }
      }
    } else {
      result.type = 'e';
      result.response = response;
    }
    return result;
  },
  async getIrrReqM({ commit }, inputVar) {
    var response = '';

    var queryUp = `



`;

    await axios
      .post(
        myHttp,
        {
          query: queryUp,
        },
        {
          headers: {
            Authorization: 'JWT ' + inputVar.tokens.tokenIM,
          },
        }
      )
      .then((res) => {
        response = res;
      })
      .catch((error) => {
        response = error;
      });

    var result = {
      type: null,
      response: null,
      query: 'calcIrrReq',
    };
    result.type = 'v';
    if (response.data) {
      if (response.data.errors && response.data.errors.length > 0) {
        result.response = response.data.errors[0].message;
      } else {
        if (response.data.data.getIrrReqs) {
          for (var a = 0; a < response.data.data.getIrrReqs.length; a++) {
            var queryUp1 =
              `mutation integrate_cycle {
    integrateCycle(token:"` +
              inputVar.tokens.tokenProgres +
              `", irrReqId: ` +
              response.data.data.getIrrReqs[a].id +
              `) {
      response {
        parcelId {
          name
        }
        date
        fertRefId {
          name
        }
        fertVol
        waterVol
      }
    }
  }
`;

            var response1 = null;

            await axios
              .post(
                myHttp,
                {
                  query: queryUp1,
                },
                {
                  headers: {
                    Authorization: 'JWT ' + inputVar.tokens.tokenIM,
                  },
                }
              )
              .then((res) => {
                response1 = res;
              })
              .catch((error) => {
                response1 = error;
              });

            if (response1.data) {
              if (response1.data.errors && response1.data.errors.length > 0) {
              } else {
                if (response1.data.data.integrateCycle) {
                }
              }
            } else {
            }
          }
        }
      }
    } else {
      result.response = response;
    }
    return result;
  },
  /* fim martelada */
  async getIrrReqByFarm({ commit }, inputVar) {
    var response = '';
    var parms = '';
    if (inputVar.farmId && inputVar.excludePendig !== null) {
      parms = `(farmId: ` + inputVar.farmId + ` , excludePending: ` + inputVar.excludePendig + `) `;
    }
    if (inputVar.farmId && inputVar.excludePendig === null) {
      parms = `(farmId: ` + inputVar.farmId + `) `;
    }
    if (!inputVar.farmId && inputVar.excludePendig !== null) {
      parms = `(excludePending: ` + inputVar.excludePendig + `) `;
    }

    var queryUp =
      `  query getIrrReqs {
    getIrrReqs ` +
      parms +
      `{
          id
          farmId {
            id
            name

          }
          startDt
          endDt
          progrSentDt
          cloudImportedDt
          sectorGroups
          shiftGap
          loadedDt
          headerId{
            id
            headerName
            headerNumber
        }

        }
      }
`;

    await axios
      .post(
        myHttp,
        {
          query: queryUp,
        },
        {
          headers: {
            Authorization: 'JWT ' + inputVar.tokens.tokenIM,
          },
        }
      )
      .then((res) => {
        response = res;
      })
      .catch((error) => {
        response = error;
      });

    var result = {
      type: null,
      response: null,
      query: 'calcIrrReq',
    };
    result.type = 'v';
    if (response.data) {
      if (response.data.errors && response.data.errors.length > 0) {
        result.response = response.data.errors[0].message;
        result.type = 'e';
      } else {
        if (response.data.data.getIrrReqs) {
          result.type = 's';

          for (var a = 0; a < response.data.data.getIrrReqs.length; a++) {
            if (response.data.data.getIrrReqs[a].shiftGap) {
              if (response.data.data.getIrrReqs[a].shiftGap === 0) {
                response.data.data.getIrrReqs[a].shiftGap = null;
              } else {
                var num = response.data.data.getIrrReqs[a].shiftGap;
                var hours = num / 60;
                var rhours = Math.floor(hours);
                if (rhours < 10) {
                  rhours = '0' + rhours;
                }
                var minutes = (hours - rhours) * 60;
                var rminutes = Math.round(minutes);
                if (rminutes < 10) {
                  rminutes = '0' + rminutes;
                }
                response.data.data.getIrrReqs[a].shiftGap = rhours + ':' + rminutes;
              }
            }
          }
          commit('setIrrReq', response.data.data.getIrrReqs);
          result.response = response.data.data.getIrrReqs;
        }
      }
    } else {
      result.type = 'e';
      result.response = response;
    }

    return result;
  },
  async importResults1({ commit }, inputVar) {
    var response = [];
    var importResults = [];

    var queryUp = '';

    if (inputVar.results.length > 0) {
      for (var x = 0; x < inputVar.results.length; x++) {
        queryUp =
          `mutation integrate_cycle {
        integrateCycle(token:"` +
          inputVar.tokens.tokenProgres +
          `", irrReqId: ` +
          inputVar.results[x].id +
          `) {
          response {
            parcelId {
              name
            }
            date
            fertRefId {
              name
            }
            fertVol
            waterVol
          }
        }
      }
    `;

        await axios
          .post(
            myHttp,
            {
              query: queryUp,
            },
            {
              headers: {
                Authorization: 'JWT ' + inputVar.tokens.tokenIM,
              },
            }
          )
          .then((res) => {
            response = res;
          })
          .catch((error) => {
            response = error;
          });
        importResults.push({ id: inputVar.results[x].id, res: response });
      }
    }
    commit('setImportResults', importResults);

    return importResults;
  },

  async importResults({ commit }, inputVar) {
    var response = '';
    /*  var queryUp = `mutation integrate_cycle {
    integrateCycle(token:"` + inputVar.tokens.tokenProgres + `", irrReqId: ` +
    inputVar.id + `) {
      response
    }
  }` */

    var queryUp =
      `mutation import_progress {
  importProgres(token:"` +
      inputVar.tokens.tokenProgres +
      `", accountId:` +
      inputVar.accountId +
      `, irrReqId:` +
      inputVar.id +
      `) {
    msg
  } 
}`;

    await axios
      .post(
        myHttp,
        {
          query: queryUp,
        },
        {
          headers: {
            Authorization: 'JWT ' + inputVar.tokens.tokenIM,
          },
        }
      )
      .then((res) => {
        response = res;
      })
      .catch((error) => {
        response = error;
      });

    var result = {
      type: null,
      response: null,
      query: 'importResults',
    };

    result.type = 'v';
    if (response.data) {
      if (response.data.errors && response.data.errors.length > 0) {
        result.response = response.data.errors[0].message;
        result.type = 'e';
      } else {
        if (response.data.data.importProgres) {
          result.type = 's';
          commit('setIntegrateCycle', response.data.data.importProgres);
          result.response = response.data.data.importProgres;
        }
      }
    } else {
      result.type = 'e';
      result.response = response;
    }
    return result;
  },
  async calcResults({ commit }, inputVar) {
    var response = '';
    /*  var queryUp = `mutation integrate_cycle {
    integrateCycle(token:"` + inputVar.tokens.tokenProgres + `", irrReqId: ` +
    inputVar.id + `) {
      response
    }
  }` */

    var queryUp =
      `mutation calc_cycle_vols {
  calcCycleVols(accountId:` +
      inputVar.accountId +
      `, irrReqId:` +
      inputVar.id +
      `) {
    msg
  } 
}`;

    await axios
      .post(
        myHttp,
        {
          query: queryUp,
        },
        {
          headers: {
            Authorization: 'JWT ' + inputVar.tokens.tokenIM,
          },
        }
      )
      .then((res) => {
        response = res;
      })
      .catch((error) => {
        response = error;
      });

    var result = {
      type: null,
      response: null,
      query: 'calcResults',
    };

    result.type = 'v';
    if (response.data) {
      if (response.data.errors && response.data.errors.length > 0) {
        result.response = response.data.errors[0].message;
        result.type = 'e';
      } else {
        if (response.data.data.calcCycleVols) {
          result.type = 's';
          commit('setIntegrateCycle', response.data.data.calcCycleVols);
          result.response = response.data.data.calcCycleVols;
        }
      }
    } else {
      result.type = 'e';
      result.response = response;
    }
    return result;
  },
  async createFarmMassivePlanFert({ commit }, inputVar) {
    var response = '';

    if (inputVar.farmIds.length > 0) {
      var queryUp = `mutation CreateFarmsMassivePlanFert {
        createFarmsMassivePlanFert(
          id: ${inputVar.id}, 
          farmIds: [${inputVar.farmIds}],
        ) {
        out {
						id
            farmId
            farmName
            sectorGroupId
            sectorGroupName
            massQty
            fertRefId
            checked
            fertPlanId
            percentage
          }
        }
      }`;

      await axios
        .post(
          myHttp,
          {
            query: queryUp,
          },
          {
            headers: {
              Authorization: 'JWT ' + inputVar.tokens.tokenIM,
            },
          }
        )
        .then((res) => {
          response = res;
        })
        .catch((error) => {
          response = error;
        });
    }

    var result = {
      type: null,
      response: null,
      query: 'createFarmsMassivePlanFert',
    };

    result.type = 'v';
    if (response.data) {
      if (response.data.errors && response.data.errors.length > 0) {
        result.response = response.data.errors[0].message;
        result.type = 'e';
      } else {
        if (response.data.data.createFarmsMassivePlanFert) {
          result.type = 's';
          result.response = response.data.data.createFarmsMassivePlanFert;
        }
      }
    } else {
      result.type = 'e';
      result.response = response;
    }
    return result;
  },
  async sendProgram({ commit }, inputVar) {
    var response = '';
    var warning = `warning{
                        message
                        farmName
                  }`;
    var outA4000 = `outA4000 {
                      id
                      deviceId
                      progtype
                      name
                      type
                      monday
                      tuesday
                      wednesday
                      thursday
                      friday
                      saturday
                      sunday
                      freqDays
                      activations
                      activationsFreq
                      start
                      sequencial
                      preIrrigation
                      postIrrigation
                      manualFactor
                      group
                      securityTime
                      subprograms {
                        programId
                        id
                        value
                        unit
                        fertUnit
                        phReference
                        sector1
                        sector2
                        sector3
                        sector4
                        sector5
                        sector6
                        sector7
                        sector8
                        sector9
                        sector10
                        fertilizer1
                        fertilizer2
                        fertilizer3
                        fertilizer4
                        fertilizer5
                        fertilizer6
                        fertilizer7
                        fertilizer8
                      }
                    }`;
    var outA4500 = `outA4500 {
                      id
                      name
                      monday
                      tuesday
                      wednesday
                      thursday
                      friday
                      saturday
                      sunday
                      activations
                      manualFactor
                      subprograms {
                        id
                        value
                        sector1
                        sector2
                        sector3
                        sector4
                        sector5
                        sector6
                        sector7
                        sector8
                        sector9
                        sector10
                      }
                    }`;
    var outA2500 = `outA2500 {
                        id
                        deviceId
                        name
                        type
                        daysFreq
                        unit
                        freqDays
                        preIrrigation
                        postIrrigation
                        sector1
                        sector2
                        sector3
                        sector4
                        start
                        value
                        fertilizer1
                        fertilizer2
                        fertilizer3
                        fertilizer4
                        monday
                        tuesday
                        wednesday
                        thursday
                        friday
                        saturday
                        sunday
                        activations
                        activationsFreq
                        priority
                    }`;

    var queryUp =
      `mutation create_program {
    createProgram(irrReqId:` +
      inputVar.id +
      `, token: "` +
      inputVar.tokens.tokenProgres +
      `") {
        ${warning}
        ${outA4500}
        ${outA4000}
        ${outA2500}
       }
     }


`;

    await axios
      .post(
        myHttp,
        {
          query: queryUp,
        },
        {
          headers: {
            Authorization: 'JWT ' + inputVar.tokens.tokenIM,
          },
        }
      )
      .then((res) => {
        response = res;
      })
      .catch((error) => {
        response = error;
      });

    var result = {
      type: null,
      response: null,
      query: 'sendProgram',
    };
    result.type = 'v';
    if (response.data) {
      if (response.data.errors && response.data.errors.length > 0) {
        result.response = response.data.errors[0].message;
        result.type = 'e';
      } else {
        if (response.data.data.createProgram) {
          result.type = 's';
          commit('setCreateProgram', response.data.data.createProgram);
          result.response = response.data.data.createProgram;
        }
      }
    } else {
      result.type = 'e';
      result.response = response;
    }
    return result;
  },

  async getIrrReq({ commit }, inputVar) {
    var response = '';

    var queryUp =
      `query getirrreq {
    getIrrReq(irrReqId: ` +
      inputVar.id +
      `) {
      header {
        sectorGroupId
        wVolArea
        shifts
      }
      daily {
        day
        startTime
        endTime
        suggCost
        suggStartTime
        cost
        manual
        ferts

      }
      water {
        day
        sectorGroupId
        volArea
        startTime
        endTime
      }
      fert {
        sgName
        fertName
        wvaReq
        fvaReal
        fvaPlan
        wvaPlan
        fvaTotal
      }
      shiftGap
    }
  }

`;

    await axios
      .post(
        myHttp,
        {
          query: queryUp,
        },
        {
          headers: {
            Authorization: 'JWT ' + inputVar.tokens.tokenIM,
          },
        }
      )
      .then((res) => {
        response = res;
      })
      .catch((error) => {
        response = error;
      });

    var result = {
      type: null,
      response: null,
      query: 'getIrrReq',
    };
    result.type = 'v';
    if (response.data) {
      if (response.data.errors && response.data.errors.length > 0) {
        result.response = response.data.errors[0].message;
        result.type = 'e';
      } else {
        if (response.data.data.getIrrReq) {
          result.type = 's';
          if (response.data.data.getIrrReq.shiftGap === 0) {
            response.data.data.getIrrReq.shiftGap = null;
          } else {
            var num = response.data.data.getIrrReq.shiftGap;
            var hours = num / 60;
            var rhours = Math.floor(hours);
            if (rhours < 10) {
              rhours = '0' + rhours;
            }
            var minutes = (hours - rhours) * 60;
            var rminutes = Math.round(minutes);
            if (rminutes < 10) {
              rminutes = '0' + rminutes;
            }
            response.data.data.getIrrReq.shiftGap = rhours + ':' + rminutes;
          }
          commit('setIrrReqId', response.data.data.getIrrReq);
          result.response = response.data.data.getIrrReq;
        }
      }
    } else {
      result.type = 'e';
      result.response = response;
    }

    return result;
  },

  /* ---------------------------------------------- */
  async getIrrReqPend({ commit }, inputVar) {
    var response = '';
    var queryUp = `query getIrrReqPend  {
    getIrrReqPend {
      farmId
      farmName
      irrReqId
      water
      cost
      endDt
      startDt
      header {
        id
        headerName
        headerNumber
      }
    }
  }

`;

    await axios
      .post(
        myHttp,
        {
          query: queryUp,
        },
        {
          headers: {
            Authorization: 'JWT ' + inputVar.tokens.tokenIM,
          },
        }
      )
      .then((res) => {
        response = res;
      })
      .catch((error) => {
        response = error;
      });

    var result = {
      type: null,
      response: null,
      query: 'getIrrReqPend',
    };
    result.type = 'v';
    if (response.data) {
      if (response.data.errors && response.data.errors.length > 0) {
        result.response = response.data.errors[0].message;
        result.type = 'e';
      } else {
        if (response.data.data.getIrrReqPend) {
          result.type = 's';
          commit('setIrrReqByDate', response.data.data.getIrrReqPend);
          result.response = response.data.data.getIrrReqPend;
        }
      }
    } else {
      result.type = 'e';
      result.response = response;
    }
    return result;
  },
  /* ---------------------------------------------- */
  async getFertPlans({ commit }, inputVar) {
    var response = '';

    var queryUp =
      `query fertPlans {
    fertPlans(farmId: ` +
      inputVar.farmId +
      ` isOriginal: ` +
      inputVar.isOriginal +
      `) {
        latestIrrReq
    fplans {
      id
    fertRefId {
        id
        name
        solid
      }

    farmId {
      id
      name
    }
    fertOutId {
      id
      name
    }
    massAreaTgt
    startDt
    endDt
    secGrp {
      id
      sectorGroupId {
        id
        groupName
      }
    }
    date {
      startDt
      endDt
      massAreaTgt
    }
  }
}
}`;

    await axios
      .post(
        myHttp,
        {
          query: queryUp,
        },
        {
          headers: {
            Authorization: 'JWT ' + inputVar.tokens.tokenIM,
          },
        }
      )
      .then((res) => {
        response = res;
      })
      .catch((error) => {
        response = error;
      });

    var result = {
      type: null,
      response: null,
      query: 'getFertPlans',
    };
    result.type = 'v';
    if (response.data) {
      if (response.data.errors && response.data.errors.length > 0) {
        result.response = response.data.errors[0].message;
        result.type = 'e';
      } else {
        if (response.data.data.fertPlans) {
          result.type = 's';
          commit('setFertPlans', response.data.data.fertPlans);
          result.response = response.data.data.fertPlans;
        }
      }
    } else {
      result.type = 'e';
      result.response = response;
    }
    return result;
  },
  async getFarmsByMassivePlanFert({ commit }, inputVar) {
    var response = '';
    var queryUp = `query getFarmsByMassivePlanFert{
        farmsByMassivePlanFert(fertPlanMassiveId: ${inputVar.id}) {
            id
            farmId
            farmName
            sectorGroupId
            sectorGroupName
            massQty
            fertRefId
            checked
            fertPlanId
            percentage
          }
        }
        `;

    await axios
      .post(
        myHttp,
        {
          query: queryUp,
        },
        {
          headers: {
            Authorization: 'JWT ' + inputVar.tokens.tokenIM,
          },
        }
      )
      .then((res) => {
        response = res;
      })
      .catch((error) => {
        response = error;
      });

    var result = {
      type: null,
      response: null,
      query: 'getFarmsByMassivePlanFert',
    };
    result.type = 'v';
    if (response.data) {
      if (response.data.errors && response.data.errors.length > 0) {
        result.response = response.data.errors[0].message;
        result.type = 'e';
      } else {
        if (response.data.data.farmsByMassivePlanFert) {
          result.type = 's';
          commit('setIrrReqByDate', response.data.data.farmsByMassivePlanFert);
          result.response = response.data.data.farmsByMassivePlanFert;
        }
      }
    } else {
      result.type = 'e';
      result.response = response;
    }
    return result;
  },
  async getFertPlanMassiveWeekDetail({ commit }, inputVar) {
    var response = '';
    var queryUp = `query getFertPlanMassiveWeekDetail{
        fertPlanMassiveWeekDetail(fertPlanMassiveId: "${inputVar.id}") {
            id
            quantity
            startDt
            endDt
          }
        }
        `;

    await axios
      .post(
        myHttp,
        {
          query: queryUp,
        },
        {
          headers: {
            Authorization: 'JWT ' + inputVar.tokens.tokenIM,
          },
        }
      )
      .then((res) => {
        response = res;
      })
      .catch((error) => {
        response = error;
      });

    var result = {
      type: null,
      response: null,
      query: 'getFertPlanMassiveWeekDetail',
    };
    result.type = 'v';
    if (response.data) {
      if (response.data.errors && response.data.errors.length > 0) {
        result.response = response.data.errors[0].message;
        result.type = 'e';
      } else {
        if (response.data.data.fertPlanMassiveWeekDetail) {
          result.type = 's';
          result.response = response.data.data.fertPlanMassiveWeekDetail;
        }
      }
    } else {
      result.type = 'e';
      result.response = response;
    }
    return result;
  },
  async getSecGrpPlanFertByFarm({ commit }, inputVar) {
    var response = '';
    var queryUp = `query getSecGrpPlanFertByFarm{
        secGrpPlanFert(fertPlanId: ${inputVar.fertPlanId}) {
            fertPlanId {
              id
            }
            sectorGroupId {
              id
            }
          }
        }`;

    await axios
      .post(
        myHttp,
        {
          query: queryUp,
        },
        {
          headers: {
            Authorization: 'JWT ' + inputVar.tokens.tokenIM,
          },
        }
      )
      .then((res) => {
        response = res;
      })
      .catch((error) => {
        response = error;
      });

    var result = {
      type: null,
      response: null,
      query: 'getSecGrpPlanFertByFarm',
    };
    result.type = 'v';
    if (response.data) {
      if (response.data.errors && response.data.errors.length > 0) {
        result.response = response.data.errors[0].message;
        result.type = 'e';
      } else {
        if (response.data.data.secGrpPlanFert) {
          result.type = 's';
          commit('setIrrReqByDate', response.data.data.secGrpPlanFert);
          result.response = response.data.data.secGrpPlanFert;
        }
      }
    } else {
      result.type = 'e';
      result.response = response;
    }
    return result;
  },
  async getFertPlanMassive({ commit }, inputVar) {
    var response = '';

    var queryUp = `query FertPlanMassive {
        fertPlanMassive {
          id
          name
          type
          startDt
          endDt
          massQty
          fertRefId {
            id
            name
          }
          accountId {
            id
          }
        }
      }`;

    await axios
      .post(
        myHttp,
        {
          query: queryUp,
        },
        {
          headers: {
            Authorization: 'JWT ' + inputVar.tokens.tokenIM,
          },
        }
      )
      .then((res) => {
        response = res;
      })
      .catch((error) => {
        response = error;
      });

    var result = {
      type: null,
      response: null,
      query: 'getFertPlanMassive',
    };
    result.type = 'v';
    if (response.data) {
      if (response.data.errors && response.data.errors.length > 0) {
        result.response = response.data.errors[0].message;
        result.type = 'e';
      } else {
        if (response.data.data.fertPlanMassive) {
          result.type = 's';
          commit('setFertPlans', response.data.data.fertPlanMassive);
          result.response = response.data.data.fertPlanMassive;
        }
      }
    } else {
      result.type = 'e';
      result.response = response;
    }
    return result;
  },

  async getFertPlansAccount({ commit }, inputVar) {
    var response = '';

    var queryUp = `query fertPlansAccount {
        fertPlansAccount {
          latestIrrReq
          fplans {
            id
            fertRefId {
              id
              name
              solid
            }
            farmId {
              id
              name
            }
            fertOutId {
              id
              name
            }
            massAreaTgt
            startDt
            endDt
            secGrp {
              id
              sectorGroupId {
                id
                groupName
              }
            }
            date {
              startDt
              endDt
              massAreaTgt
            }
          }
        }
      }`;

    await axios
      .post(
        myHttp,
        {
          query: queryUp,
        },
        {
          headers: {
            Authorization: 'JWT ' + inputVar.tokens.tokenIM,
          },
        }
      )
      .then((res) => {
        response = res;
      })
      .catch((error) => {
        response = error;
      });

    var result = {
      type: null,
      response: null,
      query: 'getFertPlansAccount',
    };
    result.type = 'v';
    if (response.data) {
      if (response.data.errors && response.data.errors.length > 0) {
        result.response = response.data.errors[0].message;
        result.type = 'e';
      } else {
        if (response.data.data.fertPlansAccount) {
          result.type = 's';
          commit('setFertPlansAccount', response.data.data.fertPlansAccount);
          result.response = response.data.data.fertPlansAccount;
        }
      }
    } else {
      result.type = 'e';
      result.response = response;
    }
    return result;
  },

  async calcFertPlans({ commit }, inputVar) {
    var response = '';

    var queryUp =
      `query calcFertPlan {
    calcFertPlan(fertid: ` +
      inputVar.calc.fertRefId.id +
      ` start:"` +
      inputVar.calc.startDt +
      `" secgrp:[` +
      inputVar.calc.secGrp +
      `] end:"` +
      inputVar.calc.endDt +
      `" massAreaTgt:" ` +
      inputVar.calc.massAreaTgt +
      `") {
      header {
        fertRefId
        startDt
        endDt
        sectorGroup
        massAreaTgt
        volAreaTgt
        massTgt
      }
      detail{
        groupName
        area
        massAreaTgt
        volAreaTgt
        massTgt
        volTgt
      }
    }
  }

`;

    await axios
      .post(
        myHttp,
        {
          query: queryUp,
        },
        {
          headers: {
            Authorization: 'JWT ' + inputVar.tokens.tokenIM,
          },
        }
      )
      .then((res) => {
        response = res;
      })
      .catch((error) => {
        response = error;
      });

    var result = {
      type: null,
      response: null,
      query: 'calcFertPlans',
    };
    result.type = 'v';
    if (response.data) {
      if (response.data.errors && response.data.errors.length > 0) {
        result.response = response.data.errors[0].message;
        result.type = 'e';
      } else {
        if (response.data.data.calcFertPlan) {
          result.type = 's';

          result.response = response.data.data.calcFertPlan;
        }
      }
    } else {
      result.type = 'e';
      result.response = response;
    }
    return result;
  },
  async createFertPlans({ commit }, inputVar) {
    var queryUp = '';
    var response = '';
    var queryVar = [];
    inputVar.fertPlans.forEach(async function (item) {
      if (item.fertOutId.id === null) {
        item.fertOutId.id = -1;
      }
      queryVar.push(
        `{ 
          isOriginal: ${inputVar.isOriginal}, 
          farmId: ${item.farmId.id}, 
          planMassiveId: ${item.planMassiveId}, 
          fertRefId: ${item.fertRefId.id}, 
          fertOutId: ${item.fertOutId.id}, 
          sectorGroups: [${item.secGrp
            .map((sg) => `{ secGrpId: ${sg.secGrpId}, massQtySecGrp: "${sg.massQtySecGrp}" }`)
            .join(',')}],
          startDt: "${item.startDt}",
          endDt: "${item.endDt}",
          massAreaTgt: "${item.massAreaTgt}", 
          cycles: [${item.formDate}] 
        }`
      );
    });
    if (queryVar.length > 0) {
      queryUp =
        `mutation createFertPlans {
      createFertPlan(fplans:[` +
        queryVar +
        `]) {
        fplansOut {
          id
          farmId {
            id
          }

          fertRefId {
            id
          }
          startDt
          endDt          
        }
      }
    }

    `;

      await axios
        .post(
          myHttp,
          {
            query: queryUp,
          },
          {
            headers: {
              Authorization: 'JWT ' + inputVar.tokens.tokenIM,
            },
          }
        )
        .then((res) => {
          response = res;
        })
        .catch((error) => {
          response = error;
        });
    }

    var result = {
      type: null,
      response: null,
      query: 'createFertPlans',
    };
    result.type = 'v';
    if (response.data) {
      if (response.data.errors && response.data.errors.length > 0) {
        result.response = response.data.errors[0].message;
        result.type = 'e';
      } else {
        if (response.data.data.createFertPlan) {
          result.type = 's';

          result.response = response.data.data.createFertPlan;
        }
      }
    } else {
      result.type = 'e';
      result.response = response;
    }
    return result;
  },

  async createFertPlansFarm({ commit }, inputVar) {
    var queryUp = '';
    var response = '';
    var queryVar = [];
    inputVar.fertPlans.forEach(async function (item) {
      if (item.fertOutId.id === null) {
        item.fertOutId.id = -1;
      }
      queryVar.push(`{
        isOriginal: ${inputVar.isOriginal},
        farmId: ${item.farmId.id},
        fertRefId: ${item.fertRefId.id},
        fertOutId: ${item.fertOutId.id},
        sectorGroups: [${item.secGrp}],
        startDt: "${item.startDt}",
        endDt: "${item.endDt}",
        massAreaTgt: "${item.massAreaTgt}",
        cycles: [${item.formDate}]
      }`);
    });
    if (queryVar.length > 0) {
      queryUp =
        `mutation createFertPlansFarm {
      createFertPlanFarm(fplans:[` +
        queryVar +
        `]) {
        fplansOut {
          id
          farmId {
            id
          }
 
          fertRefId {
            id
          }
          startDt
          endDt          
        }
      }
    }
 
    `;

      await axios
        .post(
          myHttp,
          {
            query: queryUp,
          },
          {
            headers: {
              Authorization: 'JWT ' + inputVar.tokens.tokenIM,
            },
          }
        )
        .then((res) => {
          response = res;
        })
        .catch((error) => {
          response = error;
        });
    }

    var result = {
      type: null,
      response: null,
      query: 'createFertPlansFarm',
    };
    result.type = 'v';
    if (response.data) {
      if (response.data.errors && response.data.errors.length > 0) {
        result.response = response.data.errors[0].message;
        result.type = 'e';
      } else {
        if (response.data.data.createFertPlanFarm) {
          result.type = 's';

          result.response = response.data.data.createFertPlanFarm;
        }
      }
    } else {
      result.type = 'e';
      result.response = response;
    }
    return result;
  },

  async updateFarmsMassivePlanFert({ commit }, inputVar) {
    var queryUp = '';
    var response = '';
    var queryVar = [];
    inputVar.data.forEach(async function (item) {
      var massQty =
        item.massQty === null || isNaN(item.massQty) || item.massQty === '' ? `` : ` massQty: "${item.massQty}", `;
      var fertRefId = item.fertRefId === null ? `` : ` fertRefId: ${item.fertRefId}, `;
      var fertPlanId = item.fertPlanId === null ? `` : ` fertPlanId: ${item.fertPlanId}, `;
      var percentage =
        item.percentage === null || isNaN(item.percentage) || item.percentage === ''
          ? ``
          : ` percentage: "${item.percentage}", `;
      var id = item.id === null ? '' : ` id: ${item.id}, `;
      queryVar.push(
        ` { farmId: ${item.farmId},
            sectorGroupId: ${item.sectorGroupId},
            checked: ${item.checked},
            ${id}
            ${massQty}
            ${percentage}
            ${fertRefId}
            ${fertPlanId}
          }`
      );
    });
    if (queryVar.length > 0) {
      queryUp = `mutation updFarmsMassivePlanFert {
            updFarmsMassivePlanFert( 
                idMassivePlanFert: ${inputVar.idMassivePlanFert},
                farmsMassivePlanFert:[${queryVar}]) {
                  out {
                    id
                    farmId {
                      id
                      name
                    }
                  } 
                }
          }`;

      await axios
        .post(
          myHttp,
          {
            query: queryUp,
          },
          {
            headers: {
              Authorization: 'JWT ' + inputVar.tokens.tokenIM,
            },
          }
        )
        .then((res) => {
          response = res;
        })
        .catch((error) => {
          response = error;
        });
    }

    var result = {
      type: null,
      response: null,
      query: 'updFarmsMassivePlanFert',
    };
    result.type = 'v';
    if (response.data) {
      if (response.data.errors && response.data.errors.length > 0) {
        result.response = response.data.errors[0].message;
        result.type = 'e';
      } else {
        if (response.data.data.updFarmsMassivePlanFert) {
          result.type = 's';

          result.response = response.data.data.updFarmsMassivePlanFert;
        }
      }
    } else {
      result.type = 'e';
      result.response = response;
    }
    return result;
  },
  async updateFertPlans({ commit }, inputVar) {
    var queryUp = '';
    var response = '';
    var queryVar = [];

    inputVar.fertPlans.forEach(async function (item) {
      if (item.fertOutId.id === null) {
        item.fertOutId.id = -1;
      }
      queryVar.push(
        `{ 
          fertPlanId: ${item.id}, 
          isOriginal: ${inputVar.isOriginal}, 
          farmId: ${item.farmId.id}, 
          planMassiveId: ${item.planMassiveId},
          fertRefId: ${item.fertRefId.id}, 
          fertOutId: ${item.fertOutId.id}, 
          sectorGroups: [${item.secGrp
            .map((sg) => `{ secGrpId: ${sg.secGrpId}, massQtySecGrp: "${sg.massQtySecGrp}" }`)
            .join(',')}],
          startDt: "${item.startDt}", 
          endDt: "${item.endDt}", 
          massAreaTgt: "${item.massAreaTgt}", 
          cycles: [${item.formDate}] 
        }`
      );
    });

    if (queryVar.length > 0) {
      queryUp =
        `mutation updFertPlans {
      updFertPlan(fplans:[` +
        queryVar +
        `]) {
        fplansOut {
          id
          farmId {
            id
          }

          fertRefId {
            id
          }
          startDt
          endDt
        }
      }
    }
    `;

      await axios
        .post(
          myHttp,
          {
            query: queryUp,
          },
          {
            headers: {
              Authorization: 'JWT ' + inputVar.tokens.tokenIM,
            },
          }
        )
        .then((res) => {
          response = res;
        })
        .catch((error) => {
          response = error;
        });
    }
    var result = {
      type: null,
      response: null,
      query: 'updateFertPlans',
    };
    result.type = 'v';
    if (response.data) {
      if (response.data.errors && response.data.errors.length > 0) {
        result.response = response.data.errors[0].message;
        result.type = 'e';
      } else {
        if (response.data.data.updFertPlan) {
          result.type = 's';

          result.response = response.data.data.updFertPlan;
        }
      }
    } else {
      result.type = 'e';
      result.response = response;
    }
    return result;
  },

  async updateFertPlansFarm({ commit }, inputVar) {
    let queryUp = '';
    let response = '';
    let queryVar = [];

    inputVar.fertPlans.forEach(async function (item) {
      if (item.fertOutId.id === null) {
        item.fertOutId.id = -1;
      }
      queryVar.push(`{
        fertPlanId: ${item.id},
        isOriginal: ${inputVar.isOriginal},
        farmId: ${item.farmId.id},
        fertRefId: ${item.fertRefId.id},
        fertOutId: ${item.fertOutId.id},
        sectorGroups: [${item.secGrp}],
        startDt: "${item.startDt}",
        endDt: "${item.endDt}",
        massAreaTgt: "${item.massAreaTgt}",
        cycles: [${item.formDate}]
      }`);
    });

    if (queryVar.length > 0) {
      queryUp = `mutation updFertPlansFarm {
        updFertPlanFarm(fplans: [${queryVar.join(',')}]) {
          fplansOut {
            id
            farmId {
              id
            }
            fertRefId {
              id
            }
            startDt
            endDt
          }
        }
      }`;

      await axios
        .post(myHttp, { query: queryUp }, { headers: { Authorization: 'JWT ' + inputVar.tokens.tokenIM } })
        .then((res) => {
          response = res;
        })
        .catch((error) => {
          response = error;
        });
    }

    let result = {
      type: null,
      response: null,
      query: 'updateFertPlansFarm',
    };
    result.type = 'v';

    if (response.data) {
      if (response.data.errors && response.data.errors.length > 0) {
        result.response = response.data.errors[0].message;
        result.type = 'e';
      } else {
        if (response.data.data.updFertPlanFarm) {
          result.type = 's';
          result.response = response.data.data.updFertPlanFarm;
        }
      }
    } else {
      result.type = 'e';
      result.response = response;
    }

    return result;
  },

  async deleteFertPlans({ commit }, inputVar) {
    var queryUp = '';
    var response = '';
    var queryVar = [];
    inputVar.fertPlans.forEach(async function (item) {
      queryVar.push(item.id + `,`);
    });

    if (queryVar.length > 0) {
      queryUp =
        `mutation delFertPlans {
        delFertPlans(ids:[` +
        queryVar +
        `]) {
         response {
           id
         }
        }
      }
      `;

      await axios
        .post(
          myHttp,
          {
            query: queryUp,
          },
          {
            headers: {
              Authorization: 'JWT ' + inputVar.tokens.tokenIM,
            },
          }
        )
        .then((res) => {
          response = res;
        })
        .catch((error) => {
          response = error;
        });
    }

    var result = {
      type: null,
      response: null,
      query: 'deleteFertPlans',
    };
    result.type = 'v';
    if (response.data) {
      if (response.data.errors && response.data.errors.length > 0) {
        result.response = response.data.errors[0].message;
        result.type = 'e';
      } else {
        if (response.data.data.delFertPlans) {
          result.type = 's';

          result.response = response.data.data.delFertPlans;
        }
      }
    } else {
      result.type = 'e';
      result.response = response;
    }
    return result;
  },
  // -------------Fert Container --------------------------------------
  async getFertContainer({ commit }, inputVar) {
    var response = '';
    var queryUp =
      `query containers {
      containersByFarm(farmId: ` +
      inputVar.farmId +
      `) {
        fertContainerId
        name
        capacity
        fertOut {
          fertOutId
          fertOutName
          fertRefId
          fertRefName
          startDt
          endDt
        }
        headers{
          id
          headerName
          headerNumber
        }
        farmId
      }
    }
    `;

    await axios
      .post(
        myHttp,
        {
          query: queryUp,
        },
        {
          headers: {
            Authorization: 'JWT ' + inputVar.tokens.tokenIM,
          },
        }
      )
      .then((res) => {
        response = res;
      })
      .catch((error) => {
        response = error;
      });

    var result = {
      type: null,
      response: null,
      query: 'getFertContainer',
    };
    result.type = 'v';
    if (response.data) {
      if (response.data.errors && response.data.errors.length > 0) {
        result.response = response.data.errors[0].message;
        result.type = 'e';
      } else {
        if (response.data.data.containersByFarm) {
          result.type = 's';
          commit('setContainers', response.data.data.containersByFarm);
          result.response = response.data.data.containersByFarm;
        }
      }
    } else {
      result.type = 'e';
      result.response = response;
    }
    return result;
  },
  async createFertContainer({ commit }, inputVar) {
    var queryUp = '';
    var response = '';
    var queryVar = [];
    var headers = [];
    inputVar.containers.forEach(async function (item) {
      if (item.capacity === null) item.capacity = -1;

      if (item.headers && item.headers.length > 0) {
        item.headers.forEach(async function (header) {
          headers.push(header.id);
        });
      } else {
        headers.push(-1);
      }

      queryVar.push(
        `{ name: "${item.name}", capacity: ${item.capacity}, farmId: ${item.farmId}, headers: [${headers}] }`
      );
      if (item.capacity === -1) item.capacity = null;
    });

    if (queryVar.length > 0) {
      queryUp = `mutation createFertContainer {
          createFertContainers(fconts: [${queryVar.join(', ')}]) {
            fcontsOut {
              id
              name
              capacity
              farmId {
                id
              }
            }
          }
        }
        `;

      await axios
        .post(
          myHttp,
          {
            query: queryUp,
          },
          {
            headers: {
              Authorization: 'JWT ' + inputVar.tokens.tokenIM,
            },
          }
        )
        .then((res) => {
          response = res;
        })
        .catch((error) => {
          response = error;
        });
    }

    var result = {
      type: null,
      response: null,
      query: 'createFertContainer',
    };
    result.type = 'v';
    if (response.data) {
      if (response.data.errors && response.data.errors.length > 0) {
        result.response = response.data.errors[0].message;
        result.type = 'e';
      } else {
        if (response.data.data.createFertContainers) {
          result.type = 's';

          result.response = response.data.data.createFertContainers;
        }
      }
    } else {
      result.type = 'e';
      result.response = response;
    }
    return result;
  },
  async updateFertContainer({ commit }, inputVar) {
    var queryUp = '';
    var response = '';
    var queryVar = [];

    inputVar.containers.forEach(async function (item) {
      if (item.capacity === null) item.capacity = -1;

      queryVar.push(
        `{ fertContainerId:` +
          item.fertContainerId +
          `,name: "` +
          item.name +
          ` " ,capacity: ` +
          item.capacity +
          ` ,farmId: ` +
          item.farmId +
          `}
        `
      );
      if (item.capacity === -1) item.capacity = null;
    });

    if (queryVar.length > 0) {
      queryUp =
        `mutation updFertContainers {
          updFertContainers(fconts:[` +
        queryVar +
        `]) {
            fcontsOut {
              id
              name
              capacity

              farmId {
                id
              }
            }
          }
        }
        `;

      await axios
        .post(
          myHttp,
          {
            query: queryUp,
          },
          {
            headers: {
              Authorization: 'JWT ' + inputVar.tokens.tokenIM,
            },
          }
        )
        .then((res) => {
          response = res;
        })
        .catch((error) => {
          response = error;
        });
    }

    var result = {
      type: null,
      response: null,
      query: 'updateFertContainer',
    };
    result.type = 'v';
    if (response.data) {
      if (response.data.errors && response.data.errors.length > 0) {
        result.response = response.data.errors[0].message;
        result.type = 'e';
      } else {
        if (response.data.data.updFertContainers) {
          result.type = 's';

          result.response = response.data.data.updFertContainers;
        }
      }
    } else {
      result.type = 'e';
      result.response = response;
    }
    return result;
  },

  async deleteFertContainer({ commit }, inputVar) {
    var queryUp = '';
    var response = '';
    var queryVar = [];
    inputVar.containers.forEach(async function (item) {
      queryVar.push(item.fertContainerId + `,`);
    });

    if (queryVar.length > 0) {
      queryUp =
        `mutation delFertContainer {
        delFertContainers(ids:[` +
        queryVar +
        `]) {
         response {
           id
           name
         }
        }
      }
      `;

      await axios
        .post(
          myHttp,
          {
            query: queryUp,
          },
          {
            headers: {
              Authorization: 'JWT ' + inputVar.tokens.tokenIM,
            },
          }
        )
        .then((res) => {
          response = res;
        })
        .catch((error) => {
          response = error;
        });
    }

    var result = {
      type: null,
      response: null,
      query: 'deleteFertContainer',
    };
    result.type = 'v';
    if (response.data) {
      if (response.data.errors && response.data.errors.length > 0) {
        result.response = response.data.errors[0].message;
        result.type = 'e';
      } else {
        if (response.data.data.delFertContainers) {
          result.type = 's';

          result.response = response.data.data.delFertContainers;
        }
      }
    } else {
      result.type = 'e';
      result.response = response;
    }
    return result;
  },

  async getFarms({ commit }, inputVar) {
    var response = '';
    var a = '';
    if (!inputVar.all) {
      a = `query getFarms {
        farms {
          name
          id
          lat
          lon
          preIrr
          postIrr
          label
          irrRotationId {
            id
            name
          }
          progProfile {
            id
            name
            progFunction
            single

          }
          systemRefId{
            id
            name
            maxShifts
            doubleSystem
            doubleInjection
            standard
          }
          doubleSystem
          doubleInjection
          accountId {
            accountName
          }
          controllerId {
            id
            name
            plus
            typeId {
              model
              externalId
            }
          }
          doserId {
            id
            serialNum
            doserTypeId {
              doserModel
            }
          }
          eSupplier {
            id
            name
          }
          eTariff {
            id
            name
            preferredEndTime
          }
        }
      }


      `;
    } else {
      a = ` query getFarms { farms (restricted:false) {
          name
          id
          lat
          lon
          preIrr
          postIrr
          irrRotationId {
            id
            name
          }
          progProfile {
            id
            name
            progFunction
            single

          }
          systemRefId{
            id
            name
            maxShifts
            doubleSystem
            doubleInjection
            standard
          }
          doubleSystem
          doubleInjection
          accountId {
            accountName
          }
          controllerId {
            id
            name
            typeId {model}
          }
          doserId {
            id
            serialNum
            doserTypeId {
              doserModel
            }
          }
          eSupplier {
            id
            name
          }
          eTariff {
            id
            name
            preferredEndTime
          }
        }
      }


      `;
    }
    await axios
      .post(
        myHttp,
        {
          query: a,
        },
        {
          headers: {
            Authorization: 'JWT ' + inputVar.tokens.tokenIM,
          },
        }
      )
      .then((res) => {
        response = res;
      })
      .catch((error) => {
        response = error;
      });

    var result = {
      type: null,
      response: null,
      query: 'getFarms',
    };
    result.type = 'v';
    if (response.data) {
      if (response.data.errors && response.data.errors.length > 0) {
        result.response = response.data.errors[0].message;
        result.type = 'e';
      } else {
        if (response.data.data.farms) {
          for (var i = 0; i < response.data.data.farms.length; i++) {
            /*    response.data.data.farms[i].name = response.data.data.farms[i].name.toLowerCase()
              response.data.data.farms[i].name = response.data.data.farms[i].name.charAt(0).toUpperCase() +
              response.data.data.farms[i].name.slice(1) */
            response.data.data.farms[i].name = response.data.data.farms[i].name.replace(
              /(^\w{1})|(\s+\w{1})/g,
              (letter) => letter.toUpperCase()
            );
          }

          result.type = 's';
          commit('setFarms', response.data.data.farms);
          result.response = response.data.data.farms;
        }
      }
    } else {
      result.type = 'e';
      result.response = response;
    }
    return result;
  },

  async getFarmsLabels({ commit }, inputVar) {
    var response = '';
    var queryLabel = `query getFarmsLabels {
                        getFarmsLabels
                  }`;
    await axios
      .post(
        myHttp,
        {
          query: queryLabel,
        },
        {
          headers: {
            Authorization: 'JWT ' + inputVar.tokens.tokenIM,
          },
        }
      )
      .then((res) => {
        response = res;
      })
      .catch((error) => {
        response = error;
      });

    var result = {
      type: null,
      response: null,
      query: 'getFarmsLabels',
    };
    result.type = 'v';
    if (response.data) {
      if (response.data.errors && response.data.errors.length > 0) {
        result.response = response.data.errors[0].message;
        result.type = 'e';
      } else {
        if (response.data.data.getFarmsLabels) {
          result.type = 's';

          result.response = response.data.data.getFarmsLabels;
        }
      }
    } else {
      result.type = 'e';
      result.response = response;
    }
    return result;
  },

  async getFarmById({ commit }, inputVar) {
    var response = '';
    await axios
      .post(
        myHttp,
        {
          query:
            `query farm_by_id {
            farmById(farmId: ` +
            inputVar.farmId +
            `) {
              name
              id
              label
              lat
              lon
              orderby
              preIrr
              postIrr
              doubleSystem
              doubleInjection
              irrRotationId {
                id
                name
              }
              progProfile {
                id
                name
                progFunction
                single

              }
              systemRefId{
                id
                name
                maxShifts
                doubleSystem
                doubleInjection
                standard
              }
              accountId {
                accountName
              }
              controllerId {
                id
                name
                plus
                typeId {model externalId}
              }
              sector {
                id
                name
                groupId {
                  id
                  groupName
                  master{id groupName}
                  system
                  useAsGroup
                }
                output
                auxOutput
                waterMeter
                header
                cloudFlow
                Parent {
                  id
                  parSectorId {
                    id
                  }
                  name
                  cultivarTypeId{
                    id
                    name
                  }
                  cultivarVarietyId {
                    id
                    name
                  }
                  soilTypeId {
                    id
                    name
                  }
                  irrigationSpacingId {
                    id
                    dripFlow
                    dripSpacing
                    linesPerPlantLine
                  }
                  plantSpacingId {
                    id
                    plantSpacing
                    plantLineSpacing
                  }
                  parcelTypeId {
                    id
                    name
                    parcelType
                  }
                  area
                  calculatedFlow
                  mapRef
                }
              }
              eSupplier {
                id
                name
              }
              eTariff {
                id
                name
                preferredEndTime
                isSolar
                solarPeakTime
              }
              Corporation {
                id
                percentage
                farmId {
                  id
                }
                contactId {
                  id
                  name
                }
              }
            }
          }
          `,
        },
        {
          headers: {
            Authorization: 'JWT ' + inputVar.tokens.tokenIM,
          },
        }
      )
      .then((res) => {
        response = res;
      })
      .catch((error) => {
        response = error;
      });

    var result = {
      type: null,
      response: null,
      query: 'getFarmById',
    };
    result.type = 'v';
    if (response.data) {
      if (response.data.errors && response.data.errors.length > 0) {
        result.response = response.data.errors[0].message;
        result.type = 'e';
      } else {
        if (response.data.data.farmById) {
          /*  response.data.data.farmById.name = response.data.data.farmById.name.toLowerCase()
              response.data.data.farmById.name = response.data.data.farmById.name.charAt(0).toUpperCase() +
              response.data.data.farmById.name.slice(1) */
          response.data.data.farmById.name = response.data.data.farmById.name.replace(
            /(^\w{1})|(\s+\w{1})/g,
            (letter) => letter.toUpperCase()
          );

          result.type = 's';
          commit('setFarmById', response.data.data.farmById);
          result.response = response.data.data.farmById;
        }
      }
    } else {
      result.type = 'e';
      result.response = response;
    }
    return result;
  },
  async updFarms({ commit }, inputVar) {
    var queryUp = '';
    var response = '';
    var queryVar = [];

    inputVar.farmsUp.forEach(async function (item) {
      if (item.lat === null) item.lat = -1;
      if (item.lat === '') item.lat = -1;
      if (item.lon === '') item.lon = -1;
      if (item.lon === null) item.lon = -1;
      if (item.doserId.id === null) item.doserId.id = -1;
      if (item.eTariff.id === null) item.eTariff.id = -1;
      if (item.eSupplier.id === null) item.eSupplier.id = -1;
      if (item.eSupplier.id === null) item.eSupplier.id = -1;
      if (item.progProfile.id === null) item.progProfile.id = -1;
      if (item.irrRotationId.id === null) item.irrRotationId.id = -1;
      if (item.systemRefId.id === null) item.systemRefId.id = -1;
      if (!item.preReq) item.preReq = 0;
      if (!item.postReq) item.postReq = 0;

      queryVar.push(`{farmId: ${item.id}, 
                      name: "${item.name}", 
                      label: "${item.label}", 
                      lat: ${item.lat}, 
                      lon: ${item.lon},
                      controllerId: ${item.controllerId.id},
                      doserId: ${item.doserId.id}, 
                      supplierId: ${item.eSupplier.id}, 
                      tariffId: ${item.eTariff.id}, 
                      preIrr: ${item.preIrr}, 
                      postIrr: ${item.postIrr}, 
                      progProfileId: ${item.progProfile.id}, 
                      irrRotationId: ${item.irrRotationId.id}, 
                      systemRefId: ${item.systemRefId.id}
                    }`);

      if (item.lat === -1) item.lat = null;
      if (item.lon === -1) item.lon = null;

      if (item.doserId.id === -1) item.doserId.id = null;
      if (item.eSupplier.id === -1) item.eSupplier.id = null;
      if (item.eTariff.id === -1) item.eTariff.id = null;
      if (item.progProfile.id === -1) item.progProfile.id = null;
      if (item.irrRotationId.id === -1) item.irrRotationId.id = null;
      if (item.systemRefId.id === -1) item.systemRefId.id = null;
    });

    if (queryVar.length > 0) {
      queryUp = `mutation updateFarms {
          updFarms(farms:[${queryVar}]){
            response {
              id
              name
              label
              lat
              lon
              orderby
              controllerId {
                name
                id
              }
            }
          }
        }
        `;

      await axios
        .post(
          myHttp,
          {
            query: queryUp,
          },
          {
            headers: {
              Authorization: 'JWT ' + inputVar.tokens.tokenIM,
            },
          }
        )
        .then((res) => {
          response = res;
        })
        .catch((error) => {
          response = error;
        });
    }

    var result = {
      type: null,
      response: null,
      query: 'updFarms',
    };
    result.type = 'v';
    if (response.data) {
      if (response.data.errors && response.data.errors.length > 0) {
        result.response = response.data.errors[0].message;
        result.type = 'e';
      } else {
        if (response.data.data.updFarms) {
          result.type = 's';

          result.response = response.data.data.updFarms;
        }
      }
    } else {
      result.type = 'e';
      result.response = response;
    }

    return result;
  },
  async updHeader({ commit }, inputVar) {
    var queryUp = '';
    var response = '';
    var queryVar = [];

    inputVar.headerUp.forEach(async function (item) {
      if (item.lat === null) item.lat = -1;
      if (item.lat === '') item.lat = -1;
      if (item.lon === '') item.lon = -1;
      if (item.lon === null) item.lon = -1;
      if (!item.doserId || item.doserId.id === null) {
        item.doserId = { id: -1 };
      }
      if (!item.preReq) item.preReq = 0;
      if (!item.postReq) item.postReq = 0;

      queryVar.push(`{farmId: ${item.farmId.id}, 
                      name: "${item.headerName}", 
                      lat: ${item.lat}, 
                      lon: ${item.lon},
                      controllerId: ${item.controllerId.id},
                      doserId: ${item.doserId.id}, 
                      preIrr: ${item.preIrr}, 
                      postIrr: ${item.postIrr},
                      headerNum: ${inputVar.headerNum}
                    }`);

      if (item.lat === -1) item.lat = null;
      if (item.lon === -1) item.lon = null;
      if (item.doserId.id === -1) item.doserId.id = null;
    });

    if (queryVar.length > 0) {
      queryUp = `mutation updateHeader {
          updHeader(headers:[${queryVar}]){
            response {
              id
              headerName
              headerNumber
              lat
              lon
              preIrr
              postIrr
              farmId{
                id
                name
              }
              progresId
              doserId{
                id
                serialNum
                doserTypeId{
                  doserModel
                }
              }
              controllerId {
                name
                id
              }
            }
          }
        }
        `;

      await axios
        .post(
          myHttp,
          {
            query: queryUp,
          },
          {
            headers: {
              Authorization: 'JWT ' + inputVar.tokens.tokenIM,
            },
          }
        )
        .then((res) => {
          response = res;
        })
        .catch((error) => {
          response = error;
        });
    }

    var result = {
      type: null,
      response: null,
      query: 'updHeader',
    };
    result.type = 'v';
    if (response.data) {
      if (response.data.errors && response.data.errors.length > 0) {
        result.response = response.data.errors[0].message;
        result.type = 'e';
      } else {
        if (response.data.data.updHeader) {
          result.type = 's';

          result.response = response.data.data.updHeader;
        }
      }
    } else {
      result.type = 'e';
      result.response = response;
    }

    return result;
  },
  async getHeader({ commit }, inputVar) {
    var response = '';
    await axios
      .post(
        myHttp,
        {
          query: `query get_header {
            getHeader(controllerId: ${inputVar.controllerId}, headerNum: ${inputVar.headerNum}) {
      				id
      				headerName
              lat
              lon
              headerNumber
              progresId
              preIrr
              postIrr
              controllerId{
                id
                name
              }
              doserId{
                id
                serialNum
                doserTypeId{
                  doserModel
                }
              }
             farmId{
              id
              name
             }           
            }
          }
          `,
        },
        {
          headers: {
            Authorization: 'JWT ' + inputVar.tokens.tokenIM,
          },
        }
      )
      .then((res) => {
        response = res;
      })
      .catch((error) => {
        response = error;
      });

    var result = {
      type: null,
      response: null,
      query: 'getHeader',
    };
    result.type = 'v';
    if (response.data) {
      if (response.data.errors && response.data.errors.length > 0) {
        result.response = response.data.errors[0].message;
        result.type = 'e';
      } else {
        if (response.data.data.getHeader) {
          response.data.data.getHeader.farmId.name = response.data.data.getHeader.farmId.name.replace(
            /(^\w{1})|(\s+\w{1})/g,
            (letter) => letter.toUpperCase()
          );

          result.type = 's';
          // commit('setGetHeader', response.data.data.getHeader);
          result.response = response.data.data.getHeader;
        }
      }
    } else {
      result.type = 'e';
      result.response = response;
    }
    return result;
  },
  async getHeadersController({ commit }, inputVar) {
    var response = '';
    await axios
      .post(
        myHttp,
        {
          query: `query getHeadersController {
            getHeadersController(controllerId: ${inputVar.controllerId}) {
      				id
      				headerName
              lat
              lon
              headerNumber
              progresId
              preIrr
              postIrr
              controllerId{
                id
                name
              }
              doserId{
                id
                serialNum
                doserTypeId{
                  doserModel
                }
              }
             farmId{
              id
              name
             }           
            }
          }
          `,
        },
        {
          headers: {
            Authorization: 'JWT ' + inputVar.tokens.tokenIM,
          },
        }
      )
      .then((res) => {
        response = res;
      })
      .catch((error) => {
        response = error;
      });

    var result = {
      type: null,
      response: null,
      query: 'getHeadersController',
    };
    result.type = 'v';
    if (response.data) {
      if (response.data.errors && response.data.errors.length > 0) {
        result.response = response.data.errors[0].message;
        result.type = 'e';
      } else {
        if (response.data.data.getHeadersController) {
          result.type = 's';
          result.response = response.data.data.getHeadersController;
        }
      }
    } else {
      result.type = 'e';
      result.response = response;
    }
    return result;
  },
  async createCorporations({ commit }, inputVar) {
    var queryUp = '';
    var response = '';
    var queryVar = [];
    inputVar.corporations.forEach(async function (item) {
      queryVar.push(
        '{farmId:' + item.farmId.id + ` , contactId:` + item.contactId.id + ` , percentage:` + item.percentage + `}`
      );
    });

    if (queryVar.length > 0) {
      queryUp =
        `mutation createCorporations {
          createCorporation(input:[` +
        queryVar +
        `]) {
            corporations {
              id
              contactId {
                id
              }
              percentage
            }
          }
        }
        `;

      await axios
        .post(
          myHttp,
          {
            query: queryUp,
          },
          {
            headers: {
              Authorization: 'JWT ' + inputVar.tokens.tokenIM,
            },
          }
        )
        .then((res) => {
          response = res;
        })
        .catch((error) => {
          response = error;
        });
    }

    var result = {
      type: null,
      response: null,
      query: 'createCorporations',
    };
    result.type = 'v';

    if (response.data) {
      if (response.data.errors && response.data.errors.length > 0) {
        result.response = response.data.errors[0].message;
        result.type = 'e';
      } else {
        if (response.data.data.createCorporation) {
          result.type = 's';

          result.response = response.data.data.createCorporation;
        }
      }
    } else {
      result.type = 'e';
      result.response = response;
    }
    return result;
  },
  async updateCorporations({ commit }, inputVar) {
    var queryUp = '';
    var response = '';
    var queryVar = [];
    inputVar.corporations.forEach(async function (item) {
      queryVar.push('{id:' + item.id + ` , percentage:"` + item.percentage + `"}`);
    });

    if (queryVar.length > 0) {
      queryUp =
        `mutation updateCorporations {
      updCorporation(input:[` +
        queryVar +
        `]) {
         response {
           id
           contactId {
             id
           }
           percentage
         }
       }
     }
     `;

      await axios
        .post(
          myHttp,
          {
            query: queryUp,
          },
          {
            headers: {
              Authorization: 'JWT ' + inputVar.tokens.tokenIM,
            },
          }
        )
        .then((res) => {
          response = res;
        })
        .catch((error) => {
          response = error;
        });
    }

    var result = {
      type: null,
      response: null,
      query: 'updateCorporations',
    };
    result.type = 'v';
    if (response.data) {
      if (response.data.errors && response.data.errors.length > 0) {
        result.response = response.data.errors[0].message;
        result.type = 'e';
      } else {
        if (response.data.data.updCorporation) {
          result.type = 's';

          result.response = response.data.data.updCorporation;
        }
      }
    } else {
      result.type = 'e';
      result.response = response;
    }
    return result;
  },
  async delCorporations({ commit }, inputVar) {
    var queryUp = '';
    var response = '';
    var queryVar = [];
    inputVar.corporations.forEach(async function (item) {
      queryVar.push(item.id + `,`);
    });

    if (queryVar.length > 0) {
      queryUp =
        `mutation delCorporations {
    delCorporation(ids:[` +
        queryVar +
        `]) {
      response {
        id

      }
     }
   }
   `;

      await axios
        .post(
          myHttp,
          {
            query: queryUp,
          },
          {
            headers: {
              Authorization: 'JWT ' + inputVar.tokens.tokenIM,
            },
          }
        )
        .then((res) => {
          response = res;
        })
        .catch((error) => {
          response = error;
        });
    }

    var result = {
      type: null,
      response: null,
      query: 'delCorporations',
    };
    result.type = 'v';
    if (response.data) {
      if (response.data.errors && response.data.errors.length > 0) {
        result.response = response.data.errors[0].message;
        result.type = 'e';
      } else {
        if (response.data.data.delCorporation) {
          result.type = 's';

          result.response = response.data.data.delCorporation;
        }
      }
    } else {
      result.type = 'e';
      result.response = response;
    }
    return result;
  },
  async getDosers({ commit }, inputVar) {
    var response = '';
    await axios
      .post(
        myHttp,
        {
          query: `query get_dosers {
        dosers {
          doserId
          serialNum
          doserTypeId
          model
          measuredFlow
          farmId
          farmName
          accountId
          controllerHeader
        }
      }
      `,
        },
        {
          headers: {
            Authorization: 'JWT ' + inputVar.tokens.tokenIM,
          },
        }
      )
      .then((res) => {
        response = res;
      })
      .catch((error) => {
        response = error;
      });
    var result = {
      type: null,
      response: null,
      query: 'getDosers',
    };
    result.type = 'v';
    if (response.data) {
      if (response.data.errors && response.data.errors.length > 0) {
        result.response = response.data.errors[0].message;
        result.type = 'e';
      } else {
        if (response.data.data.dosers) {
          result.type = 's';
          commit('setDosers', response.data.data.dosers);
          result.response = response.data.data.dosers;
        }
      }
    } else {
      result.type = 'e';
      result.response = response;
    }
    return result;
  },
  async getProposals({ commit }, inputVar) {
    var response = '';
    await axios
      .post(
        myHttp,
        {
          query: `query get_proposals {
        proposals {
          id
            name
            email
            addr1
            addr2
            addr3
            addr4
            phone
            group
            farms
            area
            date
            lastUpdDt
            status
            amount
        }
      }
      `,
        },
        {
          headers: {
            Authorization: 'JWT ' + inputVar.tokens.tokenIM,
          },
        }
      )
      .then((res) => {
        response = res;
      })
      .catch((error) => {
        response = error;
      });
    var result = {
      type: null,
      response: null,
      query: 'getProposal',
    };
    result.type = 'v';
    if (response.data) {
      if (response.data.errors && response.data.errors.length > 0) {
        result.response = response.data.errors[0].message;
        result.type = 'e';
      } else {
        if (response.data.data.proposals) {
          result.type = 's';
          commit('setProposals', response.data.data.proposals);
          result.response = response.data.data.proposals;
        }
      }
    } else {
      result.type = 'e';
      result.response = response;
    }
    return result;
  },
  async createProposal({ commit }, inputVar) {
    var response = '';

    var a =
      ` mutation create_proposal {
  createProposal (name:"` +
      inputVar.props.name +
      `", email:"` +
      inputVar.props.email +
      `", addr1:"` +
      inputVar.props.addr1 +
      `", addr2:" ` +
      inputVar.props.addr2 +
      `", addr3:" ` +
      inputVar.props.addr3 +
      `", addr4:" ` +
      inputVar.props.addr4 +
      `", phone:" ` +
      inputVar.props.phone +
      `", group: "` +
      inputVar.props.group +
      `", farms: ` +
      inputVar.props.farms +
      `, area: ` +
      inputVar.props.area +
      `, date:"` +
      inputVar.props.date +
      `", amount: ` +
      inputVar.props.amount +
      `) {
    data {
      name
      email
      addr1
      addr2
      addr3
      addr4
      phone
      group
      farms
      area
      date
      lastUpdDt
      status
      amount
    }
  }
}
 `;

    await axios
      .post(
        myHttp,
        {
          query: a,
        },
        {
          headers: {
            Authorization: 'JWT ' + inputVar.tokens.tokenIM,
          },
        }
      )
      .then((res) => {
        response = res;
      })
      .catch((error) => {
        response = error;
      });
    var result = {
      type: null,
      response: null,
      query: 'createProposal',
    };
    result.type = 'v';
    if (response.data) {
      if (response.data.errors && response.data.errors.length > 0) {
        result.response = response.data.errors[0].message;
        result.type = 'e';
      } else {
        if (response.data.data.createProposal) {
          result.type = 's';

          result.response = response.data.data.createProposal;
        }
      }
    } else {
      result.type = 'e';
      result.response = response;
    }
    return result;
  },
  async updateProposal({ commit }, inputVar) {
    var response = '';

    var a =
      ` mutation update_proposal {
  updProposal (proposalId:` +
      inputVar.props.id +
      `, status:"` +
      'accepted' +
      `"` +
      `) {
    data {
      name
      email
      addr1
      addr2
      addr3
      addr4
      phone
      group
      farms
      area
      date
      lastUpdDt
      status
      amount
    }
  }
}
 `;

    await axios
      .post(
        myHttp,
        {
          query: a,
        },
        {
          headers: {
            Authorization: 'JWT ' + inputVar.tokens.tokenIM,
          },
        }
      )
      .then((res) => {
        response = res;
      })
      .catch((error) => {
        response = error;
      });
    var result = {
      type: null,
      response: null,
      query: 'updateProposal',
    };
    result.type = 'v';
    if (response.data) {
      if (response.data.errors && response.data.errors.length > 0) {
        result.response = response.data.errors[0].message;
        result.type = 'e';
      } else {
        if (response.data.data.updProposal) {
          result.type = 's';

          result.response = response.data.data.updProposal;
        }
      }
    } else {
      result.type = 'e';
      result.response = response;
    }
    return result;
  },
  async delProposal({ commit }) {},
  async createUserProfiles({ commit }, inputVar) {
    var queryUp = '';
    var response = '';
    var queryVar = [];

    inputVar.userProfile.forEach(async function (item) {
      queryVar.push(
        `{profileName:"` +
          item.profileName +
          `" ,
      addFarm:` +
          item.addFarm +
          `,addAdmin:` +
          item.addAdmin +
          `,addConfig:` +
          item.addConfig +
          `,addIrrReq:` +
          item.addIrrReq +
          `,addSector:` +
          item.addSector +
          `,addParcel:` +
          item.addParcel +
          `,addFertPlan:` +
          item.addFertPlan +
          `,addFertControl:` +
          item.addFertControl +
          `,addFertRequest:` +
          item.addFertRequest +
          `,viewAdmin:` +
          item.viewAdmin +
          `,viewConfig:` +
          item.viewConfig +
          `,viewFarm:` +
          item.viewFarm +
          `,viewSector:` +
          item.viewSector +
          `,viewParcel:` +
          item.viewParcel +
          `,viewProgram:` +
          item.viewProgram +
          `,viewIrrReq:` +
          item.viewIrrReq +
          `,viewFertPlan:` +
          item.viewFertPlan +
          `,viewFertControl:` +
          item.viewFertControl +
          `,viewFertRequest:` +
          item.viewFertRequest +
          `,viewDashboard:` +
          item.viewDashboard +
          `,submitProgram:` +
          item.submitProgram +
          `,submitCloudImport:` +
          item.submitCloudImport +
          `,viewCloudImport:` +
          item.viewCloudImport +
          `,addSolidApp:` +
          item.addSolidApp +
          `,viewSolidApp:` +
          item.viewSolidApp +
          `,addStocks:` +
          item.addStocks +
          `,viewStocks:` +
          item.viewStocks +
          `}`
      );
    });

    if (queryVar.length > 0) {
      queryUp =
        `mutation createUserProfiles {
      createUserProfiles(input:[` +
        queryVar +
        `]) {
        response {
          id
          profileName
          addAdmin
          viewAdmin
          viewDashboard
          addFarm
          viewFarm
          addSector
          viewSector
          addParcel
          viewParcel
          addFertPlan
          viewFertPlan
          addIrrReq
          viewIrrReq
          addFertControl
          viewFertControl
          submitProgram
          viewProgram
          addFertRequest
          viewFertRequest
          submitCloudImport
          viewCloudImport
          addConfig
          viewConfig
          addSolidApp
          viewSolidApp
        }
      }
    }
    `;
      await axios
        .post(
          myHttp,
          {
            query: queryUp,
          },
          {
            headers: {
              Authorization: 'JWT ' + inputVar.tokens.tokenIM,
            },
          }
        )
        .then((res) => {
          response = res;
        })
        .catch((error) => {
          response = error;
        });
    }

    var result = {
      type: null,
      response: null,
      query: 'createUserProfiles',
    };
    result.type = 'v';
    if (response.data) {
      if (response.data.errors && response.data.errors.length > 0) {
        result.response = response.data.errors[0].message;
        result.type = 'e';
      } else {
        if (response.data.data.createUserProfiles) {
          result.type = 's';

          result.response = response.data.data.createUserProfiles;
        }
      }
    } else {
      result.type = 'e';
      result.response = response;
    }
    return result;
  },
  async updateUserProfiles({ commit }, inputVar) {
    var queryUp = '';
    var response = '';
    var queryVar = [];

    inputVar.userProfile.forEach(async function (item) {
      queryVar.push(
        `{userProfileId:` +
          item.id +
          `,profileName:"` +
          item.profileName +
          `" ,
      addFarm:` +
          item.addFarm +
          `,addAdmin:` +
          item.addAdmin +
          `,addConfig:` +
          item.addConfig +
          `,addIrrReq:` +
          item.addIrrReq +
          `,addSector:` +
          item.addSector +
          `,addParcel:` +
          item.addParcel +
          `,addFertPlan:` +
          item.addFertPlan +
          `,addFertControl:` +
          item.addFertControl +
          `,addFertRequest:` +
          item.addFertRequest +
          `,viewAdmin:` +
          item.viewAdmin +
          `,viewConfig:` +
          item.viewConfig +
          `,viewFarm:` +
          item.viewFarm +
          `,viewSector:` +
          item.viewSector +
          `,viewParcel:` +
          item.viewParcel +
          `,viewProgram:` +
          item.viewProgram +
          `,viewIrrReq:` +
          item.viewIrrReq +
          `,viewFertPlan:` +
          item.viewFertPlan +
          `,viewFertControl:` +
          item.viewFertControl +
          `,viewFertRequest:` +
          item.viewFertRequest +
          `,viewDashboard:` +
          item.viewDashboard +
          `,submitProgram:` +
          item.submitProgram +
          `,submitCloudImport:` +
          item.submitCloudImport +
          `,viewCloudImport:` +
          item.viewCloudImport +
          `,addSolidApp:` +
          item.addSolidApp +
          `,viewSolidApp:` +
          item.viewSolidApp +
          `,addStocks:` +
          item.addStocks +
          `,viewStocks:` +
          item.viewStocks +
          `}`
      );
    });

    if (queryVar.length > 0) {
      queryUp =
        `mutation updateUserProfiles {
      updUserProfiles(input:[` +
        queryVar +
        `]) {
        response {
          id
          profileName
          addAdmin
          viewAdmin
          viewDashboard
          addFarm
          viewFarm
          addSector
          viewSector
          addParcel
          viewParcel
          addFertPlan
          viewFertPlan
          addIrrReq
          viewIrrReq
          addFertControl
          viewFertControl
          submitProgram
          viewProgram
          addFertRequest
          viewFertRequest
          submitCloudImport
          viewCloudImport
          addConfig
          viewConfig
          addSolidApp
          viewSolidApp
        }
      }
    }

    `;

      await axios
        .post(
          myHttp,
          {
            query: queryUp,
          },
          {
            headers: {
              Authorization: 'JWT ' + inputVar.tokens.tokenIM,
            },
          }
        )
        .then((res) => {
          response = res;
        })
        .catch((error) => {
          response = error;
        });
    }

    var result = {
      type: null,
      response: null,
      query: 'updateUserProfiles',
    };
    result.type = 'v';
    if (response.data) {
      if (response.data.errors && response.data.errors.length > 0) {
        result.response = response.data.errors[0].message;
        result.type = 'e';
      } else {
        if (response.data.data.updUserProfiles) {
          result.type = 's';

          result.response = response.data.data.updUserProfiles;
        }
      }
    } else {
      result.type = 'e';
      result.response = response;
    }
    return result;
  },
  async delUserProfiles({ commit }, inputVar) {
    var queryUp = '';
    var response = '';
    var queryVar = [];
    inputVar.userProfile.forEach(async function (item) {
      queryVar.push(item.id + `,`);
    });

    if (queryVar.length > 0) {
      queryUp =
        `mutation delUserProfiles {
  delUserProfiles(ids:[` +
        queryVar +
        `]) {
    response {
      id

    }
   }
  }
  `;

      await axios
        .post(
          myHttp,
          {
            query: queryUp,
          },
          {
            headers: {
              Authorization: 'JWT ' + inputVar.tokens.tokenIM,
            },
          }
        )
        .then((res) => {
          response = res;
        })
        .catch((error) => {
          response = error;
        });
    }

    var result = {
      type: null,
      response: null,
      query: 'delUserProfiles',
    };
    result.type = 'v';
    if (response.data) {
      if (response.data.errors && response.data.errors.length > 0) {
        result.response = response.data.errors[0].message;
        result.type = 'e';
      } else {
        if (response.data.data.delUserProfiles) {
          result.type = 's';

          result.response = response.data.data.delUserProfiles;
        }
      }
    } else {
      result.type = 'e';
      result.response = response;
    }
    return result;
  },
  async createDosers({ commit }, inputVar) {
    var queryUp = '';
    var response = '';
    var queryVar = [];

    inputVar.dosers.forEach(async function (item) {
      queryVar.push(
        `{doserTypeId:` +
          item.doserTypeId +
          `,
      accountId:` +
          item.accountId +
          `,measuredFlow:` +
          item.measuredFlow +
          `, serialNum:"` +
          item.serialNum +
          `"}`
      );
    });

    if (queryVar.length > 0) {
      queryUp =
        `mutation createDosers {
      createDosers(input:[` +
        queryVar +
        `]) {
        dosers{
          id
          serialNum
          farmSet{id name}
          accountId {id}
        }
      }
    }
    `;

      await axios
        .post(
          myHttp,
          {
            query: queryUp,
          },
          {
            headers: {
              Authorization: 'JWT ' + inputVar.tokens.tokenIM,
            },
          }
        )
        .then((res) => {
          response = res;
        })
        .catch((error) => {
          response = error;
        });
    }

    var result = {
      type: null,
      response: null,
      query: 'createDosers',
    };
    result.type = 'v';
    if (response.data) {
      if (response.data.errors && response.data.errors.length > 0) {
        result.response = response.data.errors[0].message;
        result.type = 'e';
      } else {
        if (response.data.data.createDosers) {
          result.type = 's';

          result.response = response.data.data.createDosers;
        }
      }
    } else {
      result.type = 'e';
      result.response = response;
    }
    return result;
  },

  async updateDosers({ commit }, inputVar) {
    var queryUp = '';
    var response = '';
    var queryVar = [];

    inputVar.dosers.forEach(async function (item) {
      queryVar.push(
        `{doserId:` +
          item.doserId +
          `doserTypeId:` +
          item.doserTypeId +
          `,accountId:` +
          item.accountId +
          `,measuredFlow:` +
          item.measuredFlow +
          `, serialNum:"` +
          item.serialNum +
          `"}`
      );
    });

    if (queryVar.length > 0) {
      queryUp =
        `mutation updateDosers {
  updDosers(input:[` +
        queryVar +
        `]) {
    response {
      id
      serialNum
      doserTypeId{id doserModel}
      accountId{id}

      }
   }
 }
 `;

      await axios
        .post(
          myHttp,
          {
            query: queryUp,
          },
          {
            headers: {
              Authorization: 'JWT ' + inputVar.tokens.tokenIM,
            },
          }
        )
        .then((res) => {
          response = res;
        })
        .catch((error) => {
          response = error;
        });
    }

    var result = {
      type: null,
      response: null,
      query: 'updateDosers',
    };
    result.type = 'v';
    if (response.data) {
      if (response.data.errors && response.data.errors.length > 0) {
        result.response = response.data.errors[0].message;
        result.type = 'e';
      } else {
        if (response.data.data.updDosers) {
          result.type = 's';

          result.response = response.data.data.updDosers;
        }
      }
    } else {
      result.type = 'e';
      result.response = response;
    }
    return result;
  },
  async delDosers({ commit }, inputVar) {
    var queryUp = '';
    var response = '';
    var queryVar = [];
    inputVar.dosers.forEach(async function (item) {
      queryVar.push(item.doserId + `,`);
    });

    if (queryVar.length > 0) {
      queryUp =
        `mutation delDosers {
delDosers(ids:[` +
        queryVar +
        `]) {
  response {
    id

  }
 }
}
`;

      await axios
        .post(
          myHttp,
          {
            query: queryUp,
          },
          {
            headers: {
              Authorization: 'JWT ' + inputVar.tokens.tokenIM,
            },
          }
        )
        .then((res) => {
          response = res;
        })
        .catch((error) => {
          response = error;
        });
    }

    var result = {
      type: null,
      response: null,
      query: 'delDosers',
    };
    result.type = 'v';
    if (response.data) {
      if (response.data.errors && response.data.errors.length > 0) {
        result.response = response.data.errors[0].message;
        result.type = 'e';
      } else {
        if (response.data.data.delDosers) {
          result.type = 's';

          result.response = response.data.data.delDosers;
        }
      }
    } else {
      result.type = 'e';
      result.response = response;
    }
    return result;
  },
  async createParcels({ commit }, inputVar) {
    var queryUp = '';
    var response = '';
    var queryVar = [];
    inputVar.parcels.forEach(async function (item) {
      if (item.parcelTypeId.id === null) item.parcelTypeId.id = -1;
      if (item.cultivarTypeId.id === null) item.cultivarTypeId.id = -1;
      if (item.cultivarVarietyId.id === null) item.cultivarVarietyId.id = -1;
      if (item.soilTypeId.id === null) item.soilTypeId.id = -1;
      if (item.plantSpacingId.id === null) item.plantSpacingId.id = -1;
      if (item.irrigationSpacingId.id === null) item.irrigationSpacingId.id = -1;
      if (item.area === null) item.area = -1;

      const plantSpacingLine =
        item.plantSpacingId.plantLineSpacing === null || item.plantSpacingId.plantLineSpacing === ''
          ? ``
          : ` plantSpacingLine: "${item.plantSpacingId.plantLineSpacing}" ,`;
      const plantSpacing =
        item.plantSpacingId.plantSpacing === null || item.plantSpacingId.plantSpacing === ''
          ? ``
          : ` plantSpacing: "${item.plantSpacingId.plantSpacing}" ,`;
      const irrigLinesPerPlantLine =
        item.irrigationSpacingId.linesPerPlantLine === null || item.irrigationSpacingId.linesPerPlantLine === ''
          ? ``
          : ` irrigLinesPerPlantLine: ${item.irrigationSpacingId.linesPerPlantLine} ,`;
      const irrigDripFlow =
        item.irrigationSpacingId.dripFlow === null || item.irrigationSpacingId.dripFlow === ''
          ? ``
          : ` irrigDripFlow: "${item.irrigationSpacingId.dripFlow}" ,`;
      const irrigDripSpacing =
        item.irrigationSpacingId.dripSpacing === null || item.irrigationSpacingId.dripSpacing === ''
          ? ``
          : ` irrigDripSpacing: "${item.irrigationSpacingId.dripSpacing}" ,`;
      const area = item.area === null || item.area === '' ? `` : ` area: "${item.area}" ,`;
      const mapRef = item.mapRef === null || item.mapRef === '' ? `` : ` mapRef: "${item.mapRef}" ,`;
      queryVar.push(
        `{sectorId:` +
          item.sectorId +
          `,
        name:"` +
          item.name +
          `",
        parcelTypeId:` +
          item.parcelTypeId.id +
          `,
        cultivarTypeId:` +
          item.cultivarTypeId.id +
          `,
        cultivarVarietyId:` +
          item.cultivarVarietyId.id +
          `,
        soilTypeId:` +
          item.soilTypeId.id +
          `,
          ${plantSpacingLine}
          ${plantSpacing}
          ${irrigLinesPerPlantLine}
          ${irrigDripFlow}
          ${irrigDripSpacing}
          ${area}
          ${mapRef}
      }`
      );
    });
    if (queryVar.length > 0) {
      queryUp =
        `mutation create_parcels {
      createParcels(parcels:[` +
        queryVar +
        `]) {
        parcelsOut {
          id
          sectorId {
            id
          }
          name
          parcelTypeId {
            id
          }
          cultivarTypeId {
            id
          }
          cultivarVarietyId {
            id
          }
          soilTypeId {
            id
          }
          plantSpacingId {
            id
          }
          irrigationSpacingId {
            id
          }
          area
          calculatedFlow
        }
      }
    }
    `;

      await axios
        .post(
          myHttp,
          {
            query: queryUp,
          },
          {
            headers: {
              Authorization: 'JWT ' + inputVar.tokens.tokenIM,
            },
          }
        )
        .then((res) => {
          response = res;
        })
        .catch((error) => {
          response = error;
        });
    }
    var result = {
      type: null,
      response: null,
      query: 'createParcels',
    };
    result.type = 'v';
    if (response.data) {
      if (response.data.errors && response.data.errors.length > 0) {
        result.response = response.data.errors[0].message;
        result.type = 'e';
      } else {
        if (response.data.data.createParcels) {
          result.type = 's';

          result.response = response.data.data.createParcels;
        }
      }
    } else {
      result.type = 'e';
      result.response = response;
    }
    return result;
  },
  async updateParcels({ commit }, inputVar) {
    var queryUp = '';
    var response = '';
    var queryVar = [];
    inputVar.parcels.forEach(async function (item) {
      if (item.parcelTypeId.id === null) item.parcelTypeId.id = -1;
      if (item.cultivarTypeId.id === null) item.cultivarTypeId.id = -1;
      if (item.cultivarVarietyId.id === null) item.cultivarVarietyId.id = -1;
      if (item.soilTypeId.id === null) item.soilTypeId.id = -1;
      if (item.plantSpacingId.id === null) item.plantSpacingId.id = -1;
      if (item.irrigationSpacingId.id === null) item.irrigationSpacingId.id = -1;
      if (item.area === null) item.area = -1;

      const plantSpacingLine =
        item.plantSpacingId.plantLineSpacing === null || item.plantSpacingId.plantLineSpacing === ''
          ? ``
          : ` plantSpacingLine: "${item.plantSpacingId.plantLineSpacing}" ,`;
      const plantSpacing =
        item.plantSpacingId.plantSpacing === null || item.plantSpacingId.plantSpacing === ''
          ? ``
          : ` plantSpacing: "${item.plantSpacingId.plantSpacing}" ,`;
      const irrigLinesPerPlantLine =
        item.irrigationSpacingId.linesPerPlantLine === null || item.irrigationSpacingId.linesPerPlantLine === ''
          ? ``
          : ` irrigLinesPerPlantLine: ${item.irrigationSpacingId.linesPerPlantLine} ,`;
      const irrigDripFlow =
        item.irrigationSpacingId.dripFlow === null || item.irrigationSpacingId.dripFlow === ''
          ? ``
          : ` irrigDripFlow: "${item.irrigationSpacingId.dripFlow}" ,`;
      const irrigDripSpacing =
        item.irrigationSpacingId.dripSpacing === null || item.irrigationSpacingId.dripSpacing === ''
          ? ``
          : ` irrigDripSpacing: "${item.irrigationSpacingId.dripSpacing}" ,`;
      const area = item.area === null || item.area === '' ? `` : ` area: "${item.area}" ,`;
      const mapRef = item.mapRef === null || item.mapRef === '' ? `` : ` mapRef: "${item.mapRef}" ,`;

      queryVar.push(
        `{parcelId:` +
          item.id +
          `,
        name:"` +
          item.name +
          `",
        parcelTypeId:` +
          item.parcelTypeId.id +
          `,
        cultivarTypeId:` +
          item.cultivarTypeId.id +
          `,
        cultivarVarietyId:` +
          item.cultivarVarietyId.id +
          `,
        soilTypeId:` +
          item.soilTypeId.id +
          `,
          ${plantSpacingLine}
          ${plantSpacing}
          ${irrigLinesPerPlantLine}
          ${irrigDripFlow}
          ${irrigDripSpacing}
          ${area}
          ${mapRef}
          }`
      );
    });
    if (queryVar.length > 0) {
      queryUp =
        `mutation upd_parcels {
      updParcels(parcels:[` +
        queryVar +
        `]) {
        parcelsOut {
          id
          sectorId {
            id
          }
          name
          parcelTypeId {
            id
          }
          cultivarTypeId {
            id
          }
          cultivarVarietyId {
            id
          }
          soilTypeId {
            id
          }
          plantSpacingId {
            id
          }
          irrigationSpacingId {
            id
          }
          area
          calculatedFlow
        }
      }
    }
    `;

      await axios
        .post(
          myHttp,
          {
            query: queryUp,
          },
          {
            headers: {
              Authorization: 'JWT ' + inputVar.tokens.tokenIM,
            },
          }
        )
        .then((res) => {
          response = res;
        })
        .catch((error) => {
          response = error;
        });
    }
    var result = {
      type: null,
      response: null,
      query: 'updateParcels',
    };
    result.type = 'v';
    if (response.data) {
      if (response.data.errors && response.data.errors.length > 0) {
        result.response = response.data.errors[0].message;
        result.type = 'e';
      } else {
        if (response.data.data.updParcels) {
          result.type = 's';

          result.response = response.data.data.updParcels;
        }
      }
    } else {
      result.type = 'e';
      result.response = response;
    }
    return result;
  },
  async delParcels({ commit }, inputVar) {
    var queryUp = '';
    var response = '';
    var queryVar = [];
    inputVar.parcels.forEach(async function (item) {
      queryVar.push(item.id + `,`);
    });

    if (queryVar.length > 0) {
      queryUp =
        `mutation delParcel {
     delParcels(ids:[` +
        queryVar +
        `]) {
       response {
         id

       }
      }
    }
    `;
      await axios
        .post(
          myHttp,
          {
            query: queryUp,
          },
          {
            headers: {
              Authorization: 'JWT ' + inputVar.tokens.tokenIM,
            },
          }
        )
        .then((res) => {
          response = res;
        })
        .catch((error) => {
          response = error;
        });
    }

    var result = {
      type: null,
      response: null,
      query: 'delParcels',
    };
    result.type = 'v';
    if (response.data) {
      if (response.data.errors && response.data.errors.length > 0) {
        result.response = response.data.errors[0].message;
        result.type = 'e';
      } else {
        if (response.data.data.delParcels) {
          result.type = 's';

          result.response = response.data.data.delParcels;
        }
      }
    } else {
      result.type = 'e';
      result.response = response;
    }
    return result;
  },

  async getDashRegFert({ commit }, inputVar) {
    var response = '';

    var queryDashReyFert = `query dash_reg_fert {
      dashRegFert(selectTab: ${inputVar.selectTab},
                  accountid: ${inputVar.accountId}, 
                  dateSt: "${inputVar.dateSt}", 
                  dateEd: "${inputVar.dateEd}") {
        farmid
        name
        date
        label
        groupname
        area`;

    if (inputVar.selectTab == 0) {
      // Water
      queryDashReyFert += `,
        m3
        m3haApplied
        m3haPlan
        deviation
          }
        }`;
    } else {
      // Fert
      queryDashReyFert += `,
      nameFert
      volumeApplied
      volumeProgrammed
      totalLitersApplied
      n
      p
      k
      ca
      mg
    }
  }`;
    }

    await axios
      .post(
        myHttp,
        {
          query: queryDashReyFert,
        },
        {
          headers: {
            Authorization: 'JWT ' + inputVar.tokens.tokenIM,
          },
        }
      )
      .then((res) => {
        response = res;
      })
      .catch((error) => {
        response = error;
      });

    var result = {
      type: null,
      response: null,
      query: 'getDashRegFert',
    };
    result.type = 'v';
    if (response.data) {
      if (response.data.errors && response.data.errors.length > 0) {
        result.response = response.data.errors[0].message;
        result.type = 'e';
      } else {
        if (response.data.data.dashRegFert) {
          result.type = 's';
          commit('setDashRegFert', response.data.data.dashRegFert);
          result.response = response.data.data.dashRegFert;
        }
      }
    } else {
      result.type = 'e';
      result.response = response;
    }

    return result;
  },

  async getDashFert1({ commit }, inputVar) {
    var response = '';
    /* Rate */
    var a =
      `query dash_fert1 {
      dashFert1(farmId: ` +
      inputVar.farmId +
      `year:` +
      inputVar.year +
      ` isOriginal: ` +
      inputVar.isOriginal +
      `) {
            areas {
              sg
              sec
              par
              area
            }
            units {
              sg
              sec
              par
              prod
              date
              vPlan
              nPlan
              pPlan
              kPlan
              caPlan
              vReal
              nReal
              pReal
              kReal
              caReal
            }
          }
        }

  `;

    await axios
      .post(
        myHttp,
        {
          query: a,
        },
        {
          headers: {
            Authorization: 'JWT ' + inputVar.tokens.tokenIM,
          },
        }
      )
      .then((res) => {
        response = res;
      })
      .catch((error) => {
        response = error;
      });

    var result = {
      type: null,
      response: null,
      query: 'getDashFert1',
    };
    result.type = 'v';
    if (response.data) {
      if (response.data.errors && response.data.errors.length > 0) {
        result.response = response.data.errors[0].message;
        result.type = 'e';
      } else {
        if (response.data.data.dashFert1) {
          result.type = 's';
          commit('setDashFert1', response.data.data.dashFert1);
          result.response = response.data.data.dashFert1;
        }
      }
    } else {
      result.type = 'e';
      result.response = response;
    }
    return result;
  },
  async getDashFert2({ commit }, inputVar) {
    var response = '';
    /* Rate */

    var a =
      `query dash_fert2 {
    dashFert2  ( year:` +
      inputVar.year +
      `, isOriginal: ` +
      inputVar.isOriginal +
      `){
      farmId
      name
      label
      volPlan
      volReal
      nPlan
      nReal
      pPlan
      pReal
      kPlan
      kReal
      caPlan
      caReal
      latestWeek
      planLastWeek
      planTotVol
      area
    }
  }

  `;

    await axios
      .post(
        myHttp,
        {
          query: a,
        },
        {
          headers: {
            Authorization: 'JWT ' + inputVar.tokens.tokenIM,
          },
        }
      )
      .then((res) => {
        response = res;
      })
      .catch((error) => {
        response = error;
      });

    var result = {
      type: null,
      response: null,
      query: 'getDashFert2',
    };
    result.type = 'v';
    if (response.data) {
      if (response.data.errors && response.data.errors.length > 0) {
        result.response = response.data.errors[0].message;
        result.type = 'e';
      } else {
        if (response.data.data.dashFert2) {
          result.type = 's';
          commit('setDashFert2', response.data.data.dashFert2);
          result.response = response.data.data.dashFert2;
        }
      }
    } else {
      result.type = 'e';
      result.response = response;
    }

    return result;
  },
  async getDashFert3({ commit }, inputVar) {
    var response = '';

    var a =
      `query dash_fert3 {
  dashFert3(year:` +
      inputVar.year +
      `) {
    farmId
    name
    label
    date
    fertName
    volReq
    volReal
  }
}

  `;

    await axios
      .post(
        myHttp,
        {
          query: a,
        },
        {
          headers: {
            Authorization: 'JWT ' + inputVar.tokens.tokenIM,
          },
        }
      )
      .then((res) => {
        response = res;
      })
      .catch((error) => {
        response = error;
      });

    var result = {
      type: null,
      response: null,
      query: 'getDashFert3',
    };
    result.type = 'v';
    if (response.data) {
      if (response.data.errors && response.data.errors.length > 0) {
        result.response = response.data.errors[0].message;
        result.type = 'e';
      } else {
        if (response.data.data.dashFert3) {
          result.type = 's';
          commit('setDashFert3', response.data.data.dashFert3);
          result.response = response.data.data.dashFert3;
        }
      }
    } else {
      result.type = 'e';
      result.response = response;
    }

    return result;
  },
  async getDashWater1({ commit }, inputVar) {
    var response = '';
    /* Rate */
    var year = inputVar.year;
    var a =
      `query dash_water1 {
        dashWat1(farmId: ` +
      inputVar.farmId +
      `year:` +
      year +
      `) {
          areas {
            sg
            sec
            par
            area
          }
          units {
            sg
            sec
            par
            date
            volPlan
            volReal
            volReq
          }
        }
      }

  `;

    await axios
      .post(
        myHttp,
        {
          query: a,
        },
        {
          headers: {
            Authorization: 'JWT ' + inputVar.tokens.tokenIM,
          },
        }
      )
      .then((res) => {
        response = res;
      })
      .catch((error) => {
        response = error;
      });

    var result = {
      type: null,
      response: null,
      query: 'getDashWater1',
    };
    result.type = 'v';
    if (response.data) {
      if (response.data.errors && response.data.errors.length > 0) {
        result.response = response.data.errors[0].message;
        result.type = 'e';
      } else {
        if (response.data.data.dashWat1) {
          result.type = 's';
          commit('setDashWater1', response.data.data.dashWat1);
          result.response = response.data.data.dashWat1;
        }
      }
    } else {
      result.type = 'e';
      result.response = response;
    }
    return result;
  },
  async getDashWater2({ commit }, inputVar) {
    var response = '';

    var a =
      `query dash_water2  {
    dashWat2 ( year:` +
      inputVar.year +
      `) {
      farmId
      name
      label
      volPlan
      volReal
      latestWeek
      planLastWeek
      planTotVol
      area
    }
  }

  `;

    await axios
      .post(
        myHttp,
        {
          query: a,
        },
        {
          headers: {
            Authorization: 'JWT ' + inputVar.tokens.tokenIM,
          },
        }
      )
      .then((res) => {
        response = res;
      })
      .catch((error) => {
        response = error;
      });

    var result = {
      type: null,
      response: null,
      query: 'getDashWater2',
    };
    result.type = 'v';
    if (response.data) {
      if (response.data.errors && response.data.errors.length > 0) {
        result.response = response.data.errors[0].message;
        result.type = 'e';
      } else {
        if (response.data.data.dashWat2) {
          result.type = 's';
          commit('setDashWater2', response.data.data.dashWat2);
          result.response = response.data.data.dashWat2;
        }
      }
    } else {
      result.type = 'e';
      result.response = response;
    }

    return result;
  },
  async getDashWater3({ commit }, inputVar) {
    var response = '';

    var a =
      `query dash_water3  {
    dashWat3 ( year:` +
      inputVar.year +
      `) {
      farmId
      name
      deviation
      label
    }
  }
  `;

    await axios
      .post(
        myHttp,
        {
          query: a,
        },
        {
          headers: {
            Authorization: 'JWT ' + inputVar.tokens.tokenIM,
          },
        }
      )
      .then((res) => {
        response = res;
      })
      .catch((error) => {
        response = error;
      });

    var result = {
      type: null,
      response: null,
      query: 'getDashWater3',
    };
    result.type = 'v';
    if (response.data) {
      if (response.data.errors && response.data.errors.length > 0) {
        result.response = response.data.errors[0].message;
        result.type = 'e';
      } else {
        if (response.data.data.dashWat3) {
          result.type = 's';
          commit('setDashWater3', response.data.data.dashWat3);
          result.response = response.data.data.dashWat3;
        }
      }
    } else {
      result.type = 'e';
      result.response = response;
    }

    return result;
  },
  async getDashWater4({ commit }, inputVar) {
    var response = '';

    var a =
      `query dash_water4  {
    dashWat4 ( farmId: ` +
      inputVar.farmId +
      `year:` +
      inputVar.year +
      `) {
      farmId
    name
    sectorName
    sectorGroupId
    date
    deviation
    }
  }
  `;

    await axios
      .post(
        myHttp,
        {
          query: a,
        },
        {
          headers: {
            Authorization: 'JWT ' + inputVar.tokens.tokenIM,
          },
        }
      )
      .then((res) => {
        response = res;
      })
      .catch((error) => {
        response = error;
      });

    var result = {
      type: null,
      response: null,
      query: 'getDashWater4',
    };
    result.type = 'v';
    if (response.data) {
      if (response.data.errors && response.data.errors.length > 0) {
        result.response = response.data.errors[0].message;
        result.type = 'e';
      } else {
        if (response.data.data.dashWat4) {
          result.type = 's';
          commit('setDashWater4', response.data.data.dashWat4);
          result.response = response.data.data.dashWat4;
        }
      }
    } else {
      result.type = 'e';
      result.response = response;
    }

    return result;
  },
  async getDashWater5({ commit }, inputVar) {
    var response = '';

    var a =
      `query dash_water5 {
  dashWat5(year:` +
      inputVar.year +
      `) {
    farmId
    name
    date
    label
    volReq
    volReal
  }
}
  `;

    await axios
      .post(
        myHttp,
        {
          query: a,
        },
        {
          headers: {
            Authorization: 'JWT ' + inputVar.tokens.tokenIM,
          },
        }
      )
      .then((res) => {
        response = res;
      })
      .catch((error) => {
        response = error;
      });

    var result = {
      type: null,
      response: null,
      query: 'getDashWater5',
    };
    result.type = 'v';
    if (response.data) {
      if (response.data.errors && response.data.errors.length > 0) {
        result.response = response.data.errors[0].message;
        result.type = 'e';
      } else {
        if (response.data.data.dashWat5) {
          result.type = 's';
          commit('setDashWater5', response.data.data.dashWat5);
          result.response = response.data.data.dashWat5;
        }
      }
    } else {
      result.type = 'e';
      result.response = response;
    }

    return result;
  },
  async createFertRef({ commit }, inputVar) {
    var queryUp = '';
    var response = '';
    var queryVar = [];

    inputVar.fertilizers.forEach(async function (item) {
      item.efficiency = item.efficiency / 100;
      item.planDownLmt = item.planDownLmt / 100;
      item.planUpLmt = item.planUpLmt / 100;
      item.concentrLmt = item.concentrLmt / 1000;
      queryVar.push(
        `{N: "${item.N}", 
        P: "${item.P}", 
        K: "${item.K}", 
        Ca: "${item.Ca}", 
        Ng: "${item.Ng}", 
        density: "${item.density}", 
        name: "${item.name}" , 
        concentrLmt: "${item.concentrLmt}" , 
        planUpLmt: "${item.planUpLmt}" , 
        planDownLmt: "${item.planDownLmt}" , 
        efficiency: "${item.efficiency}" , 
        solid: ${item.solid} , 
        active: ${item.active}}`
      );
    });

    if (queryVar.length > 0) {
      queryUp =
        `mutation createFertRef {
      createFertRef(fertrefs:[` +
        queryVar +
        `]) {
        out {
          id
          N
          P
          K
          Ca
          Ng
          name
          concentrLmt
          density
          planUpLmt
          planDownLmt
        }
      }
    }
    `;

      await axios
        .post(
          myHttp,
          {
            query: queryUp,
          },
          {
            headers: {
              Authorization: 'JWT ' + inputVar.tokens.tokenIM,
            },
          }
        )
        .then((res) => {
          response = res;
        })
        .catch((error) => {
          response = error;
        });
    }

    var result = {
      type: null,
      response: null,
      query: 'createFertRef',
    };
    result.type = 'v';
    if (response.data) {
      if (response.data.errors && response.data.errors.length > 0) {
        result.response = response.data.errors[0].message;
        result.type = 'e';
      } else {
        if (response.data.data.createFertRef) {
          result.type = 's';

          result.response = response.data.data.createFertRef;
        }
      }
    } else {
      result.type = 'e';
      result.response = response;
    }
    return result;
  },

  async createFertPlanMassive({ commit }, inputVar) {
    var queryUp = '';
    var response = '';
    var queryVar = [];

    inputVar.fplansMassive.forEach(async function (item) {
      var fertrefIf = item.fertRefId.id === null ? `` : ` fertRefId: ${item.fertRefId.id} ,`;
      var name = item.name === null || item.name === '' ? `` : ` name: "${item.name}", `;
      var id = item.id === null ? `` : ` id: ${item.id}, `;
      var massQty = item.massQty === null || item.massQty === '' ? `` : ` massQty: "${item.massQty}", `;
      let weeks = [];
      Object.keys(item.weeks).forEach((mes) => {
        item.weeks[mes].forEach((semana) => {
          weeks.push(
            `{startDt: "${semana.startDt}", endDt: "${semana.endDt}", quantity: "${parseFloat(semana.quantity).toFixed(
              2
            )}", fertPlanMassiveId: ${semana.fertPlanMassiveId || 0}}`
          );
        });
      });
      queryVar.push(
        `{type: "${item.type}",
          startDt:"${item.startDt}",
          endDt: "${item.endDt}",
          ${massQty},
          ${name}
          ${fertrefIf}
          ${id}
         weeks: [${weeks.join(',')}]
        }`
      );
    });

    if (queryVar.length > 0) {
      queryUp = `mutation CreateFertPlanMassive {
          createFertPlanMassive(
            fplansMassive:[${queryVar}]) {
            fplansOutMassive {
              id
              name
              type
              startDt
              endDt
              massQty
              fertRefId {
                id
                name
              }
              accountId {
                id
              }
            }
            fplansOutMassiveWeekDetail{
              id
              endDt
              startDt
              quantity
            }
          }
        }
    `;

      await axios
        .post(
          myHttp,
          {
            query: queryUp,
          },
          {
            headers: {
              Authorization: 'JWT ' + inputVar.tokens.tokenIM,
            },
          }
        )
        .then((res) => {
          response = res;
        })
        .catch((error) => {
          response = error;
        });
    }

    var result = {
      type: null,
      response: null,
      query: 'createFertPlanMassive',
    };
    result.type = 'v';
    if (response.data) {
      if (response.data.errors && response.data.errors.length > 0) {
        result.response = response.data.errors[0].message;
        result.type = 'e';
      } else {
        if (response.data.data.createFertPlanMassive) {
          result.type = 's';
          result.response = response.data.data.createFertPlanMassive;
        }
      }
    } else {
      result.type = 'e';
      result.response = response;
    }
    return result;
  },
  async deleteFertPlanDetailSecrGrp({ commit }, inputVar) {
    var queryUp = '';
    var response = '';
    var queryVar = [];

    inputVar.deleteScrGrpFertPlan.forEach(async function (item) {
      queryVar.push(
        `{sectorGroupId:${item.sectorGroupId},
          fertPlanId:${item.fertPlanId},
        }`
      );
    });

    if (queryVar.length > 0) {
      queryUp = `mutation DeleteFertPlanDetailSecrGrp {
                  deleteFertPlanDetailSecrGrp(
                    fplanDetail:[${queryVar}]) {
                    out
                  }
                }`;

      await axios
        .post(
          myHttp,
          {
            query: queryUp,
          },
          {
            headers: {
              Authorization: 'JWT ' + inputVar.tokens.tokenIM,
            },
          }
        )
        .then((res) => {
          response = res;
        })
        .catch((error) => {
          response = error;
        });
    }

    var result = {
      type: null,
      response: null,
      query: 'deleteFertPlanDetailSecrGrp',
    };
    result.type = 'v';
    if (response.data) {
      if (response.data.errors && response.data.errors.length > 0) {
        result.response = response.data.errors[0].message;
        result.type = 'e';
      } else {
        if (response.data.data.deleteFertPlanDetailSecrGrp) {
          result.type = 's';
          result.response = response.data.data.deleteFertPlanDetailSecrGrp;
        }
      }
    } else {
      result.type = 'e';
      result.response = response;
    }
    return result;
  },
  async createFertPlanDetailSecrGrp({ commit }, inputVar) {
    var queryUp = '';
    var response = '';
    var queryVar = [];

    inputVar.saveScrGrpFertPlan.forEach(async function (item) {
      queryVar.push(
        `{sectorGroupId:${item.sectorGroupId},
          fertPlanId:${item.fertPlanId},
        }`
      );
    });

    if (queryVar.length > 0) {
      queryUp = ` mutation CreateFertPlanDetailSecrGrp {
                      createFertPlanDetailSecrGrp(
                        fplanDetail:[${queryVar}]) {
                            out {
                              sectorGroupId {
                                id
                              }
                              fertPlanId{
                                id
                              }
                            }
                          }
                        }`;

      await axios
        .post(
          myHttp,
          {
            query: queryUp,
          },
          {
            headers: {
              Authorization: 'JWT ' + inputVar.tokens.tokenIM,
            },
          }
        )
        .then((res) => {
          response = res;
        })
        .catch((error) => {
          response = error;
        });
    }

    var result = {
      type: null,
      response: null,
      query: 'createFertPlanDetailSecrGrp',
    };
    result.type = 'v';
    if (response.data) {
      if (response.data.errors && response.data.errors.length > 0) {
        result.response = response.data.errors[0].message;
        result.type = 'e';
      } else {
        if (response.data.data.createFertPlanDetailSecrGrp) {
          result.type = 's';
          result.response = response.data.data.createFertPlanDetailSecrGrp;
        }
      }
    } else {
      result.type = 'e';
      result.response = response;
    }
    return result;
  },
  async deleteFarmsMassivePlan({ commit }, inputVar) {
    var queryUp = '';
    var response = '';

    queryUp = `mutation DeleteFarmsMassivePlan {
                  deleteFarmsMassivePlan(
                    farmIds:[${inputVar.deletedFarmFertPlan}]
                    fertPlanMassiveId:${inputVar.id}) {
                    out
                  }
                }`;

    await axios
      .post(
        myHttp,
        {
          query: queryUp,
        },
        {
          headers: {
            Authorization: 'JWT ' + inputVar.tokens.tokenIM,
          },
        }
      )
      .then((res) => {
        response = res;
      })
      .catch((error) => {
        response = error;
      });

    var result = {
      type: null,
      response: null,
      query: 'deleteFarmsMassivePlan',
    };
    result.type = 'v';
    if (response.data) {
      if (response.data.errors && response.data.errors.length > 0) {
        result.response = response.data.errors[0].message;
        result.type = 'e';
      } else {
        if (response.data.data.deleteFarmsMassivePlan) {
          result.type = 's';
          result.response = response.data.data.deleteFarmsMassivePlan;
        }
      }
    } else {
      result.type = 'e';
      result.response = response;
    }
    return result;
  },
  async updFertRef({ commit }, inputVar) {
    var queryUp = '';
    var response = '';
    var queryVar = [];

    inputVar.fertilizers.forEach(async function (item) {
      item.efficiency = item.efficiency / 100;
      item.planDownLmt = item.planDownLmt / 100;
      item.planUpLmt = item.planUpLmt / 100;
      item.concentrLmt = item.concentrLmt / 1000;
      queryVar.push(
        `{fertRefId:  
          ${item.id}, 
          N: "${item.N}", 
          P: "${item.P}", 
          K: "${item.K}", 
          Ca: "${item.Ca}", 
          Ng: "${item.Ng}", 
          density: "${item.density}", 
          name: "${item.name}" , 
          concentrLmt: "${item.concentrLmt}" , 
          planUpLmt: "${item.planUpLmt}" , 
          planDownLmt: "${item.planDownLmt}" , 
          efficiency: "${item.efficiency}" , 
          solid: ${item.solid} , 
          active: ${item.active}}`
      );
    });

    if (queryVar.length > 0) {
      queryUp =
        `mutation updFertRef  {
  updFertRef(fertrefs:[` +
        queryVar +
        `]) {
    out {
      id
      N
      P
      K
      Ca
      Ng
      name
      concentrLmt
      density
      planUpLmt
      planDownLmt
    }
  }
}
 `;
      await axios
        .post(
          myHttp,
          {
            query: queryUp,
          },
          {
            headers: {
              Authorization: 'JWT ' + inputVar.tokens.tokenIM,
            },
          }
        )
        .then((res) => {
          response = res;
        })
        .catch((error) => {
          response = error;
        });
    }

    var result = {
      type: null,
      response: null,
      query: 'updFertRef',
    };
    result.type = 'v';
    if (response.data) {
      if (response.data.errors && response.data.errors.length > 0) {
        result.response = response.data.errors[0].message;
        result.type = 'e';
      } else {
        if (response.data.data.updFertRef) {
          result.type = 's';

          result.response = response.data.data.updFertRef;
        }
      }
    } else {
      result.type = 'e';
      result.response = response;
    }
    return result;
  },

  async delFertRef({ commit }, inputVar) {
    var queryUp = '';
    var response = '';
    var queryVar = [];
    inputVar.fertilizers.forEach(async function (item) {
      queryVar.push(item.id + `,`);
    });

    if (queryVar.length > 0) {
      queryUp =
        `mutation delFertRef {
  delFertRef(ids:[` +
        queryVar +
        `]) {
  response {
    id

  }
 }
}
`;
      await axios
        .post(
          myHttp,
          {
            query: queryUp,
          },
          {
            headers: {
              Authorization: 'JWT ' + inputVar.tokens.tokenIM,
            },
          }
        )
        .then((res) => {
          response = res;
        })
        .catch((error) => {
          response = error;
        });
    }

    var result = {
      type: null,
      response: null,
      query: 'delFertRef',
    };
    result.type = 'v';
    if (response.data) {
      if (response.data.errors && response.data.errors.length > 0) {
        result.response = response.data.errors[0].message;
        result.type = 'e';
      } else {
        if (response.data.data.delFertRef) {
          result.type = 's';

          result.response = response.data.data.delFertRef;
        }
      }
    } else {
      result.type = 'e';
      result.response = response;
    }
    return result;
  },
  async getFertRefs({ commit }, inputVar) {
    var response = '';
    await axios
      .post(
        myHttp,
        {
          query: `query fertRefs {
    fertRefs {
      id
      name
      N
      P
      K
      Ca
      Ng
      S
      concentrLmt
      density
      planUpLmt
      planDownLmt
      efficiency
      solid
      active
    }
  }

  `,
        },
        {
          headers: {
            Authorization: 'JWT ' + inputVar.tokens.tokenIM,
          },
        }
      )
      .then((res) => {
        response = res;
      })
      .catch((error) => {
        response = error;
      });

    var result = {
      type: null,
      response: null,
      query: 'getFertRefs',
    };
    result.type = 'v';
    if (response.data) {
      if (response.data.errors && response.data.errors.length > 0) {
        result.response = response.data.errors[0].message;
        result.type = 'e';
      } else {
        if (response.data.data.fertRefs) {
          result.type = 's';
          commit('setFertRefs', response.data.data.fertRefs);
          result.response = response.data.data.fertRefs;
        }
      }
    } else {
      result.type = 'e';
      result.response = response;
    }
    return result;
  },
  async getWPlanFarms({ commit }, inputVar) {
    var response = '';
    await axios
      .post(
        myHttp,
        {
          query: `query w_plan_farms {
    wPlanFarms {
      wPlanRefId
      name
      year
      details {
        date
        percent
      }
      farms {
        farmId
        volArea
      }
    }
  }

  `,
        },
        {
          headers: {
            Authorization: 'JWT ' + inputVar.tokens.tokenIM,
          },
        }
      )
      .then((res) => {
        response = res;
      })
      .catch((error) => {
        response = error;
      });

    var result = {
      type: null,
      response: null,
      query: 'getWPlanFarms',
    };
    result.type = 'v';
    if (response.data) {
      if (response.data.errors && response.data.errors.length > 0) {
        result.response = response.data.errors[0].message;
        result.type = 'e';
      } else {
        if (response.data.data.wPlanFarms) {
          result.type = 's';
          commit('setWPlanFarms', response.data.data.wPlanFarms);
          result.response = response.data.data.wPlanFarms;
        }
      }
    } else {
      result.type = 'e';
      result.response = response;
    }
    return result;
  },
  async createWPlan({ commit }, inputVar) {
    var queryUp = '';
    var response = '';
    var queryVar = [];
    queryVar.push(
      `name: "` +
        inputVar.wPlan.name +
        `" percents: [` +
        inputVar.wPlan.percents +
        `]
     `
    );

    if (queryVar) {
      queryUp =
        `mutation create_w_plan_ref {
      createWPlanRef(` +
        queryVar +
        `) {
        data {
          id
          name
          year
        }
        }
      }
    `;

      await axios
        .post(
          myHttp,
          {
            query: queryUp,
          },
          {
            headers: {
              Authorization: 'JWT ' + inputVar.tokens.tokenIM,
            },
          }
        )
        .then((res) => {
          response = res;
        })
        .catch((error) => {
          response = error;
        });
    }

    var result = {
      type: null,
      response: null,
      query: 'createWPlan',
    };
    result.type = 'v';
    if (response.data) {
      if (response.data.errors && response.data.errors.length > 0) {
        result.response = response.data.errors[0].message;
        result.type = 'e';
      } else {
        if (response.data.data.createWPlanRef) {
          result.type = 's';

          result.response = response.data.data.createWPlanRef;
        }
      }
    } else {
      result.type = 'e';
      result.response = response;
    }
    return result;
  },
  async updateWPlan({ commit }, inputVar) {
    var queryUp = '';
    var response = '';
    var queryVar = [];
    queryVar.push(
      `wPlanRefId:` +
        inputVar.wPlan.wPlanRefId +
        `,name: "` +
        inputVar.wPlan.name +
        `" percents: [` +
        inputVar.wPlan.percents +
        `]
     `
    );

    if (queryVar) {
      queryUp =
        `mutation upd_w_plan_ref {
      updWPlanRef(` +
        queryVar +
        `) {
        data {
          id
          name
          year
        }
        }
      }
    `;

      await axios
        .post(
          myHttp,
          {
            query: queryUp,
          },
          {
            headers: {
              Authorization: 'JWT ' + inputVar.tokens.tokenIM,
            },
          }
        )
        .then((res) => {
          response = res;
        })
        .catch((error) => {
          response = error;
        });
    }

    var result = {
      type: null,
      response: null,
      query: 'updateWPlan',
    };
    result.type = 'v';
    if (response.data) {
      if (response.data.errors && response.data.errors.length > 0) {
        result.response = response.data.errors[0].message;
        result.type = 'e';
      } else {
        if (response.data.data.updWPlanRef) {
          result.type = 's';

          result.response = response.data.data.updWPlanRef;
        }
      }
    } else {
      result.type = 'e';
      result.response = response;
    }
    return result;
  },
  async createWPlanFarm({ commit }, inputVar) {
    var queryUp = '';
    var response = '';
    var queryVar = [];

    inputVar.planFarm.forEach(async function (item) {
      queryVar.push(`{farmId: ` + item.farmId + `, wPlanRefId:` + item.wPlanRefId + `, volArea:` + item.volArea + `}`);
    });

    if (queryVar.length > 0) {
      queryUp =
        `mutation createWPlanFarm  {
    createWPlanFarm(fWPlans:[` +
        queryVar +
        `]) {
    ok
  }
}
 `;
      await axios
        .post(
          myHttp,
          {
            query: queryUp,
          },
          {
            headers: {
              Authorization: 'JWT ' + inputVar.tokens.tokenIM,
            },
          }
        )
        .then((res) => {
          response = res;
        })
        .catch((error) => {
          response = error;
        });
    }

    var result = {
      type: null,
      response: null,
      query: 'createWPlanFarm',
    };
    result.type = 'v';
    if (response.data) {
      if (response.data.errors && response.data.errors.length > 0) {
        result.response = response.data.errors[0].message;
        result.type = 'e';
      } else {
        if (response.data.data.createWPlanFarm) {
          result.type = 's';

          result.response = response.data.data.createWPlanFarm;
        }
      }
    } else {
      result.type = 'e';
      result.response = response;
    }
    return result;
  },
  async getSolids({ commit }, inputVar) {
    var response = '';
    await axios
      .post(
        myHttp,
        {
          query:
            `query get_applied_solids {
        appliedSolids(farmId:` +
            inputVar.farmId +
            `) {
          date
          fertRefId
          fertName
          fertMass
          ids
          sectors {
            sectorId
            sectorName
            fertMass
          }
        }
      }
      `,
        },
        {
          headers: {
            Authorization: 'JWT ' + inputVar.tokens.tokenIM,
          },
        }
      )
      .then((res) => {
        response = res;
      })
      .catch((error) => {
        response = error;
      });
    response.data.data.solid = [];
    var result = {
      type: null,
      response: null,
      query: 'getSolids',
    };
    result.type = 'v';
    if (response.data) {
      if (response.data.errors && response.data.errors.length > 0) {
        result.response = response.data.errors[0].message;
        result.type = 'e';
      } else {
        if (response.data.data.appliedSolids) {
          result.type = 's';
          commit('setSolids', response.data.data.appliedSolids);
          result.response = response.data.data.appliedSolids;
        }
      }
    } else {
      result.type = 'e';
      result.response = response;
    }
    return result;
  },
  async createSolids({ commit }, inputVar) {
    var queryUp = '';
    var response = '';
    var queryVar = [];

    queryVar.push(
      `farmId: ` +
        inputVar.solids.farmId +
        `, fertRefId:` +
        inputVar.solids.fertRefId +
        `, date:"` +
        inputVar.solids.date +
        `", fertMass:` +
        inputVar.solids.fertMass +
        `, sectors: [` +
        inputVar.solids.sectors +
        `]
     `
    );
    if (queryVar.length > 0) {
      queryUp =
        `mutation create_applied_solid {
        createAppliedSolid(` +
        queryVar +
        `) {
          out
          }
        }

    `;

      await axios
        .post(
          myHttp,
          {
            query: queryUp,
          },
          {
            headers: {
              Authorization: 'JWT ' + inputVar.tokens.tokenIM,
            },
          }
        )
        .then((res) => {
          response = res;
        })
        .catch((error) => {
          response = error;
        });
    }

    var result = {
      type: null,
      response: null,
      query: 'createSolids',
    };

    result.type = 'v';
    if (response.data) {
      if (response.data.errors && response.data.errors.length > 0) {
        result.response = response.data.errors[0].message;
        result.type = 'e';
      } else {
        if (response.data.data.createAppliedSolid) {
          result.type = 's';

          result.response = response.data.data.createAppliedSolid;
        }
      }
    } else {
      result.type = 'e';
      result.response = response;
    }

    return result;
  },
  async delSolids({ commit }, inputVar) {
    var queryUp = '';
    var response = '';
    var queryVar = [];
    inputVar.solids.forEach(async function (item) {
      queryVar.push(item.ids + `,`);
    });

    if (queryVar.length > 0) {
      queryUp =
        `mutation delAppliedSolid {
  delAppliedSolid(ids:[` +
        queryVar +
        `]) {
  out
 }
}
`;

      await axios
        .post(
          myHttp,
          {
            query: queryUp,
          },
          {
            headers: {
              Authorization: 'JWT ' + inputVar.tokens.tokenIM,
            },
          }
        )
        .then((res) => {
          response = res;
        })
        .catch((error) => {
          response = error;
        });
    }

    var result = {
      type: null,
      response: null,
      query: 'delAppliedSolid',
    };
    result.type = 'v';
    if (response.data) {
      if (response.data.errors && response.data.errors.length > 0) {
        result.response = response.data.errors[0].message;
        result.type = 'e';
      } else {
        if (response.data.data.delAppliedSolid) {
          result.type = 's';

          result.response = response.data.data.delAppliedSolid;
        }
      }
    } else {
      result.type = 'e';
      result.response = response;
    }
    return result;
  },
  async getAllSupplierProd({ commit }, inputVar) {
    var response = '';
    await axios
      .post(
        myHttp,
        {
          query:
            `query get_applied_solids {
        appliedSolids(farmId:` +
            1 +
            `) {
          date
          fertRefId
          fertName
          fertMass
          ids
          sectors {
            sectorId
            sectorName
            fertMass
          }
        }
      }
      `,
        },
        {
          headers: {
            Authorization: 'JWT ' + inputVar.tokens.tokenIM,
          },
        }
      )
      .then((res) => {
        response = res;
      })
      .catch((error) => {
        response = error;
      });
    response.data.data.solid = [];
    var result = {
      type: null,
      response: null,
      query: 'getAllSupplierProd',
    };
    result.type = 'v';
    if (response.data) {
      if (response.data.errors && response.data.errors.length > 0) {
        result.response = response.data.errors[0].message;
        result.type = 'e';
      } else {
        if (response.data.data.appliedSolids) {
          result.type = 's';
          commit('setAllSupplierProd', response.data.data.appliedSolids);
          result.response = response.data.data.appliedSolids;
        }
      }
    } else {
      result.type = 'e';
      result.response = response;
    }
    return result;
  },
  async getFertSupplier({ commit }, inputVar) {
    var response = '';
    await axios
      .post(
        myHttp,
        {
          query:
            `query get_fert_suppliers {
        fertSuppliers (farmId:` +
            inputVar.farmId +
            `) {
          id
          supplierId {
            id
            name
          }
          fertRefId {
            id
            name
          }
        }
      }
      `,
        },
        {
          headers: {
            Authorization: 'JWT ' + inputVar.tokens.tokenIM,
          },
        }
      )
      .then((res) => {
        response = res;
      })
      .catch((error) => {
        response = error;
      });

    var result = {
      type: null,
      response: null,
      query: 'getFertSupplier',
    };
    result.type = 'v';
    if (response.data) {
      if (response.data.errors && response.data.errors.length > 0) {
        result.response = response.data.errors[0].message;
        result.type = 'e';
      } else {
        if (response.data.data.fertSuppliers) {
          result.type = 's';
          commit('setFertSupplier', response.data.data.fertSuppliers);
          result.response = response.data.data.fertSuppliers;
        }
      }
    } else {
      result.type = 'e';
      result.response = response;
    }

    return result;
  },
  async createFertSupplier({ commit }, inputVar) {
    var queryUp = '';
    var response = '';
    var queryVar = [];
    inputVar.fertSupplier.forEach(async function (item) {
      queryVar.push(
        `{farmId: ` + inputVar.farmId + `, fertRefId:` + item.fertRefId.id + `, supplierId:` + item.supplierId.id + `}`
      );
    });
    if (queryVar.length > 0) {
      queryUp =
        `mutation createFertSuppliers {
        createFertSuppliers( input: [` +
        queryVar +
        `]) {
          fertSuppliers {
            id
            supplierId {
              id
              name
            }
            farmId {
              id
              name
            }
            fertRefId {
              id
              name
            }
          }
        }
      }

    `;

      await axios
        .post(
          myHttp,
          {
            query: queryUp,
          },
          {
            headers: {
              Authorization: 'JWT ' + inputVar.tokens.tokenIM,
            },
          }
        )
        .then((res) => {
          response = res;
        })
        .catch((error) => {
          response = error;
        });
    }

    var result = {
      type: null,
      response: null,
      query: 'createFertSupplier',
    };

    result.type = 'v';
    if (response.data) {
      if (response.data.errors && response.data.errors.length > 0) {
        result.response = response.data.errors[0].message;
        result.type = 'e';
      } else {
        if (response.data.data.createFertSuppliers) {
          result.type = 's';

          result.response = response.data.data.createFertSuppliers;
        }
      }
    } else {
      result.type = 'e';
      result.response = response;
    }
    return result;
  },
  async delFertSupplier({ commit }, inputVar) {
    var queryUp = '';
    var response = '';
    var queryVar = [];
    inputVar.fertSupplier.forEach(async function (item) {
      queryVar.push(item.id + `,`);
    });

    if (queryVar.length > 0) {
      queryUp =
        `mutation delFertSupplier {
  delFertSuppliers(ids:[` +
        queryVar +
        `]) {
    response {
      id
      supplierId {
        id
        name
      }
      farmId {
        id
        name
      }
      fertRefId {
        id
        name
      }
    }
  }
}
`;

      await axios
        .post(
          myHttp,
          {
            query: queryUp,
          },
          {
            headers: {
              Authorization: 'JWT ' + inputVar.tokens.tokenIM,
            },
          }
        )
        .then((res) => {
          response = res;
        })
        .catch((error) => {
          response = error;
        });
    }

    var result = {
      type: null,
      response: null,
      query: 'delFertSupplier',
    };
    result.type = 'v';
    if (response.data) {
      if (response.data.errors && response.data.errors.length > 0) {
        result.response = response.data.errors[0].message;
        result.type = 'e';
      } else {
        if (response.data.data.delFertSuppliers) {
          result.type = 's';

          result.response = response.data.data.delFertSuppliers;
        }
      }
    } else {
      result.type = 'e';
      result.response = response;
    }
    return result;
  },
  async getFertStocks({ commit }, inputVar) {
    var a =
      `query get_fert_stocks {
  fertStocks(date:"` +
      inputVar.date +
      `", supplierId:` +
      inputVar.supplierId +
      `) {
    fertRefId
    fertRefName
    farmId
    farmName
    maxQty
    totalWeeks
    header{
      headerName
      headerNumber
      id
    }
  }
}
`;

    var response = '';
    await axios
      .post(
        myHttp,
        {
          query: a,
        },
        {
          headers: {
            Authorization: 'JWT ' + inputVar.tokens.tokenIM,
          },
        }
      )
      .then((res) => {
        response = res;
      })
      .catch((error) => {
        response = error;
      });

    var result = {
      type: null,
      response: null,
      query: 'getFertStocks',
    };

    result.type = 'v';
    if (response.data) {
      if (response.data.errors && response.data.errors.length > 0) {
        result.response = response.data.errors[0].message;
        result.type = 'e';
      } else {
        if (response.data.data.fertStocks) {
          result.type = 's';
          commit('setSupplierProd', response.data.data.fertStocks);
          result.response = response.data.data.fertStocks;
        }
      }
    } else {
      result.type = 'e';
      result.response = response;
    }
    return result;
  },

  async getFertOrder({ commit }, inputVar) {
    var response = '';
    var a =
      `query get_fert_orders {
    fertOrders(year: ` +
      inputVar.year +
      `) {
      fertOrderId
      supplierId
      supplierName
      requestedDt
      deliveryDt
      details {
        load
        farmId
        fertRefId
        fertRefName
        farmName
        contactId
        contactName
        contactPhone
        vol
        header{
          id
          headerName
          headerNumber
        }
        corporation {
          corporationId
          corporationName
          corporationPercentage
        }
      }
    }
  }

  `;

    await axios
      .post(
        myHttp,
        {
          query: a,
        },
        {
          headers: {
            Authorization: 'JWT ' + inputVar.tokens.tokenIM,
          },
        }
      )
      .then((res) => {
        response = res;
      })
      .catch((error) => {
        response = error;
      });

    var result = {
      type: null,
      response: null,
      query: 'getFertOrder',
    };
    result.type = 'v';
    if (response.data) {
      if (response.data.errors && response.data.errors.length > 0) {
        result.response = response.data.errors[0].message;
        result.type = 'e';
      } else {
        if (response.data.data.fertOrders) {
          result.type = 's';

          commit('setFertOrders', response.data.data.fertOrders);
          result.response = response.data.data.fertOrders;
        }
      }
    } else {
      result.type = 'e';
      result.response = response;
    }
    return result;
  },
  async createFertOrder({ commit }, inputVar) {
    var queryUp = '';
    var response = '';
    var queryVar = [];

    queryVar.push(
      `supplierId: ` +
        inputVar.fertOrder.supplierId +
        `, deliveryDt:"` +
        inputVar.fertOrder.deliveryDt +
        `", details: [` +
        inputVar.fertOrder.details +
        `]
     `
    );

    if (queryVar.length > 0) {
      queryUp =
        `mutation create_fert_order {
        createFertOrder(` +
        queryVar +
        `) {
          forderOut {
            id
            supplierId {
              id
              name
            }
            requestedDt
            deliveryDt
          }
        }
      }

    `;

      await axios
        .post(
          myHttp,
          {
            query: queryUp,
          },
          {
            headers: {
              Authorization: 'JWT ' + inputVar.tokens.tokenIM,
            },
          }
        )
        .then((res) => {
          response = res;
        })
        .catch((error) => {
          response = error;
        });
    }

    var result = {
      type: null,
      response: null,
      query: 'createFertOrder',
    };

    result.type = 'v';
    if (response.data) {
      if (response.data.errors && response.data.errors.length > 0) {
        result.response = response.data.errors[0].message;
        result.type = 'e';
      } else {
        if (response.data.data.createFertOrder) {
          result.type = 's';

          result.response = response.data.data.createFertOrder;
        }
      }
    } else {
      result.type = 'e';
      result.response = response;
    }
    return result;
  },
  async delFertOrder({ commit }, inputVar) {
    var queryUp = '';
    var response = '';
    var queryVar = [];
    inputVar.fertOrder.forEach(async function (item) {
      queryVar.push(item.fertOrderId + `,`);
    });

    if (queryVar.length > 0) {
      queryUp =
        `mutation del_fert_orders {
  delFertOrders(ids:[` +
        queryVar +
        `]) {
    response
 }
}
`;

      await axios
        .post(
          myHttp,
          {
            query: queryUp,
          },
          {
            headers: {
              Authorization: 'JWT ' + inputVar.tokens.tokenIM,
            },
          }
        )
        .then((res) => {
          response = res;
        })
        .catch((error) => {
          response = error;
        });
    }

    var result = {
      type: null,
      response: null,
      query: 'delFertOrder',
    };
    result.type = 'v';
    if (response.data) {
      if (response.data.errors && response.data.errors.length > 0) {
        result.response = response.data.errors[0].message;
        result.type = 'e';
      } else {
        if (response.data.data.delFertOrders) {
          result.type = 's';

          result.response = response.data.data.delFertOrders;
        }
      }
    } else {
      result.type = 'e';
      result.response = response;
    }
    return result;
  },
  async getProgramProfileRef({ commit }, inputVar) {
    var response = '';
    var a =
      `query get_prog_prof_refs {
    progProfRefs(farmId:` +
      inputVar.farmId +
      `){
      id
      name

      controllerModel
      progFunction
      single

    }
  }
  `;

    await axios
      .post(
        myHttp,
        {
          query: a,
        },
        {
          headers: {
            Authorization: 'JWT ' + inputVar.tokens.tokenIM,
          },
        }
      )
      .then((res) => {
        response = res;
      })
      .catch((error) => {
        response = error;
      });

    var result = {
      type: null,
      response: null,
      query: 'getProgramProfileRef',
    };
    result.type = 'v';
    if (response.data) {
      if (response.data.errors && response.data.errors.length > 0) {
        result.response = response.data.errors[0].message;
        result.type = 'e';
      } else {
        if (response.data.data.progProfRefs) {
          result.type = 's';

          commit('setProgramProfileRef', response.data.data.progProfRefs);
          result.response = response.data.data.progProfRefs;
        }
      }
    } else {
      result.type = 'e';
      result.response = response;
    }
    return result;
  },
  async getAlarms({ commit }, inputVar) {
    var response = '';
    var queryUp = '';

    queryUp = `query get_alarms {
    alarms{
      alarmId
      farmId
      farmName
      refId 
      refName 
      categoryId 
      categoryName
      severityId 
       severityName
    
      severityColor
      pageProg
      pageTab 
      pageSubTab
      vals{
        fld 
        val 
      }
    }
  }
  `;
    await axios
      .post(
        myHttp,
        {
          query: queryUp,
        },
        {
          headers: {
            Authorization: 'JWT ' + inputVar.tokens.tokenIM,
          },
        }
      )
      .then((res) => {
        response = res;
      })
      .catch((error) => {
        response = error;
      });

    var result = {
      type: null,
      response: null,
      query: 'getAlarms',
    };
    result.type = 'v';

    if (response.data) {
      if (response.data.errors && response.data.errors.length > 0) {
        result.response = response.data.errors[0].message;
        result.type = 'e';
      } else {
        if (response.data.data.alarms) {
          result.type = 's';

          commit('setAlarms', response.data.data.alarms);
          result.response = response.data.data.alarms;
        }
      }
    } else {
      result.type = 'e';
      result.response = response;
    }
    return result;
  },
  async getAlarmRefs({ commit }, inputVar) {
    var response = '';
    var queryUp = '';

    queryUp = `query get_alarm_refs {
  alarmRefs {
    id
    name
    categoryId {
      id
      name
    }
    severityId {
      id
      name
      color
    }
  }
}
  `;

    await axios
      .post(
        myHttp,
        {
          query: queryUp,
        },
        {
          headers: {
            Authorization: 'JWT ' + inputVar.tokens.tokenIM,
          },
        }
      )
      .then((res) => {
        response = res;
      })
      .catch((error) => {
        response = error;
      });

    var result = {
      type: null,
      response: null,
      query: 'getAlarmRefs',
    };
    result.type = 'v';

    if (response.data) {
      if (response.data.errors && response.data.errors.length > 0) {
        result.response = response.data.errors[0].message;
        result.type = 'e';
      } else {
        if (response.data.data.alarmRefs) {
          result.type = 's';

          commit('setAlarmRefs', response.data.data.alarmRefs);
          result.response = response.data.data.alarmRefs;
        }
      }
    } else {
      result.type = 'e';
      result.response = response;
    }
    return result;
  },
  async updateAlarmRefs({ commit }, inputVar) {
    var queryUp = '';
    var response = '';
    var queryVar = [];

    inputVar.alarms.forEach(async function (item) {
      queryVar.push(`{alarmRefId:` + item.id + ` severityId:` + item.severityId.id + `}`);
    });

    if (queryVar.length > 0) {
      queryUp =
        `mutation updateAlarmRefs {
  updAlarmRefs(input:[` +
        queryVar +
        `]) {
    response {
       id
      name
      severityId {
        id
        name
      }

      }
   }
 }
 `;

      await axios
        .post(
          myHttp,
          {
            query: queryUp,
          },
          {
            headers: {
              Authorization: 'JWT ' + inputVar.tokens.tokenIM,
            },
          }
        )
        .then((res) => {
          response = res;
        })
        .catch((error) => {
          response = error;
        });
    }

    var result = {
      type: null,
      response: null,
      query: 'updateAlarmRefs',
    };
    result.type = 'v';
    if (response.data) {
      if (response.data.errors && response.data.errors.length > 0) {
        result.response = response.data.errors[0].message;
        result.type = 'e';
      } else {
        if (response.data.data.updAlarmRefs) {
          result.type = 's';

          result.response = response.data.data.updAlarmRefs;
        }
      }
    } else {
      result.type = 'e';
      result.response = response;
    }
    return result;
  },
  async createManualHistWater({ commit }, inputVar) {
    var queryUp = '';
    var response = '';

    queryUp =
      `mutation manual_w_load {
  manualWLoad(recs:[{farmId: ` +
      inputVar.farmId +
      `,
       date: "` +
      inputVar.manualHist.date +
      `",
       vol: "` +
      inputVar.manualHist.waterVol +
      `" ,
       sectorGroupId: ` +
      inputVar.manualHist.secGr +
      `}])
       {
      response
    }
  }
`;

    await axios
      .post(
        myHttp,
        {
          query: queryUp,
        },
        {
          headers: {
            Authorization: 'JWT ' + inputVar.tokens.tokenIM,
          },
        }
      )
      .then((res) => {
        response = res;
      })
      .catch((error) => {
        response = error;
      });

    var result = {
      type: null,
      response: null,
      query: 'createManualHistWater',
    };

    result.type = 'v';
    if (response.data) {
      if (response.data.errors && response.data.errors.length > 0) {
        result.response = response.data.errors[0].message;
        result.type = 'e';
      } else {
        if (response.data.data.manualWLoad) {
          result.type = 's';
          result.response = response.data.data.manualWLoad;
        }
      }
    } else {
      result.type = 'e';
      result.response = response;
    }
    return result;
  },
  async createManualHistFert({ commit }, inputVar) {
    var queryUp = '';
    var response = '';

    queryUp =
      `mutation manual_f_load {
  manualFLoad(recs:[{farmId: ` +
      inputVar.farmId +
      `,
          fertOutId: ` +
      inputVar.manualHist.fertOutId +
      `,
           date: "` +
      inputVar.manualHist.date +
      `",
           
           vol: "` +
      inputVar.manualHist.fertVol +
      `",
           sectorGroupId: ` +
      inputVar.manualHist.secGr +
      `}])
           {
          response
        }
      }
    `;

    await axios
      .post(
        myHttp,
        {
          query: queryUp,
        },
        {
          headers: {
            Authorization: 'JWT ' + inputVar.tokens.tokenIM,
          },
        }
      )
      .then((res) => {
        response = res;
      })
      .catch((error) => {
        response = error;
      });

    var result = {
      type: null,
      response: null,
      query: 'createManualHistFert',
    };

    result.type = 'v';
    if (response.data) {
      if (response.data.errors && response.data.errors.length > 0) {
        result.response = response.data.errors[0].message;
        result.type = 'e';
      } else {
        if (response.data.data.manualFLoad) {
          result.type = 's';
          result.response = response.data.data.manualFLoad;
        }
      }
    } else {
      result.type = 'e';
      result.response = response;
    }
    return result;
  },
  //  get all tariff tables
  async getETariff({ commit }, inputVar) {
    var response = '';
    var result = {
      query: 'getETariff',
      type: [],
      tariff: [],
      response: [],
    };
    /* Rate */
    await axios
      .post(
        myHttp,
        {
          query: ` query getERate {
      eRate {
        id
        name

        color
      }
    }
    `,
        },
        {
          headers: {
            Authorization: 'JWT ' + inputVar.tokens.tokenIM,
          },
        }
      )
      .then((res) => {
        response = res;
      })
      .catch((error) => {
        response = error;
      });
    result.tariff.push('eRate');
    if (response.data) {
      if (response.data.errors && response.data.errors.length > 0) {
        result.type.push('e');
        result.response.push(response.data.errors[0].message);
      } else {
        if (response.data.data.eRate) {
          commit('setERate', response.data.data.eRate);
          result.type.push('s');
          result.response.push(response.data.data.eRate);
        }
      }
    } else {
      result.type.push('e');
      result.response.push(response);
    }

    /* Tariff */
    await axios
      .post(
        myHttp,
        {
          query: ` query getETariff {
        eTariff {
          id
          name

          isSolar
          solarPeakTime
          isGenerator
          preferredEndTime
        }
      }

      `,
        },
        {
          headers: {
            Authorization: 'JWT ' + inputVar.tokens.tokenIM,
          },
        }
      )
      .then((res) => {
        response = res;
      })
      .catch((error) => {
        response = error;
      });
    result.tariff.push('eTariff');
    if (response.data) {
      if (response.data.errors && response.data.errors.length > 0) {
        result.type.push('e');
        result.response.push(response.data.errors[0].message);
      } else {
        if (response.data.data.eTariff) {
          commit('setETariff', response.data.data.eTariff);
          result.type.push('s');
          result.response.push(response.data.data.eTariff);
        }
      }
    } else {
      result.type.push('e');
      result.response.push(response);
    }

    /* Season */
    await axios
      .post(
        myHttp,
        {
          query: ` query getSeason {
      eSeason {
        id
        name


      }
    }

    `,
        },
        {
          headers: {
            Authorization: 'JWT ' + inputVar.tokens.tokenIM,
          },
        }
      )
      .then((res) => {
        response = res;
      })
      .catch((error) => {
        response = error;
      });
    result.tariff.push('eSeason');
    if (response.data) {
      if (response.data.errors && response.data.errors.length > 0) {
        result.type.push('e');
        result.response.push(response.data.errors[0].message);
      } else {
        if (response.data.data.eSeason) {
          commit('setESeason', response.data.data.eSeason);
          result.type.push('s');
          result.response.push(response.data.data.eSeason);
        }
      }
    } else {
      result.type.push('e');
      result.response.push(response);
    }

    /* Supplier */
    await axios
      .post(
        myHttp,
        {
          query: ` query getSupplier {
        eSupplier {
          id
          name

        }
      }
      `,
        },
        {
          headers: {
            Authorization: 'JWT ' + inputVar.tokens.tokenIM,
          },
        }
      )
      .then((res) => {
        response = res;
      })
      .catch((error) => {
        response = error;
      });
    result.tariff.push('eSupplier');
    if (response.data) {
      if (response.data.errors && response.data.errors.length > 0) {
        result.type.push('e');
        result.response.push(response.data.errors[0].message);
      } else {
        if (response.data.data.eSupplier) {
          commit('setESupplier', response.data.data.eSupplier);
          result.type.push('s');
          result.response.push(response.data.data.eSupplier);
        }
      }
    } else {
      result.type.push('e');
      result.response.push(response);
    }

    /* Supplier rate */
    await axios
      .post(
        myHttp,
        {
          query: ` query getSupllierRate {
      eSupplierRate {
        id
        supplier {
          id
          name
        }
        tariff {
          id
          name
        }
        rate {
          id
          name
        }
        cost
      }
    }
    `,
        },
        {
          headers: {
            Authorization: 'JWT ' + inputVar.tokens.tokenIM,
          },
        }
      )
      .then((res) => {
        response = res;
      })
      .catch((error) => {
        response = error;
      });
    result.tariff.push('eSupplierRate');
    if (response.data) {
      if (response.data.errors && response.data.errors.length > 0) {
        result.type.push('e');
        result.response.push(response.data.errors[0].message);
      } else {
        if (response.data.data.eSupplierRate) {
          commit('setESupplierRate', response.data.data.eSupplierRate);
          result.type.push('s');
          result.response.push(response.data.data.eSupplierRate);
        }
      }
    } else {
      result.type.push('e');
      result.response.push(response);
    }

    /* Tariff Plan */
    await axios
      .post(
        myHttp,
        {
          query: `query getTariffPlan {
        eTariffPlan {
          id

          supplier {
            id
            name
          }
          tariff {
            id
            name
          }
          rate {
            id
            name
            color
          }
          season
          endTime
          startTime
        }
      }


      `,
        },
        {
          headers: {
            Authorization: 'JWT ' + inputVar.tokens.tokenIM,
          },
        }
      )
      .then((res) => {
        response = res;
      })
      .catch((error) => {
        response = error;
      });
    result.tariff.push('eTariffPlan');
    if (response.data) {
      if (response.data.errors && response.data.errors.length > 0) {
        result.type.push('e');
        result.response.push(response.data.errors[0].message);
      } else {
        if (response.data.data.eTariffPlan) {
          commit('setETariffPlan', response.data.data.eTariffPlan);
          result.type.push('s');
          result.response.push(response.data.data.eTariffPlan);
        }
      }
    } else {
      result.type.push('e');
      result.response.push(response);
    }

    return result;
  },
  async getTabRefs({ commit }, inputVar) {
    var response = '';
    var result = {
      query: 'getTabRefs',
      type: [],
      tariff: [],
      response: [],
    };
    /* Parcel Types */
    await axios
      .post(
        myHttp,
        {
          query: ` query get_parcel_type_refs {
        parcelTypeRefs {
          id
          name
          parcelType
        }
      }
      `,
        },
        {
          headers: {
            Authorization: 'JWT ' + inputVar.tokens.tokenIM,
          },
        }
      )
      .then((res) => {
        response = res;
      })
      .catch((error) => {
        response = error;
      });

    result.tariff.push('parcelTypeRefs');

    if (response.data) {
      if (response.data.errors && response.data.errors.length > 0) {
        result.type.push('e');
        result.response.push(response.data.errors[0].message);
      } else {
        if (response.data.data.parcelTypeRefs) {
          commit('setParcelTypes', response.data.data.parcelTypeRefs);
          result.type.push('s');
          result.response.push(response.data.data.parcelTypeRefs);
        }
      }
    } else {
      result.type.push('e');
      result.response.push(response);
    }

    /* Plant Spacing */
    await axios
      .post(
        myHttp,
        {
          query: `query get_plant_spacing_refs {
        plantSpacingRefs {
          id
          plantLineSpacing
          plantSpacing
        }
      }
      `,
        },
        {
          headers: {
            Authorization: 'JWT ' + inputVar.tokens.tokenIM,
          },
        }
      )
      .then((res) => {
        response = res;
      })
      .catch((error) => {
        response = error;
      });

    result.tariff.push('plantSpacingRefs');
    if (response.data) {
      if (response.data.errors && response.data.errors.length > 0) {
        result.type.push('e');
        result.response.push(response.data.errors[0].message);
      } else {
        if (response.data.data.plantSpacingRefs) {
          commit('setPlantSpacing', response.data.data.plantSpacingRefs);
          result.type.push('s');
          result.response.push(response.data.data.plantSpacingRefs);
        }
      }
    } else {
      result.type.push('e');
      result.response.push(response);
    }

    /* Irrigation Spacing */
    await axios
      .post(
        myHttp,
        {
          query: `query get_irrigation_spacing_refs {
        irrigSpaceRefs {
          id
          linesPerPlantLine
          dripFlow
          dripSpacing
        }
      }
      `,
        },
        {
          headers: {
            Authorization: 'JWT ' + inputVar.tokens.tokenIM,
          },
        }
      )
      .then((res) => {
        response = res;
      })
      .catch((error) => {
        response = error;
      });

    result.tariff.push('irrigSpaceRefs');
    if (response.data) {
      if (response.data.errors && response.data.errors.length > 0) {
        result.type.push('e');
        result.response.push(response.data.errors[0].message);
      } else {
        if (response.data.data.irrigSpaceRefs) {
          commit('setIrrigSpace', response.data.data.irrigSpaceRefs);
          result.type.push('s');
          result.response.push(response.data.data.irrigSpaceRefs);
        }
      }
    } else {
      result.type.push('e');
      result.response.push(response);
    }

    /* Cultivar Types */
    await axios
      .post(
        myHttp,
        {
          query: `query get_cultivar_type_refs {
        cultivarTypeRefs {
          id
          name
        }
      }

      `,
        },
        {
          headers: {
            Authorization: 'JWT ' + inputVar.tokens.tokenIM,
          },
        }
      )
      .then((res) => {
        response = res;
      })
      .catch((error) => {
        response = error;
      });

    result.tariff.push('cultivarTypeRefs');
    if (response.data) {
      if (response.data.errors && response.data.errors.length > 0) {
        result.type.push('e');
        result.response.push(response.data.errors[0].message);
      } else {
        if (response.data.data.cultivarTypeRefs) {
          commit('setCultivarType', response.data.data.cultivarTypeRefs);
          result.type.push('s');
          result.response.push(response.data.data.cultivarTypeRefs);
        }
      }
    } else {
      result.type.push('e');
      result.response.push(response);
    }

    /* Cultivar Variety */
    await axios
      .post(
        myHttp,
        {
          query: `query get_cultivar_Variety_refs {
        cultivarVarietyRefs {
          id
          name
          cultivarType {id name}
        }
      }

      `,
        },
        {
          headers: {
            Authorization: 'JWT ' + inputVar.tokens.tokenIM,
          },
        }
      )
      .then((res) => {
        response = res;
      })
      .catch((error) => {
        response = error;
      });

    result.tariff.push('cultivarVarietyRefs');
    if (response.data) {
      if (response.data.errors && response.data.errors.length > 0) {
        result.type.push('e');
        result.response.push(response.data.errors[0].message);
      } else {
        if (response.data.data.cultivarVarietyRefs) {
          commit('setCultivarVariety', response.data.data.cultivarVarietyRefs);
          result.type.push('s');
          result.response.push(response.data.data.cultivarVarietyRefs);
        }
      }
    } else {
      result.type.push('e');
      result.response.push(response);
    }

    /* Soil Types */
    await axios
      .post(
        myHttp,
        {
          query: `query get_soil_type_refs {
        soilTypeRefs {
          id
          name
        }
      }
      `,
        },
        {
          headers: {
            Authorization: 'JWT ' + inputVar.tokens.tokenIM,
          },
        }
      )
      .then((res) => {
        response = res;
      })
      .catch((error) => {
        response = error;
      });
    result.tariff.push('soilTypeRefs');

    if (response.data) {
      if (response.data.errors && response.data.errors.length > 0) {
        result.type.push('e');
        result.response.push(response.data.errors[0].message);
      } else {
        if (response.data.data.soilTypeRefs) {
          commit('setSoilType', response.data.data.soilTypeRefs);
          result.type.push('s');
          result.response.push(response.data.data.soilTypeRefs);
        }
      }
    } else {
      result.type.push('e');
      result.response.push(response);
    }

    /* Contact Types */
    await axios
      .post(
        myHttp,
        {
          query: `query contacType {
        contactTypeRefs {
          id
          name
          contactType
          isUser
        }
      }
      `,
        },
        {
          headers: {
            Authorization: 'JWT ' + inputVar.tokens.tokenIM,
          },
        }
      )
      .then((res) => {
        response = res;
      })
      .catch((error) => {
        response = error;
      });

    result.tariff.push('contactTypeRefs');
    if (response.data) {
      if (response.data.errors && response.data.errors.length > 0) {
        result.type.push('e');
        result.response.push(response.data.errors[0].message);
      } else {
        if (response.data.data.contactTypeRefs) {
          commit('setContactType', response.data.data.contactTypeRefs);
          result.type.push('s');
          result.response.push(response.data.data.contactTypeRefs);
        }
      }
    } else {
      result.type.push('e');
      result.response.push(response);
    }

    /* User Profiles */
    await axios
      .post(
        myHttp,
        {
          query: `query user_profiles {
      userProfiles {
        id
        profileName
        addFarm
        addAdmin
        addConfig
        addIrrReq
        addSector
        addParcel
        addFertPlan
        addFertControl
        addFertRequest
        viewFarm
        viewAdmin
        viewConfig
        viewSector
        viewParcel
        viewProgram
        viewIrrReq
        viewFertPlan
        viewFertControl
        viewFertRequest
        viewDashboard
        submitProgram
        submitCloudImport
        viewCloudImport
        addSolidApp
        viewSolidApp
        addStocks
        viewStocks
        accountId {
          id
          accountName
        }
      }
    }

    `,
        },
        {
          headers: {
            Authorization: 'JWT ' + inputVar.tokens.tokenIM,
          },
        }
      )
      .then((res) => {
        response = res;
      })
      .catch((error) => {
        response = error;
      });

    result.tariff.push('userProfiles');
    if (response.data) {
      if (response.data.errors && response.data.errors.length > 0) {
        result.type.push('e');
        result.response.push(response.data.errors[0].message);
      } else {
        if (response.data.data.userProfiles) {
          commit('setUserProfiles', response.data.data.userProfiles);
          result.type.push('s');
          result.response.push(response.data.data.userProfiles);
        }
      }
    } else {
      result.type.push('e');
      result.response.push(response);
    }

    /* Dosers */
    await axios
      .post(
        myHttp,
        {
          query: `query get_doser_refs {
        doserRefs {
          id
          doserModel
          doserType
          vendorFlow
          fertType

        }
      }
      `,
        },
        {
          headers: {
            Authorization: 'JWT ' + inputVar.tokens.tokenIM,
          },
        }
      )
      .then((res) => {
        response = res;
      })
      .catch((error) => {
        response = error;
      });

    result.tariff.push('doserRefs');
    if (response.data) {
      if (response.data.errors && response.data.errors.length > 0) {
        result.type.push('e');
        result.response.push(response.data.errors[0].message);
      } else {
        if (response.data.data.doserRefs) {
          commit('setDoserRefs', response.data.data.doserRefs);
          result.type.push('s');
          result.response.push(response.data.data.doserRefs);
        }
      }
    } else {
      result.type.push('e');
      result.response.push(response);
    }

    /* FertRefs */
    await axios
      .post(
        myHttp,
        {
          query: `query fertRefs {
    fertRefs {
      id
      name
      N
      P
      K
      Ca
      Ng
      S
      concentrLmt
      density
      planUpLmt
      planDownLmt
    }
  }

  `,
        },
        {
          headers: {
            Authorization: 'JWT ' + inputVar.tokens.tokenIM,
          },
        }
      )
      .then((res) => {
        response = res;
      })
      .catch((error) => {
        response = error;
      });

    result.tariff.push('fertRefs');

    if (response.data) {
      if (response.data.errors && response.data.errors.length > 0) {
        result.type.push('e');

        result.response.push(response.data.errors[0].message);
      } else {
        if (response.data.data.fertRefs) {
          commit('setFertRefs', response.data.data.fertRefs);
          result.type.push('s');
          result.response.push(response.data.data.fertRefs);
        }
      }
    } else {
      result.type.push('e');
      result.response.push(response);
    }

    /* fertInventoryRefs */
    await axios
      .post(
        myHttp,
        {
          query: `query fertInventoryRefs {
    fertInventoryRefs {
      id
      name
      inOut
      invType
      userInput
      qtyDimension
    }
  }

  `,
        },
        {
          headers: {
            Authorization: 'JWT ' + inputVar.tokens.tokenIM,
          },
        }
      )
      .then((res) => {
        response = res;
      })
      .catch((error) => {
        response = error;
      });

    result.tariff.push('fertInventoryRefs');
    if (response.data) {
      if (response.data.errors && response.data.errors.length > 0) {
        result.type.push('e');
        result.response.push(response.data.errors[0].message);
      } else {
        if (response.data.data.fertInventoryRefs) {
          commit('setFertInventoryRefs', response.data.data.fertInventoryRefs);
          result.response.push(response.data.data.fertInventoryRefs);
          result.type.push('s');
        }
      }
    } else {
      result.type.push('e');
      result.response.push(response);
    }
    /* irrRotation */
    await axios
      .post(
        myHttp,
        {
          query: `query irrRotation {
    irrRotationRef {
      id
      name
    }
  }
  `,
        },
        {
          headers: {
            Authorization: 'JWT ' + inputVar.tokens.tokenIM,
          },
        }
      )
      .then((res) => {
        response = res;
      })
      .catch((error) => {
        response = error;
      });

    result.tariff.push('irrRotationRef');
    if (response.data) {
      if (response.data.errors && response.data.errors.length > 0) {
        result.type.push('e');
        result.response.push(response.data.errors[0].message);
      } else {
        if (response.data.data.irrRotationRef) {
          commit('setIrrRotationRef', response.data.data.irrRotationRef);
          result.type.push('s');
          result.response.push(response.data.data.irrRotationRef);
        }
      }
    } else {
      result.type.push('e');
      result.response.push(response);
    }
    /* systemRefs */
    await axios
      .post(
        myHttp,
        {
          query: `query get_system_types {
    systemRefs {
      id
      name
      standard
      doubleSystem
      doubleInjection
      maxShifts
    }
  }
  `,
        },
        {
          headers: {
            Authorization: 'JWT ' + inputVar.tokens.tokenIM,
          },
        }
      )
      .then((res) => {
        response = res;
      })
      .catch((error) => {
        response = error;
      });

    result.tariff.push('systemRefs');
    if (response.data) {
      if (response.data.errors && response.data.errors.length > 0) {
        result.type.push('e');
        result.response.push(response.data.errors[0].message);
      } else {
        if (response.data.data.systemRefs) {
          commit('setSystemRefs', response.data.data.systemRefs);
          result.type.push('s');
          result.response.push(response.data.data.systemRefs);
        }
      }
    } else {
      result.type.push('e');
      result.response.push(response);
    }
    /* alarmSeverityRefs */
    await axios
      .post(
        myHttp,
        {
          query: `query alarmSeverityRefs {
  alarmSeverityRefs {
    id
    severity
    color
    name
  }
}
  `,
        },
        {
          headers: {
            Authorization: 'JWT ' + inputVar.tokens.tokenIM,
          },
        }
      )
      .then((res) => {
        response = res;
      })
      .catch((error) => {
        response = error;
      });

    result.tariff.push('alarmSeverityRefs');
    if (response.data) {
      if (response.data.errors && response.data.errors.length > 0) {
        result.type.push('e');
        result.response.push(response.data.errors[0].message);
      } else {
        if (response.data.data.alarmSeverityRefs) {
          commit('setAlarmSeverity', response.data.data.alarmSeverityRefs);
          result.type.push('s');
          result.response.push(response.data.data.alarmSeverityRefs);
        }
      }
    } else {
      result.type.push('e');
      result.response.push(response);
    }

    /* accountStatusRefs */
    await axios
      .post(
        myHttp,
        {
          query: `query accountStatus {
  accountStatusRefs {
    id
    accountStatus
  }
}

  `,
        },
        {
          headers: {
            Authorization: 'JWT ' + inputVar.tokens.tokenIM,
          },
        }
      )
      .then((res) => {
        response = res;
      })
      .catch((error) => {
        response = error;
      });

    result.tariff.push('accountStatusRefs');
    if (response.data) {
      if (response.data.errors && response.data.errors.length > 0) {
        result.type.push('e');
        result.response.push(response.data.errors[0].message);
      } else {
        if (response.data.data.accountStatusRefs) {
          commit('setAccountStatus', response.data.data.accountStatusRefs);
          result.type.push('s');
          result.response.push(response.data.data.accountStatusRefs);
        }
      }
    } else {
      result.type.push('e');
      result.response.push(response);
    }
    /* cloudUser */
    await axios
      .post(
        myHttp,
        {
          query: `query cloud {
  cloudUsersRefs {
    id
    cloudUser
  }
}

  `,
        },
        {
          headers: {
            Authorization: 'JWT ' + inputVar.tokens.tokenIM,
          },
        }
      )
      .then((res) => {
        response = res;
      })
      .catch((error) => {
        response = error;
      });

    result.tariff.push('cloudUsersRefs');
    if (response.data) {
      if (response.data.errors && response.data.errors.length > 0) {
        result.type.push('e');
        result.response.push(response.data.errors[0].message);
      } else {
        if (response.data.data.cloudUsersRefs) {
          commit('setCloudUsersRef', response.data.data.cloudUsersRefs);
          result.type.push('s');
          result.response.push(response.data.data.cloudUsersRefs);
        }
      }
    } else {
      result.type.push('e');
      result.response.push(response);
    }
    /* unit Profile ref */
    await axios
      .post(
        myHttp,
        {
          query: `query unitProfile {
  unitProfileRefs {
    id
    area {
      name
    }
    wVolume {
      name
    }
    wFlow {
      name
    }
    fFlow {
      name
    }
    massArea {
      name
    }
    mass {
      name
    }
    wVolumeArea {
      name
    }
    fVolume {
      name
    }
    fVolumeArea {
      name
    }
  }
}


  `,
        },
        {
          headers: {
            Authorization: 'JWT ' + inputVar.tokens.tokenIM,
          },
        }
      )
      .then((res) => {
        response = res;
      })
      .catch((error) => {
        response = error;
      });

    result.tariff.push('unitProfileRefs');
    if (response.data) {
      if (response.data.errors && response.data.errors.length > 0) {
        result.type.push('e');
        result.response.push(response.data.errors[0].message);
      } else {
        if (response.data.data.unitProfileRefs) {
          commit('setUnitProfileRef', response.data.data.unitProfileRefs);
          result.type.push('s');
          result.response.push(response.data.data.unitProfileRefs);
        }
      }
    } else {
      result.type.push('e');
      result.response.push(response);
    }

    return result;
  },
  async getExcelModelFileFert({ commit }, inputVar) {
    const query = `query CreateFertProgrammingTemplate {
                      createFertProgrammingTemplate
                   }`;

    const response = await axios
      .post(
        myHttp,
        {
          query,
        },
        {
          headers: {
            Authorization: 'JWT ' + inputVar.tokens.tokenIM,
          },
        }
      )
      .then((res) => {
        return res.data.data.createFertProgrammingTemplate;
      })
      .catch((error) => {
        console.log(error);
      });

    return response;
  },
  async getExcelModelFileIrrig({ commit }, inputVar) {
    const query = `query CreateBatchProgrammingTemplate {
                        createBatchProgrammingTemplate
                    }`;

    const response = await axios
      .post(
        myHttp,
        {
          query,
        },
        {
          headers: {
            Authorization: 'JWT ' + inputVar.tokens.tokenIM,
          },
        }
      )
      .then((res) => {
        return res.data.data.createBatchProgrammingTemplate;
      })
      .catch((error) => {
        console.log(error);
      });

    return response;
  },
  async getExcelModelFileParcel({ commit }, inputVar) {
    const query = `query CreateParcelTemplate {
                      createParcelTemplate (farmId: ${inputVar.farmid})
                    }`;

    const response = await axios
      .post(
        myHttp,
        {
          query,
        },
        {
          headers: {
            Authorization: 'JWT ' + inputVar.tokens.tokenIM,
          },
        }
      )
      .then((res) => {
        return res.data.data.createParcelTemplate;
      })
      .catch((error) => {
        console.log(error);
      });

    return response;
  },
  async getHistoricExcelFert({ commit }, inputVar) {
    const query = `query GetHistoricFertExcel {
                      getHistoricFertExcel {
                        id
                        uploadedAtDt
                        excelBinary
                        jsonError {
                          msg
                          objFert {
                            farm
                            effectiveDt
                            qty
                            transTypeId
                            farmid
                            qtyDimension
                            fertOutName
                          }
                        }
                        jsonSuccess {
                          farm
                          effectiveDt
                          qty
                          transTypeId
                          farmid
                          qtyDimension
                          fertOutName
                        }
                      }
                    }`;

    const response = await axios
      .post(
        myHttp,
        {
          query,
        },
        {
          headers: {
            Authorization: 'JWT ' + inputVar.tokens.tokenIM,
          },
        }
      )
      .then((res) => {
        return res.data.data.getHistoricFertExcel;
      })
      .catch((error) => {
        console.log(error);
      });

    return response;
  },
  async getHistoricIrriExcel({ commit }, inputVar) {
    const query = `query GetHistoricIrriExcel {
                    getHistoricIrriExcel {
                      id
                      uploadedAtDt
                      excelBinary
                      jsonError {
                        error
                        farmId
                        farmName
                      }
                      jsonSuccess {
                        farmId
                        farmName
                      }
                    }
                  }`;

    const response = await axios
      .post(
        myHttp,
        {
          query,
        },
        {
          headers: {
            Authorization: 'JWT ' + inputVar.tokens.tokenIM,
          },
        }
      )
      .then((res) => {
        return res.data.data.getHistoricIrriExcel;
      })
      .catch((error) => {
        console.log(error);
      });

    return response;
  },
  async getWaterPrescription({ commit }, inputVar) {
    var response = '';
    var queryUp = `query getWaterPrescription{
      getWaterPrescription(farmId: ${inputVar.farmId}, startDt: "${inputVar.startDt}", endDt: "${inputVar.endDt}") {
          id 
          waterVol
          startDt
          endDt
          farmId{
            id
            name
          }
          createdDt
        }
      }
      `;

    await axios
      .post(
        myHttp,
        {
          query: queryUp,
        },
        {
          headers: {
            Authorization: 'JWT ' + inputVar.tokens.tokenIM,
          },
        }
      )
      .then((res) => {
        response = res;
      })
      .catch((error) => {
        response = error;
      });

    var result = {
      type: null,
      response: null,
      query: 'getWaterPrescription',
    };
    result.type = 'v';
    if (response.data) {
      if (response.data.errors && response.data.errors.length > 0) {
        result.response = response.data.errors[0].message;
        result.type = 'e';
      } else {
        if (response.data.data.getWaterPrescription) {
          result.type = 's';
          result.response = response.data.data.getWaterPrescription;
        }
      }
    } else {
      result.type = 'e';
      result.response = response;
    }
    return result;
  },
};
